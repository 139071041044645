import { Button, Chip, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDashboardConfigList } from '../store/actions';

const useStyle = makeStyles((theme) => ({
    container: {
        // backgroundColor: theme.palette.paper.main,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        "& *": {
            // color: theme.palette.input.text,
        },
        minWidth: 300
    },
    input: {
        width: "90%",
        padding: 6,
        fontSize: "1em",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.primary.main,
        margin: "20px 0"
    },
    forminput: {
        // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
        borderWidth: "1px",
        // borderColor: theme.palette.input.borderColor
    },
    formControl: {
        margin: '2em 0',
        // // background// color: theme.palette.input.background,
        border: 'red',
        '& input': {
            // color: theme.palette.input.text,

        },
        '& InputLabelProps': {
            // root: theme.palette.input.label,
            // focused: theme.palette.input.label,
        },
        '& InputProps': {
            // root: theme.palette.input.label,
            // focused: theme.palette.input.label,
        },
    },

    cssLabel: {
        // // color: theme.palette.input.label,
        "&.Mui-focused": {
            // // color: theme.palette.input.labelClick,

        }
    },
    formContainer: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.input.main,
        borderRadius: '4px',
        padding: theme.spacing(1)
    },
    configuredChipsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: theme.spacing(0.6)
        }
    }
}))

const defaultFormData = {
    deviceToken: 0,
    parameter: 0,
    target: 0,
    label: '',
    parameterType: 0
}
const defaultFormError = {
    deviceToken: '',
    parameter: '',
    target: '',
    label: '',
    parameterType: ''
}

const valueRangeList = [
    {
        id: 1,
        parameter: 'Water Percentage',
        minValue: 0,
        maxValue: 100,
        unit: '%',
        colors: ['#FA6400', '#FFEE52', '#96D98D', '#8AB8FF']
    },
    {
        id: 2,
        parameter: 'Conductivity',
        minValue: 0,
        maxValue: 600,
        unit: 'mS/m',
        colors: ['#FA6400', '#FFEE52', '#96D98D', '#8AB8FF']
    },
    {
        id: 3,
        parameter: 'Temperature',
        minValue: -10,
        maxValue: 50,
        unit: '°C',
        colors: ['#1F60C4', '#56A64B', '#F2CC0C', '#E02F44']
    },
    {
        id: 4,
        parameter: 'Battery',
        minValue: 0,
        maxValue: 5,
        unit: 'V',
        colors: ['#C4162A', '#FF780A', '#E1D24B', '#56A64B']
    },
    {
        id: 6,
        parameter: 'Humidity',
        minValue: 0,
        maxValue: 100,
        unit: '%',
        colors: ['#FA6400', '#E5D64C', '#96D98D', '#8AB8FF']
    },
    {
        id: 8,
        parameter: 'Leaf Moisture',
        minValue: 0,
        maxValue: 100,
        unit: '%',
        colors: ['#EA5E02', '#FFEE52', '#96D98D', '#8AB8FF']
    },
    {
        id: 10,
        parameter: 'pH',
        minValue: 0,
        maxValue: 14,
        unit: 'pH',
        colors: ['#BE2A3D', '#F2CC0C', '#96D98D', '#37872D']
    },
];

function DashboardConfigComponent(props) {
    const classes = useStyle()
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({...defaultFormData});
    const [formError, setFormError] = useState({...defaultFormError});

    const handleForm = event => {
        setFormData(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        });
        setFormError(prev => {
            return {
                ...prev,
                [event.target.name]: ''
            }
        });
    }

    const [configuredList, setConfiguredList] = useState(
        (props.info)? Object.keys(props.info).filter(k => k.startsWith('dashboardConfig_')).map(key => {
            if(props.info[key] === ""){
                return null
            }

            console.log((props.info[key].split('$'))[0]);
            console.log((props.info[key].split('$'))[1]);
            console.log((props.info[key].split('$'))[2]);
            console.log((props.info[key].split('$'))[3]);

            return {
                deviceToken: (props.info[key].split('$'))[0],
                parameter: (props.info[key].split('$'))[1],
                label: (props.info[key].split('$'))[2],
                parameterType: (props.info[key].split('$'))[3]
            }
        })
        : []
    );

    const handleRemoveConfigItem = (deviceToken, parameter) => {
        setConfiguredList(prev => prev.map(item => {
            if(item !== null && (item.deviceToken !== deviceToken || item.parameter !== parameter)){
                return item;
            }
            return null
        }));
    }

    const handleAdd = () => {
        if(formData.deviceToken === 0){
            setFormError(prev => {
                return {
                    ...prev,
                    deviceToken: 'Select a device'
                }
            });
            return;
        }
        if(formData.parameter === 0){
            setFormError(prev => {
                return {
                    ...prev,
                    parameter: 'Select a parameter'
                }
            });
            return;
        }
        if(formData.parameterType === 0){
            setFormError(prev => {
                return {
                    ...prev,
                    parameterType: 'Parameter type cannot be empty'
                }
            });
            return;
        }
        if(formData.label === ''){
            setFormError(prev => {
                return {
                    ...prev,
                    label: 'Label cannot be empty'
                }
            });
            return;
        }
        setConfiguredList(prev => [
            ...prev,
            {
                ...formData
            }
        ]);
        setFormData({...defaultFormData});
    }

    const saveConfigList = () => {
        const configStringList = [
            ...(configuredList.filter(item => item !== null).map(item => `${item.deviceToken}$${item.parameter}$${item.label}$${item.parameterType}`))
        ];
        dispatch(updateDashboardConfigList(configStringList, props.groupToken));
    }

    return (
        <div className={classes.container}>
            <Typography variant="h6">Config</Typography>
            <div className={classes.configuredChipsContainer}>
                {configuredList.length > 0 && configuredList.map((item, index) => (
                    (item)? <Chip color="primary" key={index + item.deviceToken + ' | ' + item.parameter} label={item.deviceToken + ' | ' + item.parameterType} onDelete={() => {handleRemoveConfigItem(item.deviceToken, item.parameter)}} />: ''
                ))}
            </div>
            {(configuredList.filter(item => item !== null).length < 4 )&& (
                <div className={classes.formContainer}>
                    <div className={classes.formControl}>
                        <TextField
                            label="Device" variant="outlined" size="small" fullWidth
                            name="deviceToken"
                            select
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            value={formData.deviceToken}
                            onChange={handleForm}
                            helperText={formError.deviceToken}
                            error={(formError.deviceToken.length > 0)}
                        >
                            <MenuItem value={0}>Select device</MenuItem>
                            {props.deviceList.map(device => (
                                <MenuItem key={device.Token} value={device.Token}>{device.Token}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className={classes.formControl}>
                        <TextField
                            label="Parameter" variant="outlined" size="small" fullWidth
                            name="parameter"
                            select
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            value={formData.parameter}
                            onChange={handleForm}
                            helperText={formError.parameter}
                            error={(formError.parameter.length > 0)}
                        >
                            <MenuItem value={0}>Select Parameter</MenuItem>
                            {formData.deviceToken && props.deviceList.find(device => device.Token === formData.deviceToken).data.Data.map(data => (
                                <MenuItem key={data.Parameter} value={data.Parameter}>{data.Parameter}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className={classes.formControl}>
                        <TextField
                            label="Parameter Type" variant="outlined" size="small" fullWidth
                            name="parameterType"
                            select
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            value={formData.parameterType}
                            onChange={handleForm}
                            helperText={formError.parameterType}
                            error={(formError.parameterType.length > 0)}
                        >
                            <MenuItem value={0}>Select Parameter Type</MenuItem>
                            {valueRangeList.map(data => (
                                <MenuItem key={data.parameter} value={data.parameter}>{data.parameter}</MenuItem>
                            ))}
                        </TextField>
                    </div>
                    {/* <div className={classes.formControl}>
                        <TextField
                            label="Target" variant="outlined" size="small" fullWidth name="target"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            onChange={handleForm}
                            value={formData.target}
                            helperText={formError.target}
                            error={(formError.target.length > 0)}
                        />
                    </div> */}
                    <div className={classes.formControl}>
                        <TextField
                            label="Label" variant="outlined" size="small" fullWidth name="label"
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            onChange={handleForm}
                            value={formData.label}
                            helperText={formError.label}
                            error={(formError.label.length > 0)}
                        />
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <Button size="small" onClick={handleAdd}>Add</Button>
                    </div>
                </div>
                
            )}
            <Button onClick={saveConfigList} fullWidth style={{marginTop: '1rem'}}>Save</Button>
        </div>
    );
}

export default DashboardConfigComponent;