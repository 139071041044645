import axios from "axios";
import moment from "moment";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_DEVICES = 'DASHBOARD_COMPONENT - GET_DEVICES';
export const GET_GROUPS = 'DASHBOARD_COMPONENT - GET_GROUPS';

export const START_LOADING = 'DASHBOARD_COMPONENT - START_LOADING';
export const STOP_LOADING = 'DASHBOARD_COMPONENT - STOP_LOADING';
export const ERROR_MESSAGE = 'DASHBOARD_COMPONENT - ERROR_MESSAGE';

export const GET_FARM_DETAILS = 'DASHBOARD_COMPONENT - GET_FARM_DETAILS';

export const GET_GROUP_DETAILS_AND_DATA = 'DASHBOARD_COMPONENT - GET_GROUP_DETAILS_AND_DATA';

export const RESET_DASHBOARD = 'DASHBOARD_COMPONENT - RESET_DASHBOARD';
export const CONFIG_FLAG = 'DASHBOARD_COMPONENT - CONFIG_FLAG';


export function getGroups() {

	return dispatch =>{

		axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/group/list?tenant=ESSP")
				.then(
					response => {

						let requestList = response.data.Data.filter(d => (d.Token !== 'ESSPERTH' && d.Token !== 'ESSPLOCATION')).map(row => (
							axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/states/group?groupToken=${row.Token}&tenant=ESSP`)
						))
						requestList = [
							...requestList,
							...response.data.Data.filter(d => d.Token !== 'ESSPERTH'&& d.Token !== 'ESSPLOCATION').map(row => (
								axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/group/detail?groupToken=${row.Token}&tenant=ESSP`)
							))
						]
						requestList = [
							...requestList,
							...response.data.Data.filter(d => d.Token !== 'ESSPERTH'&& d.Token !== 'ESSPLOCATION').map(row => (
								axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/data/latest/group?groupToken=${row.Token}&tenant=ESSP`)
							))
						]

						axios.all(requestList).then(
							axios.spread((...responses) => {
									let responseData = {
										states: [],
										details: [],
										data: []
									};
									let singleLength = responses.length / 3;

									for(let i = 0; i < singleLength; i++){
										responseData = {
											...responseData,
											states: [
												...responseData.states, 
												responses[i].data.Data
											]
										}
									}
									for(let i = singleLength; i < singleLength + singleLength; i++){
										responseData = {
											...responseData,
											details: [
												...responseData.details, 
												responses[i].data.Data
											]
										}
									}
									for(let i = singleLength + singleLength; i < singleLength + singleLength + singleLength; i++){
										responseData = {
											...responseData,
											data: [
												...responseData.data, 
												responses[i].data.Data
											]
										}
									}
									dispatch(stopLoading());
									return dispatch({
										type: GET_GROUPS,
										payload: responseData
									})
									
								}
							)).catch(error => {
								console.log(error)
								dispatch(stopLoading());
								return dispatch({
									type: ERROR_MESSAGE,
									payload: []
								})
							});
						
					}
				).catch(error => {
					dispatch(stopLoading());
					return dispatch({
						type: ERROR_MESSAGE,
						payload: []
					})
				});
	}
}

export function getFarmDetails() {

	return dispatch =>{

		axios.all([
			axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/states/group?groupToken=FARM1&tenant=ESSP"),
			axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/states/group?groupToken=FARM2&tenant=ESSP")
		]).then(
			axios.spread((...responses) => {
					dispatch(stopLoading());
					return dispatch({
						type: GET_FARM_DETAILS,
						payload: {
							farm1: responses[0].data.Data.Devices,
							farm2: responses[1].data.Data.Devices,
						}
					})
					
				}
			)).catch(error => {
				console.log(error)
				dispatch(stopLoading());
				return dispatch({
					type: ERROR_MESSAGE,
					payload: []
				})
			});
	}
}

export function getGroupDetailsAndData(groupToken, frequency){
	let responseTempData = [];
	let responseData = [];
	let responseDataWithState = [];
	let rowDataRequests = [];
	let responseDataWithRawData = [];
	const endTime = moment().unix();
	const startTime = endTime - frequency;

	return dispatch => {
		axios.all([
			axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/group/detail?groupToken=${groupToken}&tenant=ESSP`),
			axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/data/latest/group?groupToken=${groupToken}&tenant=ESSP`),
			axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/states/group?groupToken=${groupToken}&tenant=ESSP`)
		]).then(
			axios.spread((...responses) => {
					responseTempData = [
						...((responses[0].data && responses[0].data.Data && responses[0].data.Data.Devices)? responses[0].data.Data.Devices: [])
					];
					if(responses[1].data && responses[1].data.Data && responses[1].data.Data.Devices){
						responseTempData.forEach(item => {
							let tempItem = responses[1].data.Data.Devices.find(data => data.Token === item.Token);
							let tempItem2 = {
								...item,
								data: tempItem
							};
							responseData = [
								...responseData,
								tempItem2
							]
							rowDataRequests = [
								...rowDataRequests,
								axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/data/raw?deviceToken=${item.Token}&time=range&startTime=${startTime}&endTime=${endTime}&tenant=ESSP`)
							];
						})
					}
					if(responses[2].data && responses[2].data.Data && responses[2].data.Data.Devices){
						responseData.forEach(item => {
							let tempItem = responses[2].data.Data.Devices.find(data => data.Token === item.Token);
							let tempItem2 = {
								...item,
								state: tempItem
							};
							responseDataWithState = [
								...responseDataWithState,
								tempItem2
							];
						})
					}
					axios.all(rowDataRequests).then(
						axios.spread((...responses2) => {
							responses2.forEach(res => {
								if(res.data && res.data.Data && res.data.Data.Data){
									let tempRawItem = responseDataWithState.find(d => d.Token === res.data.Data.Token);
									responseDataWithRawData = [
										...responseDataWithRawData,
										{
											...tempRawItem,
											rawData: res.data.Data.Data
										}
									]
								}
							})
							dispatch(stopLoading());
							return dispatch({
								type: GET_GROUP_DETAILS_AND_DATA,
								payload: {
									groupDetailsAndData: responseDataWithRawData,
									groupInfo: (responses[0].data && responses[0].data.Data && responses[0].data.Data.Info)? responses[0].data.Data.Info: null
								},
							})
								
						}
					)).catch(error => {
						console.log(error)
						dispatch(stopLoading());
						return dispatch({
							type: ERROR_MESSAGE,
							payload: []
						})
					});					
				}
			)).catch(error => {
				dispatch(stopLoading());
				return dispatch({
					type: ERROR_MESSAGE,
					payload: []
				})
			});
	}
}

export function acknowledgeAlarm(requestObject, groupToken) {

	return dispatch =>{

			axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/put/info/device?tenant=ESSP", requestObject)
			.then(response => {
				setTimeout(() => {
					dispatch(getGroupDetailsAndData(groupToken));
				}, 20000);
			})
			.catch(error => {
				console.log(error)
				return dispatch({
					type: ERROR_MESSAGE,
					payload: []
				})
			});
	}
}

export function updateDashboardConfigList(configStringList, GroupToken) {

	let requestObject = {
		GroupToken,
		Time: moment().unix()
	};

	let Info = {};

	for(let i = 0; i < 4; i++){
		Info = {
			...Info,
			['dashboardConfig_' + (i+1)]: (configStringList[i])? configStringList[i]: ''
		}
	}

	requestObject = {
		...requestObject,
		Info
	};

	return dispatch =>{
		dispatch(startLoading());
		axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/put/info/group?tenant=ESSP", requestObject)
		.then(response => {
			dispatch(stopLoading());
			return dispatch({
				type: CONFIG_FLAG
			});
		})
		.catch(error => {
			dispatch(stopLoading());
			return dispatch({
				type: ERROR_MESSAGE,
				payload: []
			})
		});
	}
}

export function updateWeatherDevice(deviceToken, GroupToken) {

	let requestObject = {
		GroupToken,
		Time: moment().unix(),
		Info: {
			weatherDeviceToken: deviceToken
		}
	};

	return dispatch =>{
		dispatch(startLoading());
		axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/put/info/group?tenant=ESSP", requestObject)
		.then(response => {
			dispatch(stopLoading());
			return dispatch({
				type: CONFIG_FLAG
			});
		})
		.catch(error => {
			dispatch(stopLoading());
			return dispatch({
				type: ERROR_MESSAGE,
				payload: []
			})
		});
	}
}

export function startLoading(){
	return {
		type: START_LOADING
	}
}

export function stopLoading(){
	return {
		type: STOP_LOADING
	}
}

export function resetDashboard(){
	return {
		type: RESET_DASHBOARD
	}
}