import customAxios from "../Services/customAxios/CustomAxios";
import moment from "moment";


export const getOrganizationList = async (params) => {
    return customAxios.get(
      `${process.env.REACT_APP_API_BASE_URL}/company/getAllCompanyList`
    );
};

export const deleteOrganization = async (companyId) => {
  return customAxios.put(
    `${process.env.REACT_APP_API_BASE_URL}/company/deletecompany/${companyId}`
  );
};

export const deleteOrganizationGroupAurigaa = async (companyId) => {
  return customAxios.post(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/reg/group/delete?tenant=ESSP`, {Token: companyId}
  );
};