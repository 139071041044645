import {
  Typography,
  Button,
  makeStyles,
  Grid,
  TextField,
  Paper,
  MenuItem,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import UserEditConfirmationComponent from "../UserEditConfirmationComponent/UserEditConfirmationComponent";
import { DeviceTileComponent } from "../../UIComponents/DeviceTileComponent/DeviceTileComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserFormResetToFalse,
  getCompanies,
  getDevices,
  getEditingUser,
  getUserGroups,
  removeEditingUser,
} from "../store/actions";
import {
  firstNamePattern,
  lastNamePattern,
  emailPattern,
  contactPattern,
  designationPattern,
} from "../../../Config/RegExp";
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/material.css";
import ReactPhoneInput from "react-phone-input-material-ui";
import { useCookies } from "react-cookie";
function EditUserComponent(props) {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",

      padding: "20px",
      // backgroundColor: theme.palette.paper.main
    },
    formControl: {
        margin: "2em 0",
  
        "& .special-label": {
          background: "#ffffff",
          color: "#868686",
        },
      },
      formControlDark: {
        margin: "1em 0",
  
        "& .special-label": {
          background: "#424242",
        },
      },

    cssLabel: {
      // // color: theme.palette.input.label,
      "&.Mui-focused": {
        // // color: theme.palette.input.labelClick,
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },

    Accordion: {
      backgroundColor: theme.palette.secondary.main,
    },
    input: {
      width: "90%",
      padding: 6,
      fontSize: "1em",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.primary.main,
      margin: "20px 0",
    },
    forminput: {
      // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
      borderWidth: "1px",
      // borderColor: theme.palette.input.borderColor
    },
    inputField: {
      margin: "2em 0",
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.palette.inputBoxSecond.color,
      },
    },
    cancelButton: {
      backgroundColor: "red",
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const companyList = useSelector(({ user }) => user.companyList);
  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );

  const [cookies] = useCookies(["theme"]);
  const mode = cookies.theme;

  let phoneNoColor = "#ffffff";
  let phoneNoLabelColor = "#737373";

  if (mode === "Dark") {
    phoneNoColor = "#424242";
    phoneNoLabelColor = "#fafafa";
  }

  const defaultFormData = {
    firstName: "",
    lastName: "",
    alertEnable: false,
    userEmail: "",
    contactNo: "",
    designation: "",
    usrGroupId: 0,
    companyId:
      loggedUserRole && loggedUserRole.toLowerCase() === "admin"
        ? 0
        : loggedUserCompanyId,
    devices: [],
  };

  const defaultFormErrors = {
    firstName: "",
    lastName: "",
    alertEnable: false,
    userEmail: "",
    contactNo: "",
    designation: "",
    usrGroupId: "",
    companyId: "",
    devices: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const userGroups = useSelector(({ user }) => user.userGroups);
  const addUserFormReset = useSelector(({ user }) => user.addUserFormReset);

  useEffect(() => {
    if (addUserFormReset) {
      dispatch(getEditingUser(editingUserId));
      dispatch(addUserFormResetToFalse());
      handleConfirmModalClose();
    }
  }, [addUserFormReset, dispatch]);

  const resetForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultFormErrors);
  };

  useEffect(() => {
    dispatch(getUserGroups());
    // dispatch(getDevices());
    dispatch(getCompanies());
  }, [dispatch]);

  const handleForm = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const validateFormData = () => {
    const errors = defaultFormErrors;
    let isHaveErrors = false;

    if (
      formData.firstName === "" ||
      !firstNamePattern.test(formData.firstName)
    ) {
      errors.firstName = "Firs Name is not valid! Characters Only";
      isHaveErrors = true;
    }
    if (formData.lastName === "" || !lastNamePattern.test(formData.lastName)) {
      errors.lastName = "Last Name is not valid! Characters Only";
      isHaveErrors = true;
    }
    if (formData.userEmail === "" || !emailPattern.test(formData.userEmail)) {
      errors.userEmail = "Email is not valid!";
      isHaveErrors = true;
    }
    if (formData.contactNo === "" || !contactPattern.test(formData.contactNo)) {
      errors.contactNo = "Contact Number is not valid! Numbers only";
      isHaveErrors = true;
    }
    if (
      formData.designation === "" ||
      !designationPattern.test(formData.designation)
    ) {
      errors.designation = "designation is not valid!";
      isHaveErrors = true;
    }
    if (formData.usrGroupId === 0) {
      errors.usrGroupId = "Select a User Group";
      isHaveErrors = true;
    }

    setFormErrors(errors);
    return isHaveErrors;
  };

  const handleConfirmModalOpen = () => {
    setFormData((prev) => {
      return {
        ...prev,
        devices: topDevices,
      };
    });
    if (!validateFormData()) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  //add devices

  const [topDevices, setTopDevices] = useState([]);
  // const devices = [{ id: 1, name: 'TEST1' }, { id: 2, name: 'TEST2' }, { id: 3, name: 'TEST3' }];
  const devices = useSelector(({ user }) => user.devices);

  const [bottomDevices, setBottomDevices] = useState([]);

  const [searchDeviceText, setSearchDeviceText] = useState("");

  const removeDevice = (device) => {
    setTopDevices((prev) => prev.filter((item) => item.Token !== device.Token));
    setFormData((prev) => {
      return {
        ...prev,
        devices: prev.devices.filter(
          (deviceToken) => deviceToken !== device.Token
        ),
      };
    });
  };

  const addDevice = (device) => {
    setTopDevices((prev) => [...prev, device]);
    setFormData((prev) => {
      return {
        ...prev,
        devices: [...prev.devices, device.Token],
      };
    });
  };

  //Edit User
  const editingUserId = useSelector(({ user }) => user.editingUserId);
  const editingUser = useSelector(({ user }) => user.editingUser);

  useEffect(() => {
    if (editingUserId === null) {
      history.push("/user");
    } else {
      dispatch(getEditingUser(editingUserId));
    }
  }, [dispatch, editingUserId]);

  const removeEditingUserHandler = () => {
    dispatch(removeEditingUser());
  };

  useEffect(() => {
    if (editingUser) {
      setFormData({
        ...editingUser,
        alertEnable: editingUser.alertEnable,
        usrGroupId: editingUser.groupId,
      });
      setTopDevices(editingUser.resultList);
    }
  }, [editingUser]);

  return (
    <>
      <Paper className={classes.container}>
        <form>
          <Grid container>
            <Grid item sm={12} md={5}>
              <div className={classes.inputField}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="firstName"
                  value={formData.firstName}
                  helperText={formErrors.firstName}
                  error={formErrors.firstName.length > 0}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="lastName"
                  onChange={handleForm}
                  value={formData.lastName}
                  helperText={formErrors.lastName}
                  error={formErrors.lastName.length > 0}
                />
              </div>
              <div className={
                  mode === "Dark"
                    ? classes.formControlDark
                    : classes.formControl
                }>
                <ReactPhoneInput
                  name="contactNo"
                  label="Contact No"
                  variant="outlined"
                  size="small"
                  inputStyle={{
                    width: "100%",
                    padding: "8px 25px 0px 58px",
                    backgroundColor: phoneNoColor,
                    borderColor: phoneNoLabelColor,
                  }}
                  value={formData.contactNo}
                  country="au"
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        contactNo: e,
                      };
                    })
                  } // passed function receives the phone value
                  component={TextField}
                />
              </div>
              <div className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="alertEnable"
                      checked={formData.alertEnable}
                      onChange={(event) =>
                        setFormData((prev) => {
                          return { ...prev, alertEnable: event.target.checked };
                        })
                      }
                    />
                  }
                  label="Alert Enable"
                  labelPlacement="start"
                />
              </div>
            </Grid>
            <Grid item sm={12} md={2}></Grid>
            <Grid item sm={12} md={5}>
              <div className={classes.inputField}>
                <TextField
                  label="Designation"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="designation"
                  onChange={handleForm}
                  value={formData.designation}
                  helperText={formErrors.designation}
                  error={formErrors.designation.length > 0}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="userEmail"
                  type="email"
                  value={formData.userEmail}
                  helperText={formErrors.userEmail}
                  error={formErrors.userEmail.length > 0}
                  disabled
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  label="User Group"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="usrGroupId"
                  select
                  value={formData.usrGroupId}
                  onChange={handleForm}
                  helperText={formErrors.usrGroupId}
                  error={formErrors.usrGroupId.length > 0}
                  disabled={loggedUserRole.toLowerCase() === "admin"? false:true}
                >
                  <MenuItem value={0}>Select group</MenuItem>
                  {userGroups.map((group) => (
                    <MenuItem key={group.usrGroupId} value={group.usrGroupId}>
                      {group.usrGroupDescription}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {loggedUserRole && loggedUserRole.toLowerCase() === "admin" && (
                <div className={classes.formControl}>
                  <TextField
                    label="Organization"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyId"
                    select
                    value={formData.companyId}
                    onChange={handleForm}
                    helperText={formErrors.companyId}
                    error={formErrors.companyId.length > 0}
                  >
                    <MenuItem value={0}>Select Organization</MenuItem>
                    {companyList.map((company) => (
                      <MenuItem
                        key={company.companyId}
                        value={company.companyId}
                      >
                        {company.companyName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid>
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Button
                className={classes.cancelButton}
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={removeEditingUserHandler}
              >
                Cancel
              </Button>
              <Button
                className={classes.addButton}
                variant="contained"
                onClick={handleConfirmModalOpen}
              >
                Edit USER
              </Button>
            </div>
          </Grid>
        </form>
      </Paper>
      <UserEditConfirmationComponent
        handleClose={handleConfirmModalClose}
        open={confirmModalOpen}
        formData={formData}
        userGroups={userGroups}
      />
    </>
  );
}

export default EditUserComponent;
