import {
    Button,
    Grid,
    makeStyles,
    Modal,
    Backdrop,
    Fade,
    Paper,
    TextField,
    FormGroup,
    LinearProgress,
    Typography
} from '@material-ui/core'
import React from "react"
import { useDispatch, useSelector } from 'react-redux';
import { startLoadDeviceProcessing, updateDevice } from '../../store/actions';
import  { NavLink } from 'react-router-dom'
import {  DEVICE_ROUTE } from "../../../../RouteComponent/PathConfig";

function EditDeviceConfirmationComponent(props) {

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            width: "70%",
            [theme.breakpoints.up('sm')]: {
                width: "60%",
            },
            padding: "20px",
        },
        formControl: {
            margin: '2em 0'
        },
        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        heading: {
            // color: theme.palette.input.text,
        },
        cancelButton: {
            backgroundColor: "red",
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        }
    }))

    const classes = useStyles();
    const dispatch = useDispatch();

    const updateDeviceHanlder = () => {
        let requestObject = {
            Token: props.formData.deviceToken,
            Name: props.formData.deviceName,
            Description: props.formData.description
        };
        dispatch(startLoadDeviceProcessing());
        dispatch(updateDevice(requestObject));
    }

    const loadDeviceProcessing = useSelector(({ device }) => device.loadDeviceProcessing);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableBackdropClick={loadDeviceProcessing}
        >
            <Fade in={props.open}>
                <Paper className={classes.container}>
                <Typography variant="h5" style={{margin: '6px 8px'}} className={classes.heading}>{props.deviceToken}</Typography>
                    <form>
                        <Grid container  >
                            <Grid sm={12} md={5}>

                                <FormGroup>
                                    <div className={classes.formControl}>
                                        <TextField label="Device Name" variant="outlined" size="small" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                            value={props.formData.deviceName} />

                                    </div>
                                </FormGroup>
                                
                            </Grid>
                            <Grid sm={12} md={2}>

                            </Grid>
                            <Grid sm={12} md={4} >
                                {/* <div className={classes.formControl}>
                                    <TextField label="Driver Id" variant="outlined" size="small" fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled
                                        value={props.formData.driverId} />

                                </div> */}
                                <FormGroup>
                                    <div className={classes.formControl}>
                                        <TextField label="Description" variant="outlined" size="small" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                            value={props.formData.description} />

                                    </div>
                                </FormGroup>
                            </Grid>
                            <Grid xm={12} md={12}>
                                <div className={classes.formControl} style={{ textAlign: 'right' }}>
                                    <Button className={classes.cancelButton} variant="contained" style={{ marginRight: '10px' }} disabled={loadDeviceProcessing} onClick={props.handleClose}>Cancel</Button>
                                    <Button className={classes.addButton} variant="contained" onClick={updateDeviceHanlder} disabled={loadDeviceProcessing} component={NavLink} to={DEVICE_ROUTE}>CONFIRM</Button>
                                </div>
                            </Grid>

                        </Grid>
                    </form>
                    {(loadDeviceProcessing) ? (<LinearProgress />) : ''}
                </Paper>
            </Fade>
        </Modal>
    )
}

export default EditDeviceConfirmationComponent
