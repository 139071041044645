import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Divider,
  Collapse,
  Tooltip
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useHistory } from "react-router";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    backgroundColor: theme.palette.cardColor.color,
    borderRadius: 4,
    padding: 10,
  },
  middleItemName: {
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  middleItemValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bottomBox: {
    padding: "10px",
    backgroundColor: theme.palette.cardColor.bottomBoxColor,
  },
  bottomBoxName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bottomBoxValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  stateBox: {
    borderRadius: 20,
    textAlign: "center",
    paddingTop: 1,
    paddingBottom: 1,
  },
}));

const MachineCard = (props) => {
  const { name, state, noDevice, batteryLevel, workingDevice, firstBottomBox, secondBottomBox } = props;
  const { url } = props;
  const classes = useStyles();
  const history = useHistory();
  const [arrow, setArrow] = useState(false);

  const stateColor = (st) => {
    const running = "#1cff03";
    const stope = "#ff0303";
    if (st == null || st == 0) {
      return running;
    } else if (st !== null || st !== 0) {
      return stope;
    }
  };

  const stateTooltip= (st) => {
    const running = "No Alarms";
    const stope = "Alarms Available";
    if (st == null || st == 0) {
      return running;
    } else if (st !== null || st !== 0) {
      return stope;
    }
  };

  // console.log(firstBottomBox)

  return (
    <Box boxShadow={5} className={classes.root}>
      <div style={{ cursor: "pointer" }} onClick={() => history.push(url)}>
        <Grid container xs={12} style={{ marginBottom: "20px" }}>
          <Grid item xs={8}>
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{ height: "50px" }}
            >
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: "flex", justifyContent: "flex-end" }}
          ><Tooltip title={stateTooltip(state)} placement="bottom">
            <FiberManualRecordIcon
              className={classes.stateBox}
              style={{ color: stateColor(state), fontSize: "40px" }}
            />
            </Tooltip>
          </Grid>
        </Grid>
        <Collapse in={arrow} collapsedHeight={70}>
          <Grid container xs={12} style={{ marginBottom: "10px" }}>
            <Grid item container xs={12} style={{ marginBottom: "15Px" }}>
              <Grid item xs={9} className={classes.middleItemName}>
                <Typography>Battery State</Typography>
              </Grid>
              <Grid item xs={3} className={classes.middleItemValue}>
                <Typography>{batteryLevel || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginBottom: "15Px" }}>
              <Grid item xs={9} className={classes.middleItemName}>
                <Typography>Number Of Alarms</Typography>
              </Grid>
              <Grid item xs={3} className={classes.middleItemValue}>
                <Typography>{state || 0}</Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginBottom: "15Px" }}>
              <Grid item xs={9} className={classes.middleItemName}>
                <Typography>Number of Devices</Typography>
              </Grid>
              <Grid item xs={3} className={classes.middleItemValue}>
                <Typography>{noDevice || 0}</Typography>
              </Grid>
            </Grid>
            {/* <Grid container xs={12} style={{ marginBottom: "15Px" }}>
              <Grid item xs={9} className={classes.middleItemName}>
                <Typography>Working Devices</Typography>
              </Grid>
              <Grid item xs={3} className={classes.middleItemValue}>
                <Typography>{workingDevice || 0}</Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </Collapse>
      </div>

      <Divider style={{ marginBottom: "15Px" }} />

      <Grid container direction="row" xs={12}>
        <Grid item xs={arrow ? 12 : 6}>
          <Box
            boxShadow={3}
            className={classes.bottomBox}
            style={
              arrow
                ? { borderRadius: 4, marginBottom: "10px" }
                : { borderRadius: 4, marginRight: "12px" }
            }
          >
            <Grid container xs={12}>
              <Grid item xs={8} className={classes.bottomBoxName}>
                <Typography noWrap style={{ fontSize: "12px", fontWeight: 600 }}>
                  {firstBottomBox.name? firstBottomBox.name:"Na"}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.bottomBoxValue}>
                <Typography noWrap style={{ fontSize: "12px", fontWeight: 600 }}>
                  {firstBottomBox.value? firstBottomBox.value : "Na"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={arrow ? 12 : 6}>
          <Box
            boxShadow={3}
            className={classes.bottomBox}
            style={
              arrow
                ? { borderRadius: 4 }
                : { borderRadius: 4, marginLeft: "12px" }
            }
          >
            <Grid container xs={12}>
              <Grid item xs={8} className={classes.bottomBoxName}>
                <Typography noWrap style={{ fontSize: "12px", fontWeight: 600 }}>
                {secondBottomBox.name? secondBottomBox.name:"Na"}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.bottomBoxValue}>
                <Typography noWrap style={{ fontSize: "12px", fontWeight: 600 }}>
                {secondBottomBox.value? secondBottomBox.value : "Na" }
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        {arrow ? (
          <ArrowDropUpIcon
            onClick={() => setArrow(!arrow)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <ArrowDropDownIcon
            onClick={() => setArrow(!arrow)}
            style={{ cursor: "pointer" }}
          />
        )}
      </Grid>
    </Box>
  );
};

export default MachineCard;
