import { makeStyles, Modal, Backdrop, Fade, Button, TextField, Grid, Paper, MenuItem, LinearProgress, Collapse, IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { addUser, addUserErrorClear } from "../store/actions";

function UserFormConfirmationComponent(props) {

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            width: "70%",
            [theme.breakpoints.up('sm')]: {
                width: "60%",
            },
            padding: "20px",
        },
        formControl: {
            margin: '2em 0'
        },
    }))

    const classes = useStyles();
    const dispatch = useDispatch();

    const addUserProcessing = useSelector(({ user }) => user.addUserProcessing);
    const addUserError = useSelector(({ user }) => user.addUserError);

    const handleAddUser = () => {
        dispatch(addUser(props.formData));
    }

    const clearAddUserError = () => {
        dispatch(addUserErrorClear());
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableBackdropClick={addUserProcessing}
        >
            <Fade in={props.open}>
                <Paper className={classes.container}>

                    <Collapse in={addUserError.length > 0}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={clearAddUserError}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {addUserError}
                        </Alert>
                    </Collapse>

                    <form>
                        <Grid container>
                            <Grid item sm={12} md={5}>
                                <div className={classes.formControl}>
                                    <TextField
                                        label="Frist Name" variant="outlined" size="small" fullWidth name="firstName"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={props.formData.firstName}
                                        disabled
                                    />
                                </div>
                                <div className={classes.formControl}>
                                    <TextField
                                        label="Last Name" variant="outlined" size="small" fullWidth name="lastName"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={props.formData.lastName}
                                        disabled
                                    />
                                </div>
                                <div className={classes.formControl}>
                                    <TextField
                                        label="Contact No" variant="outlined" size="small" fullWidth name="contactNo"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={props.formData.contactNo}
                                        disabled
                                    />
                                </div>
                                <div className={classes.formControl}>
                                    <FormControlLabel
                                        control={<Checkbox  color="primary" disabled name="alertEnable" checked={props.formData.alertEnable} />}
                                        label="Alert Enable"
                                        labelPlacement="start"
                                    />
                                </div>
                            </Grid>
                            <Grid item sm={false} md={2}>

                            </Grid>
                            <Grid item sm={12} md={5}>
                                <div className={classes.formControl}>
                                    <TextField
                                        label="Designation" variant="outlined" size="small" fullWidth name="designation"
                                        InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                                root: classes.cssLabel,

                                            }
                                        }}

                                        value={props.formData.designation}
                                        disabled
                                    />
                                </div>
                                <div className={classes.formControl}>
                                    <TextField
                                        label="Email" variant="outlined" size="small" fullWidth name="userEmail"
                                        type="email"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={props.formData.userEmail}
                                        disabled
                                    />
                                </div>
                                {(props.userGroups) ? (
                                    <div className={classes.formControl}>
                                        <TextField
                                            label="User Group" variant="outlined" size="small" fullWidth
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={props.formData.usrGroupId}
                                            disabled
                                        >
                                            <MenuItem value={0}>Select group</MenuItem>
                                            {props.userGroups.map(group => (
                                                <MenuItem key={group.usrGroupId} value={group.usrGroupId}>{group.usrGroupDescription}</MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                ) : ''}
                                {(props.companyList) ? (
                                    <div className={classes.formControl}>
                                        <TextField
                                            label="Organization" variant="outlined" size="small" fullWidth
                                            name="companyId"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                                classes: {
                                                    root: classes.cssLabel,
        
                                                }
                                            }}
                                            InputProps={{
                                                className: classes.forminput,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                            value={props.formData.companyId}
                                            disabled
                                        >
                                            <MenuItem value={0}>Select Organization</MenuItem>
                                            {props.companyList.map(company => (
                                                <MenuItem key={company.companyId} value={company.companyId}>{company.companyName}</MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                ) : ''}

                                <div className={classes.formControl} style={{ textAlign: 'right' }}>
                                    <Button color="inherit" variant="contained" style={{ marginRight: '10px' }} onClick={() => { props.handleClose(); clearAddUserError(); }} disabled={addUserProcessing}>Cancel</Button>
                                    <Button color="primary" variant="contained" disabled={addUserProcessing} onClick={handleAddUser}>Confirm</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                    {(addUserProcessing) ? (<LinearProgress />) : ''}
                </Paper>
            </Fade>
        </Modal>
    )
}

export default UserFormConfirmationComponent
