import * as ACTIONS from '../actions';
const initialState = {
    companies: [],
    companyCount: 0,
    // devices: [],
    // userGroups: [],
    addCompanyProcessing: false,
    loadCompanyProcessing: false,
    addCompanyFormReset: false,
    addCompanyError: '',
    // getUserError: '',
    editingCompany: null,
    editingCompanyId: null,
    // userDeletetionFlag: false,
    // editUserProcessing: false,
    // editUserFormReset: false,
    getCompaniesError: '',
};

const companyReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_LOADING: {
            return {
                ...state,
                loadCompanyProcessing: true
            }
        }
        case ACTIONS.STOP_LOADING: {
            return {
                ...state,
                loadCompanyProcessing: false
            }
        }
        case ACTIONS.ADD_COMPANY_PROCESSING: {
            return {
                ...state,
                addCompanyProcessing: true
            }
        }
        case ACTIONS.ADD_COMPANY: {
            if(action.payload === 'Ok'){
                return {
                    ...state,
                    addCompanyProcessing: false,
                    addCompanyFormReset: true
                }
            }else{
                return {
                    ...state,
                    addCompanyProcessing: false,
                    addCompanyError: action.payload
                }
            }
        }
        // case ACTIONS.GET_USER_GROUPS: {
        //     return {
        //         ...state,
        //         userGroups: action.payload
        //     }
        // }
        case ACTIONS.GET_COMPANIES: {
            return {
                ...state,
                companies: action.payload.data,
                companyCount: action.payload.dataCount
            }
        }
        case ACTIONS.ADD_COMPANY_FORM_RESET_FALSE: {
            return {
                ...state,
                addCompanyFormReset: false
            }
        }
        case ACTIONS.ADD_COMPANY_ERROR_CLEAR: {
            return {
                ...state,
                addCompanyError: '',
            }
        }
        // case ACTIONS.GET_DEVICES: {
        //     return {
        //         ...state,
        //         devices: action.payload
        //     }
        // }
        case ACTIONS.GET_COMPANIES_ERROR: {
            return {
                ...state,
                getCompaniesError: 'Network error! Please check your internet connection',
                addCompanyProcessing: false
            }
        }
        // case ACTIONS.CLEAR_GET_USER_ERROR: {
        //     return {
        //         ...state,
        //         getUserError: ''
        //     }
        // }
        case ACTIONS.SET_EDITING_COMPANY_ID: {
            return {
                ...state,
                editingCompanyId: action.payload
            }
        }
        case ACTIONS.GET_EDITING_COMPANY: {
            return {
                ...state,
                editingCompany: action.payload,
                addCompanyProcessing: false
            }
        }
        case ACTIONS.REMOVE_EDITING_COMPANY: {
            return {
                ...state,
                editingCompany: null,
                editingCompanyId: null,
                addCompanyProcessing: false
            }
        }
        // case ACTIONS.DELETE_USER: {
        //     return {
        //         ...state,
        //         userDeletetionFlag: !state.userDeletetionFlag
        //     }
        // }
        // case ACTIONS.EDIT_USER: {
        //     return {
        //         ...state,
        //         addUserProcessing: false,
        //         addUserFormReset: true
        //     }
        // }
        default: {
            return state;
        }
    }
};

export default companyReducer;