import * as ACTIONS from '../actions';
const initialState = {
    profileDetails: null,
    userPermissions: null,
    appLoading: false,
};

const userReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_APP_LOADING: {
            return {
                ...state,
                appLoading: true
            }
        }
        case ACTIONS.STOP_APP_LOADING: {
            return {
                ...state,
                appLoading: false
            }
        }
        case ACTIONS.GET_PROFILE_DETAILS: {
            return {
                ...state,
                profileDetails: action.payload
            }
        }
        case ACTIONS.GET_USER_PERMISSIONS: {
            return {
                ...state,
                userPermissions: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default userReducer;