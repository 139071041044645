import axios from "axios";

const customAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const requestHandler = request => {
    const USER = JSON.parse(sessionStorage.getItem('user'));
    if(USER){
        request.headers.Authorization = `Bearer ${USER.data.data.token}`;
    }
    
  
    return request;
};

const responseHandler = response => {
    
    // if (response.status === 403) {
    //     sessionStorage.removeItem('user');
    // }

    return response;
};

const errorHandler = error => {
    // console.log(error)
    if(error.response && error.response.status === 403){

        sessionStorage.removeItem('user');
        window.location = '/login';

    }
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );

 export default customAxios;