import { LinearProgress } from "@material-ui/core";
import { makeStyles, CssBaseline, ThemeProvider } from "@material-ui/core"
import React, { useEffect,useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutComponent from "../../Layout/LayoutComponent"
import RouteComponent from "../../RouteComponent/RouteComponent";
import AuthService from "../../Services/AuthService/AuthService";
import { useCookies } from "react-cookie"
import { lightTheme, darkTheme }  from "../../Theme/Theme";


import { getUserPermissions } from "./store/actions";

function MainComponent(props) {
    const drawerWidth = 225;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            minHeight: "100vh",           
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            marginTop: theme.spacing.unit,
            justifyContent: "flex-end",
            padding: "0 8px",
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    }))
    

    const classes = useStyles()
    const dispatch = useDispatch();
    const history = useHistory();

    const userPermissions = useSelector(({ main }) => main.userPermissions);

    useEffect(() => {
        dispatch(getUserPermissions())
    }, [dispatch]);

    if (!AuthService.isAuthenticated()) {
        history.push('/login');
    }

    const [cookies] = useCookies(["theme"])
    const mode = cookies.theme

    return (
        <ThemeProvider theme={mode === "Light" ? lightTheme() : darkTheme()}>
        <div className={classes.root}>
            {(userPermissions) ? (
                <>
                    <CssBaseline />
                    <LayoutComponent drawerWidth={drawerWidth} />
                    <main className={classes.content} style={{backgroundColor:mode === "Light" ? "#FFFFFF":"#090909"}}>
                        <div className={classes.toolbar} />
                        {/* <BreadcumbComponent /> */}
                        <RouteComponent />

                    </main>
                </>
            ) : (
                <LinearProgress />
            )}
            </div>
       
        </ThemeProvider>
    )
}

export default MainComponent
