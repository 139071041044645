import {
    AppBar,
    IconButton,
    makeStyles,
    MenuItem,
    Toolbar,
    Button

} from "@material-ui/core"
import classNames from "classnames"
import React, { useState } from "react";
import { NavLink } from 'react-router-dom'
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Menu from "@material-ui/core/Menu";
import LogoComponent from "./LogoComponent";
import AuthService from "../../Services/AuthService/AuthService";
import { useCookies } from "react-cookie";
function HeaderComponent(props) {
    const useStyles = makeStyles((theme) => ({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.primary.heder,
            // color: theme.palette.secondary.main,
        },
        appBarShift: {
            marginLeft: props.drawerWidth,
            width: `calc(100% - ${props.drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginLeft: 12,
            marginRight: 36,
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(["transform"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: "rotate(0deg)",
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(["transform"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: "rotate(180deg)",
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            marginTop: theme.spacing.unit,
            justifyContent: "flex-end",
            padding: "0 8px",
            ...theme.mixins.toolbar,


        },
        grow: {
            flexGrow: 1,
        },
        icons: theme.typography.icons,
    }))
    const drawerWidth = props.drawerWidth
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    // const history = useHistory();
    const logOut = () => {
        AuthService.logout();
        // history.push("/login");
    }

    const [cookies, setCookies] = useCookies(["theme"])

    const theme = cookies.theme

    const changeTheme = () => {
        theme === "Light" ? setCookies("theme", "Dark", { path: "/" }) : setCookies("theme", "Light", { path: "/" })

    }



    return (
        <AppBar
            position="fixed"
            className={classes.appBar}
            fooJon={classNames(classes.appBar, {
                [classes.appBarShift]: props.sidebarOpen,
            })}

        >
            <Toolbar disableGutters={true} >
                <LogoComponent drawerWidth={drawerWidth} />
                <IconButton
                    
                    aria-label="Open drawer"
                    onClick={props.handleDrawer}
                    // className={classes.menuButton, classes.icons}
                >
                    <MenuIcon
                        classes={{
                            root: props.sidebarOpen
                                ? classes.menuButtonIconOpen
                                : classes.menuButtonIconClosed,
                        }}
                    />
                </IconButton>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.grow}
                    noWrap
                >
                    {/* Mini variant menu++ */}
                </Typography>
                <Button onClick={changeTheme}>
                    {theme === "Dark" ? <WbSunnyIcon  /> : <Brightness2Icon  />}
                </Button>


                <div>
                    <IconButton
                        aria-owns={props.sidebarOpen ? "menu-appbar" : undefined}
                        aria-haspopup="true"
                        onClick={handleMenu}
                        className={classes.icons}
                    >
                        <AccountCircle />
                    </IconButton>


                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={anchorEl}
                        onClose={handleMenuClose}
                    >

                        <MenuItem component={NavLink} to="/myaccount" onClick={handleMenuClose} >
                            My Account
                        </MenuItem>
                        <MenuItem onClick={logOut}>
                            Log Out
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>

        </AppBar >
    )
}

export default HeaderComponent
