import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";

import AddCompanyConfirmation from "../AddCompanyConfirmation/AddCompanyConfirmation";
import { useDispatch, useSelector } from "react-redux";
import ReactPhoneInput from "react-phone-input-material-ui";
// import { addDeviceFormResetToFalse, getDevices, getUsers } from '../store/actions';
import {
  firstNamePattern,
  emailPattern,
  contactPattern,
} from "../../../Config/RegExp";
import {
  addCompanyFormResetToFalse,
  getEditingCompany,
  setEditingCompanyId,
} from "../store/actions";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

function EditCompanyComponent(props) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      padding: "20px",
      // backgroundColor: theme.palette.paper.main
    },
    text: {
      // color: theme.palette.Breadcrumb.text,
      marginTop: "20px",
    },
    formControl: {
      margin: "1em 0",

      "& .special-label": {
        background: "#ffffff",
        color: "#868686",
      },
    },
    formControlDark: {
      margin: "1em 0",

      "& .special-label": {
        background: "#424242",
      },
    },
    inputField: {
      margin: "1em 0",
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.palette.inputBoxSecond.color,
      },
    },
    cssLabel: {
      // // color: theme.palette.input.label,
      "&.Mui-focused": {
        // // color: theme.palette.input.labelClick,
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    Accordion: {
      backgroundColor: theme.palette.secondary.main,
    },

    input: {
      width: "90%",
      padding: 2,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.primary.main,
      margin: "20px 0",
    },
    forminput: {
      // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
      borderWidth: "1px",
      // borderColor: theme.palette.input.borderColor
    },
    employeeCard: {
      border: "none",
    },
    backcolor: "red",
    cancelButton: {
      backgroundColor: "red",
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();

  const [cookies] = useCookies(["theme"]);
  const mode = cookies.theme;

  let phoneNoColor = "#ffffff";
  let phoneNoLabelColor = "#737373";

  if (mode === "Dark") {
    phoneNoColor = "#424242";
    phoneNoLabelColor = "#fafafa";
  }

  const defaultFormData = {
    companyId: "",
    companyName: "",
    // companyCode: '',
    companyEmail: "",
    contactNo1: "",
    contactNo2: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    countryId: 13,
  };

  const defaultFormErrors = {
    companyId: "",
    companyName: "",
    companyEmail: "",
    contactNo1: "",
    contactNo2: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    countryId: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const handleForm = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validateFormData = () => {
    const errors = defaultFormErrors;
    let isHaveErrors = false;

    // if (formData.companyID === '') {
    //     errors.companyID = "Company ID is not valid";
    //     isHaveErrors = true;
    // }
    if (formData.companyName === "") {
      errors.companyName = "Company Name is not valid";
      isHaveErrors = true;
    }
    // if (formData.companyCode === '') {
    //     errors.companyCode = 'Company Codes is not valid';
    //     isHaveErrors = true;
    // }

    if (
      formData.companyEmail === "" ||
      !emailPattern.test(formData.companyEmail)
    ) {
      errors.companyEmail = "Company Email is not valid";
      isHaveErrors = true;
    }
    if (formData.contactNo1 === "" || formData.contactNo1.length < 5) {
      errors.contactNo1 = "Company Contact is not valid";
      isHaveErrors = true;
    }
    setFormErrors(errors);
    return isHaveErrors;
  };
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const handleConfirmModalOpen = () => {
    if (!validateFormData()) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const resetForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultFormErrors);
  };

  const addCompanyFormReset = useSelector(
    ({ company }) => company.addCompanyFormReset
  );

  useEffect(() => {
    if (addCompanyFormReset) {
      resetForm();
      dispatch(addCompanyFormResetToFalse());
      handleConfirmModalClose();
    }
  }, [addCompanyFormReset, dispatch]);

  const editingCompanyId = useSelector(
    ({ company }) => company.editingCompanyId
  );
  const editingCompany = useSelector(({ company }) => company.editingCompany);

  const history = useHistory();

  useEffect(() => {
    if (editingCompanyId) {
      dispatch(getEditingCompany(editingCompanyId));
    } else {
      history.push("/company");
    }
  }, [dispatch, editingCompanyId]);

  useEffect(() => {
    if (editingCompany) {
      setFormData({
        ...editingCompany,
        contactNo1: editingCompany.contactNo1.toString(),
        contactNo2: editingCompany.contactNo2.toString(),
      });
    }
  }, [editingCompany]);

  const redirectToView = () => {
    dispatch(setEditingCompanyId(null));
  };

  return (
    <>
      <Paper className={classes.container}>
        <form>
          <Grid container>
            <Grid sm={12} md={5} style={{ margin: "0 auto" }}>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyName"
                    onChange={handleForm}
                    helperText={formErrors.companyName}
                    value={formData.companyName}
                    error={formErrors.companyName.length > 0}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyEmail"
                    onChange={handleForm}
                    helperText={formErrors.companyEmail}
                    value={formData.companyEmail}
                    error={formErrors.companyEmail.length > 0}
                  />
                </div>
              </FormGroup>
              <Grid container justify="space-between">
                <Grid item md={5} sm={11}>
                  <div
                    className={
                      mode === "Dark"
                        ? classes.formControlDark
                        : classes.formControl
                    }
                  >
                    <ReactPhoneInput
                      name="contactNo1"
                      label="Contact No 1"
                      variant="outlined"
                      size="small"
                      inputStyle={{
                        width: "100%",
                        padding: "12px 25px 0px 58px",
                        backgroundColor: phoneNoColor,
                        borderColor: phoneNoLabelColor,
                      }}
                      specialLabel="Contact No 1"
                      value={formData.contactNo1}
                      country="au"
                      onChange={(e) =>
                        setFormData((prev) => {
                          return {
                            ...prev,
                            contactNo1: e,
                          };
                        })
                      } // passed function receives the phone value
                      component={TextField}
                      helperText={formErrors.contactNo1}
                      error={formErrors.contactNo1.length > 0}
                    />
                  </div>
                  {formErrors.contactNo1.length > 0 && (
                    <FormHelperText error={true}>
                      {formErrors.contactNo1}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} sm={11}>
                  <div
                    className={
                      mode === "Dark"
                        ? classes.formControlDark
                        : classes.formControl
                    }
                  >
                    <ReactPhoneInput
                      name="contactNo2"
                      label="Contact No 2"
                      variant="outlined"
                      size="small"
                      inputStyle={{
                        width: "100%",
                        padding: "12px 25px 0px 58px",
                        backgroundColor: phoneNoColor,
                        borderColor: phoneNoLabelColor,
                      }}
                      value={formData.contactNo2}
                      specialLabel="Contact No 2"
                      country="au"
                      onChange={(e) =>
                        setFormData((prev) => {
                          return {
                            ...prev,
                            contactNo2: e,
                          };
                        })
                      } // passed function receives the phone value
                      component={TextField}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid sm={12} md={5} style={{ margin: "0 auto" }}>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Add Line 1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="addressLine1"
                    onChange={handleForm}
                    helperText={formErrors.addressLine1}
                    value={formData.addressLine1}
                    error={formErrors.addressLine1.length > 0}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Add Line 2"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="addressLine2"
                    onChange={handleForm}
                    helperText={formErrors.addressLine2}
                    value={formData.addressLine2}
                    error={formErrors.addressLine2.length > 0}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Add Line 3"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="addressLine3"
                    onChange={handleForm}
                    helperText={formErrors.addressLine3}
                    value={formData.addressLine3}
                    error={formErrors.addressLine3.length > 0}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="City"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="city"
                    onChange={handleForm}
                    helperText={formErrors.city}
                    value={formData.city}
                    error={formErrors.city.length > 0}
                  />
                </div>
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <div style={{ textAlign: "right" }}>
                <Button 
                  className={classes.cancelButton}
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={redirectToView}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.addButton}
                  variant="contained"
                  onClick={handleConfirmModalOpen}
                >
                  UPDATE Organization
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <AddCompanyConfirmation
        isEdit={true}
        handleClose={handleConfirmModalClose}
        open={confirmModalOpen}
        formData={formData}
      />
    </>
  );
}

export default EditCompanyComponent;
