import * as ACTIONS from '../actions';
const initialState = {
    reportData: [],
    reportLoading: false,
    devices: []
};

const userReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_REPORT_LOADING: {
            return {
                ...state,
                reportLoading: true
            }
        }
        case ACTIONS.STOP_REPORT_LOADING: {
            return {
                ...state,
                reportLoading: false
            }
        }
        case ACTIONS.GET_REPORT: {
            return {
                ...state,
                reportData: action.payload
            }
        }
        case ACTIONS.GET_DEVICES: {
            return {
                ...state,
                devices: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default userReducer;