import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { Provider } from "react-redux"
import store from "./Store/storeConfig"
import MainComponent from "./Components/MainComponent/MainComponent"
import { LOGIN_ROUTE,COMPANY_ADMIN_UPDATE } from "./RouteComponent/PathConfig"
import LoginComponents from "./Components/LoginComponent/LoginComponents"
import AdminProfileUpdateComponent from "./Components/LoginComponent/AdminProfileUpdateComponent/AdminProfileUpdateComponent"
import { useEffect, useState } from "react"
import AuthService from "./Services/AuthService/AuthService"
import { useCookies } from "react-cookie"
function App() {
    const [user, setUser] = useState(null)

    const [cookies, setCookies] = useCookies(["theme"])
    const mode = cookies.theme

    useEffect(() => {
        setUser(AuthService.isAuthenticated())
    }, [])

    useEffect(() => {
        if(!mode){
            setCookies('theme','Dark')
        }
    }, [])
    

    // const [cookies, setCookies] = useCookies(["theme"])
    // const mode = cookies.theme

    return (
        // <ThemeProvider theme={mode === "Light" ? lightTheme() : darkTheme()}>
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path={LOGIN_ROUTE}
                        component={() =>
                            !user ? <LoginComponents /> : <Redirect to="/company/admin/update" />
                        }
                    />
                    <Route
                        exact
                        path={COMPANY_ADMIN_UPDATE}
                        component={AdminProfileUpdateComponent}
                    />
                    <Provider store={store}>
                        <MainComponent />
                    </Provider>
                </Switch>
            </BrowserRouter>
        // </ThemeProvider>
    )
}

export default App
