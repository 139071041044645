import React, { useState } from 'react'
import { Grid, makeStyles, Paper, Button, Typography, TextField, Link, FormGroup, FormHelperText } from '@material-ui/core'
import ReactPhoneInput from 'react-phone-input-material-ui';
import mainImage from '../../../assets/images/login_image.png'
import logo from '../../../assets/images/logo.jpeg'
import Alert from '@material-ui/lab/Alert';
// import AuthService from '../../../Services/AuthService/AuthService';
import { useHistory } from 'react-router-dom'
import customAxios from '../../../Services/customAxios/CustomAxios';


const AdminProfileUpdateComponent = (props) => {
    const UseStyles = makeStyles((theme) => ({
        root: {

            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            // // backgroundColor: theme.palette.dashboard.main,
            minHeight: "100vh"
        },
        title: {
            marginBottom: theme.spacing(3),
            // color: theme.palette.primary.main,
            // color: theme.palette.input.background,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(2),


        },
        loginCard: {

            backgroundColor: theme.palette.primary.main,
            height: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: 'center',
        },
        paper: {
            marginTop: theme.spacing(10),
        },
        logo: {
            width: '100%',
        },
        mainImage: {
            width: '100%',
            borderRadius: '5%',
            backgroundSize: 'cover',
            height: '450px'
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        cssOutlinedInput: {
            '&$notchedOutline': {
                borderColor: `${theme.palette.secondary.main} !important`,
            }
        },
        cssLabel: {
            // color: theme.palette.input.background,
            "&.Mui-focused": {
                // color: theme.palette.input.background,

            }
        },
        notchedOutline: {
            borderWidth: '2px',
            borderColor: `${theme.palette.secondary.main} !important`
        },
        input: {
            "& .MuiInputBase-root": {
                color: theme.palette.secondary.main,

            }
        }
    }))
    const classes = UseStyles();

    const history = useHistory();

    const defaultFormData = {
        employNo: '',
        firstName: '',
        lastName: '',
        designation: '',
        contactNo: '',

    };

    const defaultFormErrors = {
        firstName: '',
        lastName: '',
        designation: '',
        contactNo: '',
    };

    const [formData, setFormData] = useState(defaultFormData);
    const [formErrors, setFormErrors] = useState(defaultFormErrors);

    const handleForm = (event) => {
        setFormData(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        });
    }
    const validationForm = () => {
        const errors = defaultFormErrors;
        let isHaveErrors = false;
        if (formData.lastName === '') {
            errors.lastName = "Last Name is not valid";
            isHaveErrors = true;
        }
        if (formData.firstName === '') {
            errors.firstName = "First Name is not valid";
            isHaveErrors = true;
        }
        if (formData.designation === '') {
            errors.designation = "Designation is not valid";
            isHaveErrors = true;
        }
        if (formData.contactNo === '' || formData.contactNo.length < 5) {
            errors.contactNo = "Contact No is not valid";
            isHaveErrors = true;
        }
        setFormErrors(errors);
        return isHaveErrors;
    }

    const submitForm = () => {
        if (!validationForm()) {
            customAxios.post(process.env.REACT_APP_API_BASE_URL + '/usermanagement/updateuserdetails/self', formData)
            .then(response => {
                window.location = "/";
            }).catch(error => {})
        }
    }



    return (
        <div container className={classes.root} spacing={2} >
            <Grid container md={8} >
                <Paper elevation={8} className={classes.paper} >
                    <Grid container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center"

                    >

                        <Grid container md={7} direction="row"
                            alignItems="center"
                            justify="center" >
                            <Grid md={10} style={{ textAlign: 'center' }} >
                                
                                {/* <img src={logo} alt="Green IT" className={classes.logo} /> */}
                                {/* <Typography variant="h5"
                                    color="inherit">
                                    <span className={classes.title}>Device Monitoring and </span> Alerting System </Typography> */}
                                <img src={mainImage} alt="Green IT" className={classes.mainImage} />
                            </Grid>
                        </Grid>

                        <Grid container md={5} direction="row"
                            alignItems="center"
                            justify="center">
                            <Paper className={classes.loginCard}>
                                    
                                <Grid item xs={10} spacing={5}>
                                    
                                    <form className={classes.form}  >
                                    <Typography variant="h5" align="center" className={classes.title} >
                                    User Details Update
                                </Typography>
                                        <TextField label="First Name" margin="normal" variant="outlined" size="small" fullWidth name="firstName"
                                            className={classes.input}
                                            autoFocus
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    notchedOutline: classes.notchedOutline,
                                                },

                                            }}
                                            onChange={handleForm}
                                            helperText={formErrors.firstName}
                                            value={formData.firstName}
                                            error={(formErrors.firstName.length > 0)}

                                        />
                                        <TextField label="Last Name" margin="normal" variant="outlined" size="small" fullWidth name="lastName"
                                            className={classes.input}
                                            autoFocus
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    notchedOutline: classes.notchedOutline,
                                                },

                                            }}
                                            onChange={handleForm}
                                            helperText={formErrors.lastName}
                                            value={formData.lastName}
                                            error={(formErrors.lastName.length > 0)}

                                        />
                                        <TextField label="Designation" margin="normal" variant="outlined" size="small" fullWidth name="designation"
                                            className={classes.input}
                                            autoFocus
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    notchedOutline: classes.notchedOutline,
                                                },

                                            }}
                                            onChange={handleForm}
                                            helperText={formErrors.designation}
                                            value={formData.designation}
                                            error={(formErrors.designation.length > 0)}

                                        />
                                        <FormGroup>
                                            <div className={classes.formControl}>
                                                <ReactPhoneInput name="contactNo" label="Contact No " variant="outlined" size="small" inputStyle={{ width: '100%', padding: '12px 25px 0px 58px', backgroundColor: classes.cssLabel }}
                                                    specialLabel="Contact No"
                                                    value={formData.contactNo1}
                                                    country='au'
                                                    onChange={(e) => setFormData(prev => {
                                                        return {
                                                            ...prev,
                                                            contactNo: e
                                                        }
                                                    })} // passed function receives the phone value
                                                    component={TextField}
                                                    helperText={formErrors.contactNo}
                                                    error={(formErrors.contactNo.length > 0)}
                                                />
                                                {(formErrors.contactNo.length > 0) && (<FormHelperText error={true}>{formErrors.contactNo}</FormHelperText>)}
                                            </div>
                                        </FormGroup>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            className={classes.submit}
                                            onClick={submitForm}
                                        >
                                            Update
                                        </Button>
                                        <Grid container>
                                            <Grid item xs>

                                            </Grid>
                                            <Grid item >
                                                {/* <Link href="#" variant="body2" style={{ color: "white" }}>
                                                    Forgot password?
                                                </Link> */}
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </div>
    )
}



export default AdminProfileUpdateComponent
