import axios from "axios";

class AuthService {
    login(username, password) {
        return axios.post(process.env.REACT_APP_API_BASE_URL + '/auth/signin', { username, password })
    }
    storeSession(data) {
        sessionStorage.setItem('user', JSON.stringify({ data }));
    }
    isAuthenticated() {
        return (sessionStorage.getItem('user')) ? true : false;
    }
    logout() {
        sessionStorage.clear()
        window.location = '/login'; 
    }
}

export default new AuthService();