import {
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Grid,
  Paper,
  MenuItem,
  LinearProgress,
  Collapse,
  IconButton,
  Typography,
  FormGroup,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { addUser, addCompanyErrorClear } from "../store/actions";
import ReactPhoneInput from "react-phone-input-material-ui";
import {
  addCompany,
  addCompanyErrorClear,
  editCompany,
} from "../store/actions";
import { useCookies } from "react-cookie";

function AddCompanyConfirmation(props) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    container: {
      width: "70%",
      [theme.breakpoints.up("sm")]: {
        width: "60%",
      },
      padding: "20px",
    },
    formControl: {
      margin: "1em 0",
    },
    formControl: {
      margin: "1em 0",

      "& .special-label": {
        background: "#ffffff",
        color:'#868686'
      },
    },
    formControlDark: {
      margin: "1em 0",
      
      "& .special-label": {
        background: "#424242",
      },
    },
    inputField: {
      margin: "1em 0",
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.inputBoxSecond.color
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.inputBoxSecond.color
          
      },"& .MuiInputLabel-root.Mui-focused": {
          color: theme.palette.inputBoxSecond.color
      },
    },
    cancelButton: {
      backgroundColor: "red",
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();
  const [cookies] = useCookies(["theme"]);
  const mode = cookies.theme;
  const dispatch = useDispatch();

  let phoneNoColor = "#ffffff";
  let phoneNoLabelColor = "#737373";

  if (mode === "Dark") {
    phoneNoColor = "#424242";
    phoneNoLabelColor = "#fafafa";
  }

  const addCompanyProcessing = useSelector(
    ({ company }) => company.addCompanyProcessing
  );
  const addCompanyError = useSelector(({ company }) => company.addCompanyError);

  const handleAddCompany = () => {
    if (props.isEdit) {
      dispatch(editCompany(props.formData));
    } else {
      dispatch(addCompany(props.formData));
    }
  };

  const clearaddCompanyError = () => {
    dispatch(addCompanyErrorClear());
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick={addCompanyProcessing}
    >
      <Fade in={props.open}>
        <Paper className={classes.container}>
        <Typography style={{margin:"0px 0px 20px 0px"}} variant='h5'>Confirm Add Organization details</Typography>
          <Collapse in={addCompanyError.length > 0}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={clearaddCompanyError}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {addCompanyError}
            </Alert>
          </Collapse>

          <form>
            <Grid container>
              <Grid sm={12} md={5} style={{ margin: "0 auto" }}>
                <Typography
                  variant="h5"
                  component="h5"
                  gutterBottom
                  align="left"
                  className={classes.text}
                >
                  Organization Details
                </Typography>
                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="companyName"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.companyName}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="companyEmail"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.companyEmail}
                    />
                  </div>
                </FormGroup>
                <Grid container justify="space-between">
                  <Grid item md={5} sm={11}>
                  <div
                    className={
                      mode === "Dark"
                        ? classes.formControlDark
                        : classes.formControl
                    }
                  >
                    <ReactPhoneInput
                      name="companyContact1"
                      label="Contact No 1"
                      variant="outlined"
                      size="small"
                      inputStyle={{
                        width: "100%",
                        padding: "12px 25px 0px 58px",
                        backgroundColor: phoneNoColor,
                        borderColor: phoneNoLabelColor
                      }}
                      value={props.formData.contactNo1}
                      specialLabel="Contact No 1"
                      country="in"
                      disabled
                      component={TextField}
                    />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={11}>
                  <div
                    className={
                      mode === "Dark"
                        ? classes.formControlDark
                        : classes.formControl
                    }
                  >
                    <ReactPhoneInput
                      name="companyContact2"
                      label="Contact No 2"
                      variant="outlined"
                      size="small"
                      inputStyle={{
                        width: "100%",
                        padding: "12px 25px 0px 58px",
                        backgroundColor: phoneNoColor,
                        borderColor: phoneNoLabelColor
                      }}
                      value={props.formData.contactNo2}
                      specialLabel="Contact No 2"
                      country="lk"
                      disabled
                      component={TextField}
                    />
                    </div>
                  </Grid>
                </Grid>

                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Add Line 1"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="addressLine1"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.addressLine1}
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Add Line 2"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="addressLine2"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.addressLine2}
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Add Line 3"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="addressLine3"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.addressLine3}
                    />
                  </div>
                </FormGroup>
                {/* <Grid container justify="space-between"> */}
                {/* <Grid item md={5} sm={11}> */}
                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="City"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="city"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.city}
                    />
                  </div>
                </FormGroup>
              </Grid>
              <Grid sm={12} md={5} style={{ margin: "0 auto" }}>
                <Typography
                  variant="h5"
                  component="h5"
                  gutterBottom
                  align="left"
                  className={classes.text}
                >
                  Admin Details
                </Typography>
                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Admin User Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="companyAdminName"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.companyAdminName}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className={classes.inputField}>
                    <TextField
                      label="Admin Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="companyAdminEmail"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      disabled
                      value={props.formData.companyAdminEmail}
                    />
                  </div>
                </FormGroup>
              </Grid>
              <Grid item md={12}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    color="inherit"
                    variant="contained"
                    className={classes.cancelButton}
                    onClick={props.handleClose}
                    style={{ marginRight: "10px" }}
                    disabled={addCompanyProcessing}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    variant="contained"
                    onClick={handleAddCompany}
                    disabled={addCompanyProcessing}
                  >
                    {props.isEdit ? "EDIT " : "ADD "}Organization
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
          {addCompanyProcessing ? <LinearProgress /> : ""}
        </Paper>
      </Fade>
    </Modal>
  );
}

export default AddCompanyConfirmation;
