import axios from "axios";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_USERS = 'USER_COMPONENT - GET_USERS';
export const GET_USER_ERROR = 'USER_COMPONENT - GET_USER_ERROR';
export const CLEAR_GET_USER_ERROR = 'USER_COMPONENT - CLEAR_GET_USER_ERROR';
export const GET_DEVICES = 'USER_COMPONENT - GET_DEVICES';
export const GET_USER_GROUPS = 'USER_COMPONENT - GET_USER_GROUPS';
export const ADD_USER = 'USER_COMPONENT - ADD_USER';

export const START_LOADING = 'USER_COMPONENT - START_LOADING';
export const STOP_LOADING = 'USER_COMPONENT - STOP_LOADING';

export const ADD_USER_PROCESSING = 'USER_COMPONENT - ADD_USER_PROCESSING';
export const ADD_USER_FORM_RESET_FALSE = 'USER_COMPONENT - ADD_USER_FORM_RESET_FALSE';
export const ADD_USER_ERROR_CLEAR = 'USER_COMPONENT - ADD_USER_ERROR_CLEAR';

export const SET_EDITING_USER_ID = 'USER_COMPONENT - SET_EDITING_USER_ID';
export const GET_EDITING_USER = 'USER_COMPONENT - GET_EDITING_USER';
export const REMOVE_EDITING_USER = 'USER_COMPONENT - REMOVE_EDITING_USER';

export const EDIT_USER = 'USER_COMPONENT - EDIT_USER';
export const EDIT_USER_PROCESSING = 'USER_COMPONENT - EDIT_USER_PROCESSING';
export const EDIT_USER_FORM_RESET_FALSE = 'USER_COMPONENT - EDIT_USER_FORM_RESET_FALSE';
export const EDIT_USER_ERROR_CLEAR = 'USER_COMPONENT - EDIT_USER_ERROR_CLEAR';

export const DELETE_USER = 'USER_COMPONENT - DELETE_USER';
export const GET_COMPANIES = 'USER_COMPONENT - GET_COMPANIES';


export function getUsers(page, rowsPerPage, searchText, sortParameter, sortType) {

	return dispatch =>{

        dispatch(startLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + 
			`/usermanagement/getUserListInCompany?page=${page}&rowsPerPage=${rowsPerPage}&searchText=${searchText}&sortParameter=${sortParameter}&sortType=${sortType}`)
				.then(
					response => {
						dispatch(stopLoading());
						return dispatch({
							type: GET_USERS,
							payload: response.data
						})
						
					}
				).catch(error => {
					dispatch(stopLoading());
					return dispatch({
						type: GET_USER_ERROR,
					})
				});
	}
}
export function deleteUsers(userId) {

	return dispatch =>{

		customAxios.put(process.env.REACT_APP_API_BASE_URL + `/usermanagement/deleteuser/${userId}`)
				.then(
					response => {
						return dispatch({
							type: DELETE_USER,
						})
						
					}
				)
	}
}

export function getEditingUser(userId) {

	return dispatch =>{

		customAxios.get(process.env.REACT_APP_API_BASE_URL + `/usermanagement/getuserdetails/${userId}`)
				.then(
					response => {
						return dispatch({
							type: GET_EDITING_USER,
							payload: response.data
						})
						
					}
				)
	}
}

export function getDevices() {

	return dispatch =>{

        dispatch(startLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/divicemanagement/groupdetailsLlist/")
				.then(
					response => {
						dispatch(stopLoading());
						return dispatch({
							type: GET_DEVICES,
							payload: (response.data.divice.Data)? response.data.divice.Data.Devices: []
						})
						
					}
				);
	}
}

export function getCompanies() {

	return dispatch => {

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/company/companylist")
			.then(
				response => {
					return dispatch({
						type: GET_COMPANIES,
						payload: (response.data)? response.data: []
					})

				}
			);
	}
}

export function getUserGroups() {

	return dispatch =>{

        dispatch(startLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/usermanagement/getallusergroups")
				.then(
					response => {
						return dispatch({
							type: GET_USER_GROUPS,
							payload: response.data
						})
						
					}
				);
	}
}

export function addUser(formData) {

	formData = {
		...formData,
		devices: formData.devices.map(device => device.Token)
	}

	return dispatch =>{

        dispatch(addUserProcessing());

		customAxios.post(process.env.REACT_APP_API_BASE_URL + "/usermanagement/usercreation", formData)
				.then(
					response => {
						return dispatch({
							type: ADD_USER,
							payload: response.data.status
						})
						
					}
				);
	}
}

export function editUser(formData) {

	return dispatch =>{

        dispatch(addUserProcessing());

		customAxios.post(process.env.REACT_APP_API_BASE_URL + "/usermanagement/updateuserdetails/other", formData)
				.then(
					response => {
						return dispatch({
							type: EDIT_USER,
							payload: response.data.status
						})
						
					}
				);
	}
}

export function startLoading(){
	return {
		type: START_LOADING
	}
}

export function stopLoading(){
	return {
		type: STOP_LOADING
	}
}
export function addUserProcessing(){
	return {
		type: ADD_USER_PROCESSING
	}
}
export function addUserFormResetToFalse(){
	return {
		type: ADD_USER_FORM_RESET_FALSE
	}
}
export function addUserErrorClear(){
	return {
		type: ADD_USER_ERROR_CLEAR
	}
}
export function clearGetUserError(){
	return {
		type: CLEAR_GET_USER_ERROR
	}
}
export function setEditingUserId(userId){
	return {
		type: SET_EDITING_USER_ID,
		payload: userId
	}
}
export function removeEditingUser(){
	return {
		type: REMOVE_EDITING_USER
	}
}

export function updateUserPassword(newPassword, userId) {

	const formData = {
		newPassword,
		userId
	}

	return dispatch =>{

        dispatch(addUserProcessing());

		customAxios.post(process.env.REACT_APP_API_BASE_URL + "/usermanagement/updatePassword", formData)
				.then(
					response => {
						
					}
				);
	}
}