import * as ACTIONS from '../actions';
const initialState = {
    users: [],
    userCount: 0,
    devices: [],
    userGroups: [],
    addUserProcessing: false,
    loadUserProcessing: false,
    addUserFormReset: false,
    addUserError: '',
    getUserError: '',
    editingUser: null,
    editingUserId: null,
    userDeletetionFlag: false,
    editUserProcessing: false,
    editUserFormReset: false,
    editUserError: '',
    companyList: []
};

const userReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_LOADING: {
            return {
                ...state,
                loadUserProcessing: true
            }
        }
        case ACTIONS.STOP_LOADING: {
            return {
                ...state,
                loadUserProcessing: false
            }
        }
        case ACTIONS.ADD_USER_PROCESSING: {
            return {
                ...state,
                addUserProcessing: true
            }
        }
        case ACTIONS.ADD_USER: {
            if(action.payload === 'success'){
                return {
                    ...state,
                    addUserProcessing: false,
                    addUserFormReset: true
                }
            }else{
                return {
                    ...state,
                    addUserProcessing: false,
                    addUserError: action.payload
                }
            }
        }
        case ACTIONS.GET_USER_GROUPS: {
            return {
                ...state,
                userGroups: action.payload
            }
        }
        case ACTIONS.GET_USERS: {
            return {
                ...state,
                users: action.payload.data,
                userCount: action.payload.dataCount
            }
        }
        case ACTIONS.ADD_USER_FORM_RESET_FALSE: {
            return {
                ...state,
                addUserFormReset: false
            }
        }
        case ACTIONS.ADD_USER_ERROR_CLEAR: {
            return {
                ...state,
                addUserError: ''
            }
        }
        case ACTIONS.GET_DEVICES: {
            return {
                ...state,
                devices: action.payload
            }
        }
        case ACTIONS.GET_USER_ERROR: {
            return {
                ...state,
                getUserError: 'Network error! Please check your internet connection'
            }
        }
        case ACTIONS.CLEAR_GET_USER_ERROR: {
            return {
                ...state,
                getUserError: ''
            }
        }
        case ACTIONS.SET_EDITING_USER_ID: {
            return {
                ...state,
                editingUserId: action.payload
            }
        }
        case ACTIONS.GET_EDITING_USER: {
            return {
                ...state,
                editingUser: {...action.payload}
            }
        }
        case ACTIONS.REMOVE_EDITING_USER: {
            return {
                ...state,
                editingUser: null,
                editingUserId: null
            }
        }
        case ACTIONS.DELETE_USER: {
            return {
                ...state,
                userDeletetionFlag: !state.userDeletetionFlag
            }
        }
        case ACTIONS.EDIT_USER: {
            return {
                ...state,
                addUserProcessing: false,
                addUserFormReset: true
            }
        }
        case ACTIONS.GET_COMPANIES: {
            return {
                ...state,
                companyList: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default userReducer;