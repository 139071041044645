import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Grid,
  makeStyles,
  CircularProgress,
  TextField,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Typography,
  FormHelperText,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import { updateDevice } from "../../../api/Device";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "20px",
  },
  formControl: {
    margin: "2em 0",
  },

  inputField: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputBoxSecond.color,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputBoxSecond.color,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.inputBoxSecond.color,
    },
  },

  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  Accordion: {
    backgroundColor: theme.palette.secondary.main,
  },

  input: {
    width: "90%",
    padding: 2,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.primary.main,
    margin: "20px 0",
  },
  notchedOutline: {
    borderWidth: "1px",
    // borderColor: theme.palette.input.borderColor
  },
  employeeCard: {
    border: "none",
  },
  heading: {
    // color: theme.palette.input.text,
  },
  cancelButton: {
    backgroundColor: "red",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const EditDeviceModel = ({
  deviceModelOpen,
  setDeviceModelOpen,
  singleDevice,
  setSingleDevice,
  setDeviceEditState,
  setSuccessMessage,
  setErrorMessage
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    Token: "",
    Name: "",
    Description: "",
  });
  console.log(singleDevice);

  useEffect(() => {
    if (singleDevice) {
      setValues({
        Token: singleDevice.Token,
        Name: singleDevice.Name,
        Description: singleDevice.Description,
      });
    }
  }, []);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const saveHandler = async () => {
    console.log("values", values);
    try {
      setLoading(true);
      await updateDevice(values);
      setLoading(false);
      setDeviceEditState(true)
      setSuccessMessage("Edit Device Successful")
      handleClose();
    } catch (e) {
        console.log("Edit Error",e)
        setErrorMessage("Something went Wrong")
    }
  };

  const handleClose = () => {
    setDeviceModelOpen(false);
    setSingleDevice(null);
  };
  //   console.log("values",values)

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={deviceModelOpen}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Edit Device - {singleDevice.Token || "-"}
      </DialogTitle>
      <DialogContent dividers>
        {error && (
          <Box mb={2}>
            <Alert severity="error">Please Select Devices</Alert>
          </Box>
        )}
        <Grid container>
          <Grid sm={12} md={5}>
            <div className={classes.formControl}>
              <TextField
                label="Device Name"
                variant="outlined"
                size="small"
                fullWidth
                name="Name"
                onChange={changeHandler}
                className={classes.inputField}
                value={values.Name}
                // helperText={formErrors.deviceName}
                // error={formErrors.deviceName.length > 0}
                disabled={dataLoading}
              />
            </div>
          </Grid>
          <Grid sm={12} md={1}></Grid>
          <Grid sm={12} md={5}>
            <div className={classes.formControl}>
              <TextField
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
                name="Description"
                onChange={changeHandler}
                className={classes.inputField}
                value={values.Description}
                disabled={dataLoading}
              />
            </div>
          </Grid>
          <Grid
            xm={12}
            md={12}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div style={{ textAlign: "right" }}>
              <Button
                className={classes.cancelButton}
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.addButton}
                variant="contained"
                onClick={saveHandler}
                disabled={dataLoading}
              >
                {loading ? <CircularProgress size={20} /> : "Save"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditDeviceModel;
