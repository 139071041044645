import customAxios from "../Services/customAxios/CustomAxios";
import moment from "moment";



// for Table Data
export const getDevicesList = async (params) => {
    return customAxios.get(
      `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/device/list?tenant=ESSP`
    );
};  
export const getCompanyList = async (params) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_BASE_URL}/company/companylist`
  );
}; 

// for Table Data if user role not admin
export const groupDetail = async (companyID) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/group/detail?tenant=ESSP&groupToken=ESSPERTH${companyID}`
  );
}; 


// for Delete
export const memberAdd = async (deviceToken) => {
  // console.log("memberAdd deviceToken",deviceToken)
  return customAxios.post(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/reg/group/member/add?tenant=ESSP`,{Token: 'ESSPERTH', Devices: deviceToken}
  );
}; 

export const memberRemove = async (groupToken, deviceToken) => {
  // console.log("memberRemove deviceToken",deviceToken)
  return customAxios.post(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/reg/group/member/remove?tenant=ESSP`,{Token: groupToken, Devices: deviceToken}
  );
}; 

export const infoDevice = async (deviceToken) => {
  // console.log("infoDevice deviceToken",deviceToken)
  return customAxios.post(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/put/info/device?tenant=ESSP`,{DeviceToken: deviceToken,Time: moment().unix(), Info: {group: 'ESSPERTH'}}
  );
}; 

// for Edit
export const updateDevice = async (body) => {
  return customAxios.post(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/reg/device/update?tenant=ESSP`,body
  );
}; 

