import React, { createRef, useEffect, useState } from "react"
import {
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    LinearProgress,
    Card,
    Grid,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";
import { getDevices, getReport } from "./store/actions";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        padding: "20px",
        // backgroundColor: theme.palette.paper.main,
    },
    textField: {
        width: '100%'
    },

    tableContainer: {
        // padding: theme.spacing(3),
        overflowX: "auto",
    },
    table: {},
    tableCell: {
        fontSize: theme.typography.body1.fontSize,
        textAlign: "center",
        // color: theme.palette.input.text,
    },
    tableHeadCell: {
        fontSize: theme.typography.body1.fontSize,
        textAlign: "center",
        fontWeight: "bold",
        // color: theme.palette.input.text,
    },
    tableRow: {
        transitionDuration: 0.2,
        "&:hover": {
            // backgroundColor: theme.palette.table.hover,
            transitionDuration: 0.2,
        },
    },
    searchCard: {
        margin: '10px',
        padding: '30px'
    },
    forminput: {
        // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
        borderWidth: "1px",
        // borderColor: theme.palette.input.borderColor
    },
    heading: {
        // color: theme.palette.input.text,
    },
    buttonSuccess: theme.typography.buttonSuccess,
    buttonWarning: theme.typography.buttonWarning,
    buttonDanger: theme.typography.buttonDanger,
}))

function DeviceAnalysisComponent(props) {
    const classes = useStyles()

    const devices = useSelector(({ deviceAnalysisReport }) => deviceAnalysisReport.devices)
    const datas = useSelector(({ deviceAnalysisReport }) => deviceAnalysisReport.reportData)
    const reportLoading = useSelector(({ deviceAnalysisReport }) => deviceAnalysisReport.reportLoading)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDevices());
    }, [dispatch])

    const [searchTableFound, setSearchTableFound] = useState(false)




    const today = moment().format('YYYY-MM-DD');
    const beforeDate = moment().subtract(3, 'months').format('YYYY-MM-DD');

    const [minDate, setMinDate] = useState(beforeDate)
    const [startDate, setStartDate] = useState(beforeDate)
    const [endDate, setEndDate] = useState(today);
    const [deviceToken, setDeviceToken] = useState('');
    const [excelUrl, setExcelUrl] = useState('');

    // const onchangeEndDate = (value) => {
    //     setEndDate(value);
    //     let date = moment(value).subtract(3, 'months').format('YYYY-MM-DD');
    //     setMaxDate(date);
    //     setStartDate(date);
    // }

    const [confirmAlert, setConfirmAlert] = useState(false);

    const excelLinkRef = createRef();
    const searchCriteria = () => {
        if ((startDate && endDate && deviceToken) && startDate < endDate) {
            setSearchTableFound(true)
            dispatch(getReport(deviceToken, moment(startDate, "YYYY-MM-DD").unix(), moment(endDate, "YYYY-MM-DD").unix()))
            setExcelUrl(process.env.REACT_APP_API_BASE_URL + `/divicemanagement/getDeviceAnalysisReport/${moment(startDate, "YYYY-MM-DD").unix()}/${moment(endDate, "YYYY-MM-DD").unix()}/${deviceToken}`);
        } else {
            setConfirmAlert(true)
        }

    }
    const cancelDelete = () => {

        setConfirmAlert(false)
    }


    const handleExcelExport = () => {
        excelLinkRef.current.click()
    }
    return (
        <Paper className={classes.container}>
            <Typography variant="h5" style={{margin: '6px 8px'}} className={classes.heading}>Device Analysis Report</Typography>
            <a href={excelUrl} style={{ display: 'none' }} ref={excelLinkRef}> <span> </span> </a>
            <TableContainer className={classes.tableContainer}>
                <Grid container spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center" >
                    <Grid md={8} >
                        <Card className={classes.searchCard}>
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12} sm={12}>

                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={devices}
                                            size="small"
                                            InputProps={{
                                                className: classes.forminput,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                            onChange={(event, newValue) => {
                                                setDeviceToken((newValue) ? newValue.Token : '')
                                            }}
                                            getOptionLabel={(option) => option.Token}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Device Token" variant="standard" />}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={12}>

                                        <TextField
                                            id="start_date"
                                            label="Start Date"
                                            type="Date"
                                            inputProps={{ min: minDate, max: today }}
                                            defaultValue={startDate}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={startDate}
                                            onChange={event => { setStartDate(event.target.value) }}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={12}>
                                        <TextField
                                            id="end_date"
                                            label="End Date"
                                            type="Date"
                                            inputProps={{ min: minDate, max: today }}
                                            defaultValue={endDate}
                                            className={classes.textField}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={endDate}
                                            onChange={event => setEndDate(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={12}>
                                        <Button
                                            onClick={searchCriteria}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>

                                </Grid>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
                {searchTableFound ? (
                    <>
                        <Grid container direction="row"
                            justify="flex-end"
                            alignItems="baseline">
                            <Button onClick={handleExcelExport} variant="contained"
                                color="primary" className={classes.submit}>
                                <CloudDownloadIcon style={{ marginRight: '5px' }} />  Export
                            </Button>
                        </Grid>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadCell}>
                                        Time
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        Target Weight
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        Cumulative Weight
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        Last Bucket Weight
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        Bucket Count
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {(reportLoading) ? ('') : (
                                <TableBody>
                                    {datas.map((data) => (
                                        <TableRow className={classes.tableRow}>
                                            <TableCell className={classes.tableCell}>
                                                {new Date(data.Time * 1000).toLocaleString(
                                                    "en-US"
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {data.X0}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {data.X1}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {data.X3}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {data.X5}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table></>) : ''}

                {(reportLoading) ? (<LinearProgress />) : ''}

            </TableContainer>
            <div>

                <Dialog
                    open={confirmAlert}
                    onClose={cancelDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >

                    <DialogContent className={classes.alertBox}>
                        <DialogContentText id="alert-dialog-description" className={classes.alertBox} style={{ fontSize: '15px' }}>
                            All fields are required and Start date <strong> must </strong> be <strong> before </strong> End date
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.alertBox}>

                        <Button className={classes.buttonDanger} autoFocus onClick={cancelDelete}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Paper>
    )
}

export default DeviceAnalysisComponent
