import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevices,
  resetAlarms,
  startLoading,
  updateAlarm,
} from "./store/actions";
import { DeleteForever, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import { getCompanies } from "../DeviceComponent/store/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "20px",
    // backgroundColor: theme.palette.paper.main
  },
  alertBox: {
    // background: theme.palette.paper.main,
    // // color: theme.palette.input.label,
  },
  tableContainer: {
    // padding: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    // color: theme.palette.input.text,
  },
  tableHeadCell: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    fontWeight: "bold",
    // color: theme.palette.input.text,
  },
  tableRow: {
    transitionDuration: 0.2,
    "&:hover": {
      // backgroundColor: theme.palette.table.hover,
      transitionDuration: 0.2,
    },
  },
  tableRowForDevice: {
    // backgroundColor: theme.palette.info.light,
    // fontWeight: 'bold'
  },
  tableCellForDevice: {
    // fontWeight: 'bold',
    // textAlign: 'center',
    padding: theme.spacing(0),
    borderBottom: "none",
  },
  tableButton: {
    borderColor: theme.typography.button.borderColor,
    color: theme.typography.button.color,
  },
  buttonSuccess: theme.typography.buttonSuccess,
  buttonWarning: theme.typography.buttonWarning,
  buttonDanger: theme.typography.buttonDanger,

  searchRoot: {
    padding: "0px 4px",
    marginTop: "8px",
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: 400,
    marginLeft: "auto",
    backgroundColor: "#f2f2f2",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px",
  },
  TablePagination: {
    // color: theme.palette.input.text
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: "100%",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  AccordionHeading: {
    backgroundColor: theme.palette.action.selected,
  },
  heading: {
    // color: theme.palette.input.text,
  },
  buttonSuccess: theme.typography.buttonSuccess,
  buttonWarning: theme.typography.buttonWarning,
  buttonDanger: theme.typography.buttonDanger,
  buttonWarning: theme.typography.buttonWarning,
  alertBox: {
    // background: theme.palette.paper.main,
    // // color: theme.palette.input.label,
  },
  Accordion: {
    // backgroundColor: theme.palette.input.background
  },
  formControl: {
    width: "400px",
  },
  forminput: {
    // // background// color: theme.palette.input.background,
  },
  notchedOutline: {
    borderWidth: "1px",
    // borderColor: theme.palette.input.borderColor
  },
}));

function AlarmViewComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const devices = useSelector(({ alarm }) => alarm.devices);
  const loading = useSelector(({ alarm }) => alarm.loading);

  const rowsPerPageOptions = [5, 10, 15];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );

  const filterData = () => {
    let deviceList = [];
    devices.forEach((device) => {
      let isValid = false;
      Object.keys(device.Info)
        .filter((k) => k.startsWith("AL_") && k.endsWith("_value"))
        .forEach((key) => {
          if (
            device.Info[key.substring(0, key.length - 5) + "enable"] ===
              undefined ||
            device.Info[key.substring(0, key.length - 5) + "enable"] === "true"
          ) {
            isValid = true;
          }
        });
      if (isValid) {
        deviceList = [...deviceList, device];
      }
    });
    return deviceList.filter(
      (device) => company === 0 || device.Info.group === `ESSPERTH${company}`
    );
  };

  const getPlaginatedData = () => {
    return (
      loggedUserRole.toLowerCase() === "admin"
        ? filterData()
        : filterData().filter(
            (d) => d?.Info?.group?.substring(8) == loggedUserCompanyId
          )
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  const getCount = () => {
    return (
      loggedUserRole.toLowerCase() === "admin"
        ? filterData()
        : filterData().filter(
            (d) => d?.Info?.group?.substring(8) == loggedUserCompanyId
          )
    ).length;
  };

  const [company, setCompany] = useState(0);
  const companyList = useSelector(({ device }) => device.companyList);

  useEffect(() => {
    dispatch(resetAlarms());
    dispatch(startLoading());
    dispatch(getDevices());
    dispatch(getCompanies());
  }, [dispatch]);

  const [deletingDeviceName, setDeletingDeviceName] = useState("");
  const [deletingDeviceParameter, setDeletingDeviceParameter] = useState("");
  const [deletingRequestObject, setDeletingRequestObject] = useState(null);

  const deleteAlarm = (deviceToken, parameter, deviceName, paramater) => {
    let requestObject = {
      DeviceToken: deviceToken,
      Time: moment().unix(),
      Info: {
        [parameter]: "false",
      },
    };
    setDeletingDeviceName(deviceName);
    setDeletingDeviceParameter(paramater);
    setDeletingRequestObject(requestObject);
  };

  const handleDelete = () => {
    dispatch(startLoading());
    dispatch(updateAlarm(deletingRequestObject));
    deleteConfirmationClose();
  };

  const deleteConfirmationClose = () => {
    setDeletingRequestObject(null);
    setDeletingDeviceName("");
    setDeletingDeviceParameter("");
  };

  console.log(filterData());
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          color: "#939799",
          marginBottom: "1rem",
        }}
      >
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          {/* <InputLabel id="demo-simple-select-outlined-label">Time</InputLabel> */}
          {loggedUserRole.toLowerCase() !== "admin" ? (
            ""
          ) : (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // label="Time"
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              className={classes.forminput}
              InputProps={{
                className: classes.forminput,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            >
              <MenuItem value={0}>Select Organization</MenuItem>
              {companyList.map((company) => (
                <MenuItem key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </div>
      <Paper className={classes.container}>
        <TableContainer className={classes.tableContainer}>
          <Typography
            variant="h5"
            style={{ margin: "6px 0" }}
            className={classes.heading}
          >
            Alarm List
          </Typography>
          <Table className={classes.table}>
            {!loading ? (
              <TableBody>
                {getPlaginatedData().map((device) => {
                  return (
                    <Accordion key={device.Token} className={classes.Accordion}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.AccordionHeading}
                      >
                        <Typography className={classes.heading}>
                          {device.Name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer className={classes.tableContainer}>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                  Name
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                  Parameter
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                  Condition
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                  Value
                                </TableCell>
                                <TableCell
                                  className={classes.tableHeadCell}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            {Object.keys(device.Info)
                              .filter(
                                (k) =>
                                  k.startsWith("AL_") && k.endsWith("_value")
                              )
                              .map((key) => {
                                if (
                                  device.Info[
                                    key.substring(0, key.length - 5) + "enable"
                                  ] === undefined ||
                                  device.Info[
                                    key.substring(0, key.length - 5) + "enable"
                                  ] === "false"
                                ) {
                                  return "";
                                }
                                return (
                                  <TableRow
                                    key={key}
                                    className={classes.tableRow}
                                  >
                                    <TableCell className={classes.tableCell}>
                                      {device.Info[
                                        key.substring(0, key.length - 6) +
                                          "_name"
                                      ]
                                        ? device.Info[
                                            key.substring(0, key.length - 6) +
                                              "_name"
                                          ]
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {key
                                        .substring(3, key.length - 6)
                                        .toLocaleUpperCase()}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {device.Info[
                                        key.substring(0, key.length - 5) +
                                          "condition"
                                      ] === ">>"
                                        ? "Greater Than"
                                        : device.Info[
                                            key.substring(0, key.length - 5) +
                                              "condition"
                                          ] === "<<"
                                        ? "Less Than"
                                        : device.Info[
                                            key.substring(0, key.length - 5) +
                                              "condition"
                                          ] === "=="
                                        ? "Equals"
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {device.Info[key]}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <Button
                                        className={classes.buttonDanger}
                                        onClick={() =>
                                          deleteAlarm(
                                            device.Token,
                                            key.substring(0, key.length - 5) +
                                              "enable",
                                            device.Name,
                                            key
                                              .substring(3, key.length - 6)
                                              .toLocaleUpperCase()
                                          )
                                        }
                                      >
                                        <DeleteForever /> Delete
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                    //     </TableCell>
                    // </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
          {loading ? <LinearProgress /> : ""}
          <TablePagination
            className={classes.TablePagination}
            component="div"
            count={getCount()}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(parseInt(event.target.value));
              setPage(0);
            }}
          />
        </TableContainer>

        <div>
          <Dialog
            open={deletingRequestObject !== null}
            onClose={deleteConfirmationClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className={classes.alertBox}>
              <strong>Are You Sure ? </strong>
            </DialogTitle>
            <DialogContent className={classes.alertBox}>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.alertBox}
                style={{ fontSize: "15px" }}
              >
                You are trying to Delete a parameter {deletingDeviceParameter}{" "}
                from {deletingDeviceName}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.alertBox}>
              <Button
                onClick={deleteConfirmationClose}
                className={classes.buttonWarning}
              >
                Cancel
              </Button>
              <Button
                className={classes.buttonSuccess}
                autoFocus
                onClick={handleDelete}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </>
  );
}

export default AlarmViewComponent;
