import {
  makeStyles,
  Paper,
  LinearProgress,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Map, Overlay } from "pigeon-maps";
import { getDevices, resetMaps } from "./store/actions";
import MuiAlert from "@material-ui/lab/Alert";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import tt from "@tomtom-international/web-sdk-maps";
import {
  getDevicesList,
  getGPSDevicesData,
  getGPSDevicesState,
} from "../../api/Map";

function MapComponent() {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      // padding: theme.spacing(5),
      // minHeight: '80vh',
      // backgroundColor: theme.palette.paper.main
    },
    dotGreen: {
      backgroundColor: "#08FF08",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      border: "2px solid #154734",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      // color: theme.palette.input.text,
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const devices = useSelector(({ map }) => map.devices);
  const states = useSelector(({ map }) => map.states);
  // const loading = useSelector(({ map }) => map.loading);
  const [allDevicesApiData, setAllDevicesApiData] = useState([]);
  const [GPSDevicesApiData, setGPSDevicesApiData] = useState([]);
  const [GPSDevicesApiState, setGPSDevicesApiState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [combineApiData, setCombineApiData] = useState([]);
  const mapElement = useRef();
  const [mapLongitude, setMapLongitude] = useState(118.42);
  const [mapLatitude, setMapLatitude] = useState(-34.09);
  const [mapZoom, setMapZoom] = useState(6);
  const [map, setMap] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      if (loggedUserRole.toLowerCase() === "admin") {
        const {
          data: { Data: gpsDeviceData },
        } = await getGPSDevicesData();

        setGPSDevicesApiData(gpsDeviceData);

        const {
          data: { Data: gpsDeviceState },
        } = await getGPSDevicesState();

        setGPSDevicesApiState(gpsDeviceState);

        const combineData = gpsDeviceData?.Devices?.map((gpsData) => {
          const {
            StateMachines,
            LocalCounters,
            DeviceCounters,
            ["Last Seen"]: LastSeen,
          } = gpsDeviceState?.Devices?.find(
            (gpsState) => gpsState.Token === gpsData.Token
          );

          return {
            Token: gpsData.Token,
            Name: gpsData.Name,
            "Last Seen": LastSeen,
            Data: gpsData.Data,
            StateMachines: StateMachines,
            LocalCounters: LocalCounters,
            DeviceCounters: DeviceCounters,
          };
        });

        setCombineApiData(combineData);

        // console.log("combineData", combineData);
      } else if (loggedUserCompanyId) {
        const {
          data: { Data: allDeviceList },
        } = await getDevicesList();

        setAllDevicesApiData(allDeviceList);

        const {
          data: { Data: gpsDeviceData },
        } = await getGPSDevicesData();

        setGPSDevicesApiData(gpsDeviceData);

        const {
          data: { Data: gpsDeviceState },
        } = await getGPSDevicesState();

        setGPSDevicesApiState(gpsDeviceState);

        const combineData = gpsDeviceData?.Devices?.filter((gpsFindData) =>
          allDeviceList
            .filter(
              (filterData) =>
                filterData.Info.group == "ESSPERTH" + loggedUserCompanyId
            )
            .find((d) => d.Token === gpsFindData.Token)
        ).map((gpsData) => {
          const {
            StateMachines,
            LocalCounters,
            DeviceCounters,
            ["Last Seen"]: LastSeen,
          } = gpsDeviceState?.Devices?.find(
            (gpsState) => gpsState.Token === gpsData.Token
          );

          return {
            Token: gpsData.Token,
            Name: gpsData.Name,
            "Last Seen": LastSeen,
            Data: gpsData.Data,
            StateMachines: StateMachines,
            LocalCounters: LocalCounters,
            DeviceCounters: DeviceCounters,
          };
        });

        setCombineApiData(combineData);
      } else {
        setLoading(true);
      }
      setLoading(false);
    } catch (e) {
      console.log("Error", e);
      setLoading(true);
      setErrorMessage("Something Went Wrong");
    }
  };

  useEffect(() => {
    // dispatch(resetMaps());
    // dispatch(getDevices());
    fetchData();
  }, []);

  const increaseZoom = () => {
    if (mapZoom < 10) {
      setMapZoom(mapZoom + 1);
    }
  };
  const decreaseZoom = () => {
    if (mapZoom > 1) {
      setMapZoom(mapZoom - 1);
    }
  };
  const updateMap = () => {
    map.setCenter([parseFloat(mapLongitude), parseFloat(mapLatitude)]);
    map.setZoom(mapZoom);
  };

  let devicesWithLocations = [];

  useEffect(() => {
    // const mapData = () => {
    // if (devices && states && states.length > 0 && devices.length > 0) {
    //   let map = tt.map({
    //     key: "NdfCp7GrAH1VwqIQ70GBhQWt8kHRR50s",
    //     container: mapElement.current,
    //     center: [mapLongitude, mapLatitude],
    //     zoom: mapZoom,
    //   });
    //   devices.forEach((device) => {
    //     let color = "gray";
    //     if (
    //       states
    //         .find((state) => state.Token === device.Token)
    //         .StateMachines[0].Level.toLowerCase() === "informational"
    //     ) {
    //       color = "#0F0";
    //     } else if (
    //       states
    //         .find((state) => state.Token === device.Token)
    //         .StateMachines[0].Level.toLowerCase() === "error"
    //     ) {
    //       color = "#F00";
    //     }
    //     const popupOffsets = {
    //       top: [0, 0],
    //       bottom: [0, -40],
    //       "bottom-right": [0, -70],
    //       "bottom-left": [0, -70],
    //       left: [25, -35],
    //       right: [-25, -35],
    //     };
    //     let popup = new tt.Popup({ offset: popupOffsets }).setHTML(
    //       `<p style="color: black;"><b>${device.Name}</b></p>`
    //     );
    //     new tt.Marker({ color: color })
    //       .setLngLat([
    //         device.Data.find((data) => data.Parameter === "Latitude").Value !==
    //         device.Data.find((data) => data.Parameter === "Longitude").Value
    //           ? device.Data.find((data) => data.Parameter === "Longitude").Value
    //           : device.Data.find((data) => data.Parameter === "rxLongitude")
    //               .Value,
    //         device.Data.find((data) => data.Parameter === "Latitude").Value !==
    //         device.Data.find((data) => data.Parameter === "Longitude").Value
    //           ? device.Data.find((data) => data.Parameter === "Latitude").Value
    //           : device.Data.find((data) => data.Parameter === "rxLatitude")
    //               .Value,
    //       ])
    //       .setPopup(popup)
    //       .addTo(map);
    //     devicesWithLocations = [
    //       ...devicesWithLocations,
    //       {
    //         deviceToken: device.Token,
    //         location: [
    //           device.Data.find((data) => data.Parameter === "Latitude")
    //             .Value !==
    //           device.Data.find((data) => data.Parameter === "Longitude").Value
    //             ? device.Data.find((data) => data.Parameter === "Longitude")
    //                 .Value
    //             : device.Data.find((data) => data.Parameter === "rxLongitude")
    //                 .Value,
    //           device.Data.find((data) => data.Parameter === "Latitude")
    //             .Value !==
    //           device.Data.find((data) => data.Parameter === "Longitude").Value
    //             ? device.Data.find((data) => data.Parameter === "Latitude")
    //                 .Value
    //             : device.Data.find((data) => data.Parameter === "rxLatitude")
    //                 .Value,
    //         ],
    //       },
    //     ];
    //   });
    //   map.on("click", (e) => {
    //     console.log(e);
    //   });
    //   setMap(map);
    //   console.log(devicesWithLocations);
    //   return () => map.remove();
    // }
    // };

    if (combineApiData?.length > 0) {
      let map = tt.map({
        key: "NdfCp7GrAH1VwqIQ70GBhQWt8kHRR50s",
        container: mapElement.current,
        center: [mapLongitude, mapLatitude],
        zoom: mapZoom,
      });

      combineApiData?.map((data) => {
        let color = "gray";
        if (data?.StateMachines[0]?.Level?.toLowerCase() === "informational") {
          color = "#0F0";
        } else if (data?.StateMachines[0]?.Level?.toLowerCase() === "error") {
          color = "#F00";
        }
        const popupOffsets = {
          top: [0, 0],
          bottom: [0, -40],
          "bottom-right": [0, -70],
          "bottom-left": [0, -70],
          left: [25, -35],
          right: [-25, -35],
        };
        let popup = new tt.Popup({ offset: popupOffsets }).setHTML(
          `<p style="color: black;"><b>${data.Name}</b></p>`
        );
        new tt.Marker({ color: color })
          .setLngLat([
            data?.Data.find((dataValue) => dataValue.Parameter === "Latitude")
              .Value !==
            data?.Data.find((dataValue) => dataValue.Parameter === "Longitude")
              .Value
              ? data?.Data.find(
                  (dataValue) => dataValue.Parameter === "Longitude"
                ).Value
              : data?.Data.find(
                  (dataValue) => dataValue.Parameter === "rxLongitude"
                ).Value,
            data?.Data.find((dataValue) => dataValue.Parameter === "Latitude")
              .Value !==
            data?.Data.find((dataValue) => dataValue.Parameter === "Longitude")
              .Value
              ? data?.Data.find(
                  (dataValue) => dataValue.Parameter === "Latitude"
                ).Value
              : data?.Data.find(
                  (dataValue) => dataValue.Parameter === "rxLatitude"
                ).Value,
          ])
          .setPopup(popup)
          .addTo(map);
        devicesWithLocations = [
          ...devicesWithLocations,
          {
            deviceToken: data.Token,
            location: [
              data.Data.find((dataValue) => dataValue.Parameter === "Latitude")
                .Value !==
              data.Data.find((dataValue) => dataValue.Parameter === "Longitude")
                .Value
                ? data.Data.find(
                    (dataValue) => dataValue.Parameter === "Longitude"
                  ).Value
                : data.Data.find(
                    (dataValue) => dataValue.Parameter === "rxLongitude"
                  ).Value,
              data.Data.find((dataValue) => dataValue.Parameter === "Latitude")
                .Value !==
              data.Data.find((dataValue) => dataValue.Parameter === "Longitude")
                .Value
                ? data.Data.find(
                    (dataValue) => dataValue.Parameter === "Latitude"
                  ).Value
                : data.Data.find(
                    (dataValue) => dataValue.Parameter === "rxLatitude"
                  ).Value,
            ],
          },
        ];
      });

      map.on("click", (e) => {
        console.log(e);
      });

      setMap(map);
      return () => map.remove();
    }
  }, [combineApiData]);

  return (
    <>
      <Paper className={classes.container}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={errorMessage !== ""}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setErrorMessage("")}
            severity="error"
          >
            {errorMessage}
          </MuiAlert>
        </Snackbar>
        <Typography
          variant="h5"
          style={{ margin: "6px 8px" }}
          className={classes.heading}
        >
          Device Map
        </Typography>
        {loading && <LinearProgress style={{ backgroundColor: "#fff" }} />}
        <div style={{ ...(loading ? { visibility: "hidden" } : {}) }}>
          <div
            ref={mapElement}
            className="mapDiv"
            style={{ width: "100%", height: "75vh" }}
          ></div>
        </div>
      </Paper>
    </>
  );
}

export default MapComponent;
