import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Paper,
  CircularProgress,
  Box,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import MachineCard from "./components/MachineCard";
import {
  getGroupList,
  getStatesGroup,
  getGroupDetail,
  getLatestGroup,
} from "../../api/Dashboard";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";

// ........................Old Code Import..................................

// import { useHistory } from "react-router";
// import { getGroups, getFarmDetails, resetDashboard } from "./store/actions";
// import moment from "moment";
// import { Alert } from "@material-ui/lab";
// import {
//   DeviceTileComponent,
//   DeviceTileComponentDetails,
// } from "../UIComponents/DeviceTileComponent/DeviceTileComponent";
// import { useDispatch, useSelector } from "react-redux";

// ........................Old Code Import..................................

function DashboardComponent() {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      padding: "20px",
      minHeight: "80vh",
      backgroundColor: theme.palette.paper.color,
    },
    cardContainer: {
      padding: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    oneCard: {
      width: "20px",
      justifyContent: "center",
      display: "flex",
    },
  }));

  const classes = useStyles();
  const [organizationData, setOrganizationData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [workingDevice, setWorkingDevice] = useState(0);
  const [loggedUserCompanyIdState,setLoggedUserCompanyIdState] = useState(false)

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );
  // console.log("ESSPERTH" + loggedUserCompanyId)

  // ........................Old Code..................................

  // const [deviceDetailsCard, setDeviceCardDetails] = useState(null);
  // const [componentDetailsBox, setComponentDetailsBox] = useState(false);
  // const openCardDetails = (device) => {
  //   setDeviceCardDetails(device);
  //   setComponentDetailsBox(true);
  //   // console.log(device);
  // };
  // const closeCardDetails = (device) => {
  //   setComponentDetailsBox(false);
  // };

  // const dispatch = useDispatch();
  // const history = useHistory();

  // const userId = useSelector(({ main }) => main.userPermissions.User.userid);
  // const lastUpdatedTime = useSelector(({ dashboard }) => dashboard.lastUpdatedTime
  // );
  // const [lastUpdatedTimeRefreshFlag, setLastUpdatedTimeRefreshFlag] = useState(false);
  // const [getDeviceRefreshFlag, setSetDeviceRefreshFlag] = useState(false);

  // const farm1 = useSelector(({ dashboard }) => dashboard.farm1);
  // const farm2 = useSelector(({ dashboard }) => dashboard.farm2);

  // ........................Old Code..................................

  useEffect(() => {
    const fetchData = async () => {
      try {

        if(loggedUserRole.toLowerCase() === "admin"){ 

          const {
            data: { Data: groupList },
          } = await getGroupList();

          console.log(groupList)
  
          const getStatesGroupApi = groupList.filter((d) => d.Token !== "ESSPERTH" && d.Token !== "ESSPLOCATION").map((row) => getStatesGroup(row.Token));
          const statesGroupDataStructure = await axios.all(getStatesGroupApi);
          const finalStateGroup = statesGroupDataStructure.map((t) => t?.data?.Data);
          // console.log(finalStateGroup);
  
          const getGroupDetailApi = groupList.filter((d) => d.Token !== "ESSPERTH" && d.Token !== "ESSPLOCATION").map((row) => getGroupDetail(row.Token));
          const GroupDetailDataStructure = await axios.all(getGroupDetailApi);
          const finalGroupDetail = GroupDetailDataStructure.map((t) => t?.data?.Data);
          // console.log(finalGroupDetail);
  
          const getLatestGroupApi = groupList.filter((d) => d.Token !== "ESSPERTH" && d.Token !== "ESSPLOCATION").map((row) => getLatestGroup(row.Token));
          const LatestGroupDataStructure = await axios.all(getLatestGroupApi);
          const finalLatestGroup = LatestGroupDataStructure.map((t) => t?.data?.Data);
          // console.log(finalLatestGroup);
  
          const combineArray = finalStateGroup.map((stateData) => {
            const {Token,Name,Description,Devices,Groups,Info,Root,Type,} = finalGroupDetail.find(
              (groupData) => groupData.Token === stateData.Token
            );
            const {["Token"]: lToken,["Name"]: lName,["Devices"]: lDevices,["Data Type"]: dataType,} = finalLatestGroup.find(
              (latestData) => latestData.Token === stateData.Token
            );
            return { stateData: stateData, groupData: {Token,Name,Description,Info,Root,Type,Devices,Groups}, latestData: {Token: lToken,Name: lName,Devices: lDevices,dataType}};
          });
          setLoading(true);
          setOrganizationData(combineArray);

        }else{

          const {
            data: { Data: groupList },
          } = await getGroupList();
  
          const getStatesGroupApi = groupList.filter((d) => d.Token == "ESSPERTH" + loggedUserCompanyId).map((row) => getStatesGroup(row.Token));
          const statesGroupDataStructure = await axios.all(getStatesGroupApi);
          const finalStateGroup = statesGroupDataStructure.map((t) => t?.data?.Data);
          // console.log(finalStateGroup);
  
          const getGroupDetailApi = groupList.filter((d) => d.Token == "ESSPERTH" + loggedUserCompanyId).map((row) => getGroupDetail(row.Token));
          const GroupDetailDataStructure = await axios.all(getGroupDetailApi);
          const finalGroupDetail = GroupDetailDataStructure.map((t) => t?.data?.Data);
          // console.log(finalGroupDetail);
  
          const getLatestGroupApi = groupList.filter((d) => d.Token == "ESSPERTH" + loggedUserCompanyId).map((row) => getLatestGroup(row.Token));
          const LatestGroupDataStructure = await axios.all(getLatestGroupApi);
          const finalLatestGroup = LatestGroupDataStructure.map((t) => t?.data?.Data);
          // console.log(finalLatestGroup);
  
          const combineArray = finalStateGroup.map((stateData) => {
            const {Token,Name,Description,Devices,Groups,Info,Root,Type,} = finalGroupDetail.find(
              (groupData) => groupData.Token === stateData.Token
            );
            const {["Token"]: lToken,["Name"]: lName,["Devices"]: lDevices,["Data Type"]: dataType,} = finalLatestGroup.find(
              (latestData) => latestData.Token === stateData.Token
            );
            return { stateData: stateData, groupData: {Token,Name,Description,Info,Root,Type,Devices,Groups}, latestData: {Token: lToken,Name: lName,Devices: lDevices,dataType}};
          });
          setLoading(true);
          setOrganizationData(combineArray);

        }
        
        
      } catch (error) {
        setErrorMessage("Something went wrong");
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData()
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const machineView = (groupToken, user, password) => {
    let url = `/dashboards/${groupToken}`;
    return url;
  };

  const batteryState = (data) => {
   const stateValue = data.Devices?.find(device => (device?.Data?.find(device => (device?.Parameter === 'Bat' || device?.Parameter === 'BatV'))))?.Data?.find(device => (device?.Parameter === 'Bat' || device?.Parameter === 'BatV'))?.Value || -1
    if(stateValue === -1){
      return 'Unknown'
    }else if(stateValue > 2.9){
      return 'Good'
    }else{
      return 'Bad'
    }
  }

  const calcWorkingDevice = (data) =>{
   let count = 0
    data.Devices.map(d=>d?.["Last Seen"]).forEach(element => {
      // console.log(element)
      if((element) > (element+10)){
        // console.log("treu")
        ++count
        return count
        // setWorkingDevice(workingDevice++)
      }else{
        // console.log("fasle")
        return count
        // setWorkingDevice(workingDevice)
      }    
        })

    
  }

  const checkingStringValue = (value) =>{
    if(typeof value === 'string'){
      return "Info"
    }else{
      return value
    }

  }

  const bottomBoxData = (data,type) => {

    
    if(type === "first"){
      const boxData = {name:data?.Devices[0]?.Data[0]?.Parameter,value:checkingStringValue(data?.Devices[0]?.Data[0]?.Value) || 0}
      // console.log(boxData)
      return boxData
    }else if (type === "second" ){
      const boxData = {name:data?.Devices[0]?.Data[1]?.Parameter,value:checkingStringValue(data?.Devices[0]?.Data[1]?.Value) || 0}
      // console.log(boxData)
      return boxData
    }

  }

  // .......................................................Old Code..........................................................

  // useEffect(() => {
  //   dispatch(getGroups());
  //   // dispatch(getFarmDetails())
  //   setTimeout(() => {
  //     setSetDeviceRefreshFlag((prev) => !prev);
  //   }, 300000);
  // }, [getDeviceRefreshFlag, dispatch]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLastUpdatedTimeRefreshFlag((prev) => !prev);
  //   }, 60000);
  // }, [lastUpdatedTimeRefreshFlag]);

  // const states = useSelector(({ dashboard }) => dashboard.states);
  // const details = useSelector(({ dashboard }) => dashboard.details);
  // const data = useSelector(({ dashboard }) => dashboard.data);
  // const errorMessage = useSelector(({ dashboard }) => dashboard.errorMessage);

  // bars in the cards
  // const [barData, setBarData] = useState([]);

  // useEffect(() => {
  //   const tempData = [];
  //   if (data && data.length > 0) {
  //     for (let i = 0; i < data.length; i++) {}
  //   }
  // }, [data]);

  // .......................................................Old Code..........................................................

  //  console.log(data)
    console.log(organizationData);
  //  console.log(organizationData[0]?.latestData.Devices?.find(device => (device?.Data?.find(device => (device?.Parameter === 'Bat' || device?.Parameter === 'BatV')))).Data?.find(device => (device?.Parameter === 'Bat' || device?.Parameter === 'BatV'))?.Value);
// organizationData[0]?.latestData?.Devices.map(d=>d?.["Last Seen"]).forEach(element => {
//     console.log(element)
//   }); 
  
  

  return (
    <>
      <Paper className={classes.container}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={errorMessage !== ""}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setErrorMessage("")}
            severity="error"
          >
            {errorMessage}
          </MuiAlert>
        </Snackbar>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "#939799",
          }}
        >
          {/* <Typography variant="subtitle2">
            Last Update: {moment().to(lastUpdatedTime)}
          </Typography> */}
        </div>

        <Box className={classes.cardContainer}>
          <Grid container spacing={3} alignItems="flex-start">
            {loading ? (
              <>
                {organizationData.map((data) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className={classes.oneCard}
                      key={data.stateData.Token}
                    >
                      <MachineCard
                        name={data.stateData.Name}
                        state={data.stateData.Counters.Alert}
                        noDevice={data.groupData.Devices.length}
                        batteryLevel={batteryState(data.latestData)}
                        workingDevice={calcWorkingDevice(data.latestData)}
                        firstBottomBox={bottomBoxData(data.latestData,"first")}
                        secondBottomBox={bottomBoxData(data.latestData,"second")}
                        url={machineView(data.stateData.Token)}
                      />
                    </Grid>
                  );
                })}
              </>
            ) : (
              <CircularProgress style={{ margin: "200px auto" }} />
            )}
          </Grid>
        </Box>
      </Paper>

      {/* ........................................................................Old Code................................................................................. */}

      {/* {(errorMessage !== '') ? (<Alert severity="error">{errorMessage}</Alert>) : ''}
                <Grid container xs={12} style={{ marginLeft: "30px", marginTop: "10px", marginBottom: "10px", }}>
                    <Grid item xs={12} sm={3} md={2} lg={2} justify="left" style={{ display: "inline-flex" }} >
                        <Chip label="Working" style={{ backgroundColor: '#009A17', width: '90%', color: 'white' }} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={2} justify="left" style={{ display: "inline-flex" }} >
                        <Chip label="Alarmed" style={{ backgroundColor: '#D41920', width: '90%', color: 'white' }} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={2} justify="left" style={{ display: "flex" }}>
                        <Chip label="Unknown" style={{ backgroundColor: '#939799', width: '90%', color: 'white' }} />
                    </Grid>
                </Grid>
                <Grid container spacing={"1"} style={{ width: '100%' }} wrap={'wrap'} >
                        {(loading) ? (<CircularProgress style={{ margin: '200px auto' }} />) : ''}
                        {(states && details && data && details.length > 0 && data.length > 0) && states.map(group => (
                            <>
                                <Grid item xs={12} sm={6} md={4} lg={3} justify="left" key={group.Token}>
                                    <div onClick={() => redirectToGrafana(group.Token, process.env.REACT_APP_API_GRAFANA_USERNAME, process.env.REACT_APP_API_GRAFANA_PASSWORD)}>
                                        <DeviceTileComponent 
                                            device={group.Name} 
                                            data={data.find(d => d.Token === group.Token)}
                                            groupInfo={(details.find(g => g.Token === group.Token) && details.find(g => g.Token === group.Token).Info)? details.find(g => g.Token === group.Token).Info: null}
                                            errorStates={(group.Counters && group.Counters.Alert)? group.Counters.Alert: 0} 
                                            batteryLevel={
                                                (data.find(d => d.Token === group.Token) && data.find(d => d.Token === group.Token).Devices && data.find(d => d.Token === group.Token).Devices.length > 0)? (data.find(d => d.Token === group.Token).Devices.find(device => (device.Data && device.Data.find(param => (param.Parameter === 'Bat' || param.Parameter === 'BatV')))))? data.find(d => d.Token === group.Token).Devices.find(device => (device.Data && device.Data.find(param => (param.Parameter === 'Bat' || param.Parameter === 'BatV')))).Data.find(param => (param => (param.Parameter === 'Bat' || param.Parameter === 'BatV'))).Value: -1} 
                                        />
                                    </div>
                                </Grid>
                            </>
                        ))}
                </Grid> */}

      {/* {(deviceDetailsCard) ? (
                <DeviceTileComponentDetails close={closeCardDetails} open={componentDetailsBox} data={deviceDetailsCard} lastUpdatedTime={lastUpdatedTime} />
            ) : ''} */}

      {/* ........................................................................Old Code................................................................................. */}
    </>
    // (data.find(d => d.Token === group.Token) && data.find(d => d.Token === group.Token).Devices && data.find(d => d.Token === group.Token).Devices.length > 0)? (data.find(d => d.Token === group.Token).Devices.find(device => (device.Data && device.Data.find(param => (param.Parameter === 'Bat' || param.Parameter === 'BatV')))))? data.find(d => d.Token === group.Token).Devices.find(device => (device.Data && device.Data.find(param => (param.Parameter === 'Bat' || param.Parameter === 'BatV')))).Data.find(param => (param => (param.Parameter === 'Bat' || param.Parameter === 'BatV'))).Value: -1} 
  );
}

export default DashboardComponent;
