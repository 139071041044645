import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Paper,
  InputBase,
  Divider,
  IconButton,
  ButtonGroup,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableSortLabel,
  Typography,
  Snackbar,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MuiAlert from "@material-ui/lab/Alert";
import { Edit, DeleteForever } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteCompanyById,
  getCompanies,
  setEditingCompanyId,
} from "./store/actions";
import { getOrganizationList, deleteOrganization, deleteOrganizationGroupAurigaa } from "../../api/Organization";
import { getGroupDetail } from "../../api/Dashboard";
import { memberAdd, memberRemove, infoDevice } from "../../api/Device";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "20px",
    // backgroundColor: theme.palette.paper.main
  },
  alertBox: {
    // background: theme.palette.paper.main,
    // // color: theme.palette.input.label,
  },
  tableContainer: {
    // padding: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    // color: theme.palette.input.text,
  },
  tableHeadCell: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    fontWeight: "bold",
    // color: theme.palette.input.text,
  },
  tableRow: {
    transitionDuration: 0.2,
    "&:hover": {
      // backgroundColor: theme.palette.table.hover,
      transitionDuration: 0.2,
    },
  },
  tableButton: {
    borderColor: theme.typography.button.borderColor,
    color: theme.typography.button.color,
  },
  buttonSuccess: theme.typography.buttonSuccess,
  buttonWarning: theme.typography.buttonWarning,
  buttonDanger: theme.typography.buttonDanger,

  searchRoot: {
    padding: "0px 4px",
    marginTop: "8px",
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: 400,
    marginLeft: "auto",
    backgroundColor: "#f2f2f2",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px",
  },
  TablePagination: {
    // color: theme.palette.input.text
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: "100%",
    color: "#363434",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    marginTop: 8,
    margin: 4,
    backgroundColor: "#5c5c5c",
  },
  iconColor: {
    color: "#363434",
  },
  deleteBtn: {
    color: theme.palette.error.dark,
  },
  editBtn: {
    color: theme.palette.warning.dark,
  },
}));

function DeviceComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const rowsPerPageOptions = [5, 10, 15];

  const [organizationsData, setOrganizationsData] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [organizationDeleteState, setOrganizationDeleteState] = useState(false);
  const [deleteButtonState,setDeleteButtonState] = useState(false)
  const [sortParameter, setSortParameter] = useState("companyName");
  const [sortType, setSortType] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  

  const companies = useSelector(({ company }) => company.companies);
  console.log("Company", companies);
  const companyCount = useSelector(({ company }) => company.companyCount);

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );
  const loadCompanyProcessing = useSelector(
    ({ company }) => company.loadCompanyProcessing
  );

  

  const getPlaginatedData = () => {
    return filteredOrganizations?.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  

  const handleSorting = (parameter) => {
    const isAsc = sortParameter === parameter && sortType === "asc";
    setSortParameter(parameter);
    setSortType(isAsc ? "desc" : "asc");
  };

  const fetchData = async () => {
    try {
      if (loggedUserRole.toLowerCase() === "admin") {
        setLoading(true);
        const {
          data: { data: organizationList, dataCount: organizationCount },
        } = await getOrganizationList();

        console.log("organizationList", organizationList);
        console.log("organizationCount", organizationCount);
        setOrganizationsData(organizationList);
        setLoading(false);
      } else {
        setLoading(true);

        const {
          data: { data: organizationList },
        } = await getOrganizationList();

        const filterData = organizationList.filter(
          (d) => d.companyId === loggedUserCompanyId
        );
        const organizationCount = filterData.length;

        console.log("organizationList", filterData);
        console.log("organizationCount", organizationCount);
        setOrganizationsData(filterData);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Something went wrong");
      setLoading(true);
    }
  };

  useEffect(() => {
    fetchData();

    setOrganizationDeleteState(false)

    // dispatch(
    //   getCompanies(page, rowsPerPage, searchText, sortParameter, sortType)
    // );
  }, [organizationDeleteState]);

  useEffect(() => {
    if (searchText === "") {
      setFilteredOrganizations(organizationsData);
    } else {
      setFilteredOrganizations(
        organizationsData.filter((d) =>
          d.companyName.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [organizationsData, searchText]);

  const editCompanyHandler = (id) => {
    dispatch(setEditingCompanyId(id));
    history.push("/company/edit");
  };

  const [deletingCompanyName, setDeletingCompanyName] = useState("");
  const [deletingCompanyId, setDeletingCompanyId] = useState(0);
  const [confirmAlert, setConfirmAlert] = useState(false);

  const deleteCompanyConfirmation = (companyName, companyId) => {
    setDeletingCompanyName(companyName);
    setDeletingCompanyId(companyId);
    setConfirmAlert(true);
  };

  const cancelDelete = () => {
    setDeletingCompanyName("");
    setDeletingCompanyId(0);
    setConfirmAlert(false);
  };

  const deleteCompany = async (id) => {
    console.log(id)
    const groupId = "ESSPERTH" + id
    try {
      
      const {
        data: { Data: groupDetail },
      } = await getGroupDetail(groupId);

      console.log(groupDetail)
      

      const deviceTokenList = groupDetail.Devices.map((d) => d.Token)

      if(deviceTokenList.length == 0){
        console.log("true")
        try {

          await deleteOrganization(id);
          await deleteOrganizationGroupAurigaa(groupId);
          setSuccessMessage("Remove Organization Successful");
          setOrganizationDeleteState(true)
          
        } catch (error) {
          setErrorMessage("Something Went Wrong")
          console.log({name:"Error",type:"length == 0 Api call",data:error})
        }
      }else{
        console.log("false")
        console.log(deviceTokenList)
        try {


          await deleteOrganization(id);
          await deleteOrganizationGroupAurigaa(groupId);
          await memberAdd(deviceTokenList);
          await memberRemove(groupId,deviceTokenList);
          

           const infoApi = deviceTokenList.map((d)=>  infoDevice(d))
           await Promise.all(infoApi)

          
          setSuccessMessage("Remove Organization Successful");
          setOrganizationDeleteState(true)
          
        } catch (error) {
          setErrorMessage("Something Went Wrong")
          console.log({name:"Error",type:"length != 0 Api call",data:error})
        }
      }

    } catch (error) {
      setErrorMessage("Something Went Wrong")
      console.log({name:"Error",type:"GroupDetail",data:error})
    }
    // dispatch(
    //   deleteCompanyById(
    //     id,
    //     page,
    //     rowsPerPage,
    //     searchText,
    //     sortParameter,
    //     sortType
    //   )
    // );
    cancelDelete();
  };

  return (
    <Paper className={classes.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={errorMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setErrorMessage("")}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={successMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccessMessage("")}
          severity="success"
        >
          {successMessage}
        </MuiAlert>
      </Snackbar>
      <Typography
        variant="h5"
        style={{ margin: "6px 8px" }}
        className={classes.heading}
      >
        Organization List
      </Typography>
      <div
        className={classes.searchRoot}
        style={{
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
        }}
      >
        <InputBase
          className={classes.input}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon color="inherit" className={classes.iconColor} />
        </IconButton>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                Organization Name
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Country</TableCell>
              <TableCell className={classes.tableHeadCell}>Email</TableCell>
              <TableCell className={classes.tableHeadCell}>Address</TableCell>
              <TableCell className={classes.tableHeadCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  No Records
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {getPlaginatedData()?.map((company) => (
                <TableRow key={company.companyId} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {company.companyName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {company.country}{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {company.companyEmail}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {company.addressLine1 +
                      ", " +
                      company.addressLine2 +
                      ", " +
                      (company.addressLine3
                        ? company.addressLine3 + ", "
                        : "") +
                      company.city}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <ButtonGroup variant="outlined" size="small">
                      <Button
                        className={classes.editBtn}
                        onClick={() => editCompanyHandler(company.companyId)}
                        disabled={
                          loggedUserRole.toLowerCase() === "admin" ||
                          loggedUserRole.toLowerCase() === "company admin"
                            ? false
                            : true
                        }
                      >
                        <Edit /> Edit
                      </Button>
                      <Button
                        className={classes.deleteBtn}
                        onClick={() =>
                          deleteCompanyConfirmation(
                            company.companyName,
                            company.companyId
                          )
                        }
                        disabled={
                          loggedUserRole.toLowerCase() === "admin" ||
                          loggedUserRole.toLowerCase() === "company admin"
                            ? false
                            : true
                        }
                      >
                        <DeleteForever /> Delete
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {loadCompanyProcessing ? <LinearProgress /> : ""}
        <TablePagination
          component="div"
          count={organizationsData?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          className={classes.TablePagination}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={(event, newPage) => setPage(newPage)}
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
        />
      </TableContainer>

      <div>
        <Dialog
          open={confirmAlert}
          onClose={cancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.alertBox}>
            <strong>Are You Sure ? </strong>
          </DialogTitle>
          <DialogContent className={classes.alertBox}>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.alertBox}
              style={{ fontSize: "15px" }}
            >
              You are trying to Delete a company - {deletingCompanyName}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.alertBox}>
            <Button onClick={cancelDelete} className={classes.buttonWarning}>
              Cancel
            </Button>
            <Button
              className={classes.buttonSuccess}
              autoFocus
              onClick={() => deleteCompany(deletingCompanyId)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Paper>
  );
}

export default DeviceComponent;
