import React from 'react'
import {  Route, Redirect } from "react-router-dom"
import AuthService from '../Services/AuthService/AuthService'

export default function ProtectedRoute(props) {

    
    return (
        <>
            {
                (AuthService.isAuthenticated() && props.isAuthorized) ? (<Route exact path={props.path} component={props.component} />)
                : (AuthService.isAuthenticated() &&  !props.isAuthorized)? (<Redirect to="/" />)
                : <Redirect to="/login" />
            }
        </>
    )
}
