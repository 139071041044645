// import axios from "axios";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_PROFILE_DETAILS = 'MAIN_COMPONENT - GET_PROFILE_DETAILS';
export const GET_USER_PERMISSIONS = 'MAIN_COMPONENT - GET_USER_PERMISSIONS';

export const START_APP_LOADING = 'MAIN_COMPONENT - START_APP_LOADING';
export const STOP_APP_LOADING = 'MAIN_COMPONENT - STOP_APP_LOADING';


export function getProfileDetails() {

	return dispatch => {

		dispatch(startAppLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/usermanagement/profiledetails")
			.then(
				response => {
					return dispatch({
						type: GET_PROFILE_DETAILS,
						payload: response.data
					})

				}
			);
	}
}

export function getUserPermissions() {

	return dispatch => {

		dispatch(startAppLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/auth/userpermission")
			.then(
				response => {
					dispatch(stopAppLoading());
					return dispatch({
						type: GET_USER_PERMISSIONS,
						payload: response.data
					})

				}
			);
	}
}

export function startAppLoading() {
	return {
		type: START_APP_LOADING
	}
}

export function stopAppLoading() {
	return {
		type: STOP_APP_LOADING
	}
}