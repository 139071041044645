// import axios from "axios";
import axios from "axios";
import moment from "moment";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_DEVICES = 'DEVICE_COMPONENT - GET_DEVICES';
export const ADD_DEVICE = 'DEVICE_COMPONENT - ADD_DEVICE';
export const ADD_DEVICE_ERROR = 'DEVICE_COMPONENT - ADD_DEVICE_ERROR';
export const DELETE_DEVICE = 'DEVICE_COMPONENT - DELETE_DEVICE';

export const START_ADD_DEVICE_COMPONENT_LOADING = 'DEVICE_COMPONENT - START_ADD_DEVICE_COMPONENT_LOADING';
export const STOP_ADD_DEVICE_COMPONENT_LOADING = 'DEVICE_COMPONENT - STOP_ADD_DEVICE_COMPONENT_LOADING';

export const ADD_DEVICE_FORM_RESET_FALSE = 'DEVICE_COMPONENT - ADD_DEVICE_FORM_RESET_FALSE';


export const START_ADD_DEVCIE_LOADING = 'DEVICE_COMPONENT - START_ADD_DEVCIE_LOADING';
export const STOP_ADD_DEVCIE_LOADING = 'DEVICE_COMPONENT - STOP_ADD_DEVCIE_LOADING';

export const GET_ALL_DEVICES = 'DEVICE_COMPONENT - GET_ALL_DEVICES';
export const START_LOAD_DEVICE_PROCESSING = 'DEVICE_COMPONENT - START_LOAD_DEVICE_PROCESSING';
export const STOP_LOAD_DEVICE_PROCESSING = 'DEVICE_COMPONENT - STOP_LOAD_DEVICE_PROCESSING';

export const GET_USERS = 'DEVICE_COMPONENT - GET_USERS';

export const GET_COMPANIES = 'DEVICE_COMPONENT - GET_COMPANIES';
export const GET_DEVICE_BY_TOKEN = 'DEVICE_COMPONENT - GET_DEVICE_BY_TOKEN';
export const RESET_DEVICES = 'DEVICE_COMPONENT - RESET_DEVICES';


export function getDevices() {

	return dispatch => {

		dispatch(startAddDeviceComponentLoading());

		axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/group/detail?tenant=ESSP&groupToken=ESSPERTH")
			.then(
				response => {
					dispatch(stopAddDeviceComponentLoading());
					return dispatch({
						type: GET_DEVICES,
						payload: (response.data.Data && response.data.Data.Devices)? response.data.Data.Devices: []
					})

				}
			);
	}
}

export function getDeviceByToken(deviceToken) {

	return dispatch => {

		axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/device/detail?tenant=ESSP&deviceToken=" + deviceToken)
			.then(
				response => {
					dispatch(stopLoadDeviceProcessing());
					return dispatch({
						type: GET_DEVICE_BY_TOKEN,
						payload: (response.data.Data)? response.data.Data: null
					})

				}
			);
	}
}

export function updateDevice(requestObject) {

	return dispatch => {

		axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/device/update?tenant=ESSP", requestObject)
			.then(
				response => {
					dispatch(getDeviceByToken(requestObject.Token));
				}
			);
	}
}

export function getCompanies() {

	return dispatch => {

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/company/companylist")
			.then(
				response => {
					dispatch(stopAddDeviceComponentLoading());
					return dispatch({
						type: GET_COMPANIES,
						payload: (response.data)? response.data: []
					})

				}
			);
	}
}
export function getUsers() {

	return dispatch => {


		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/usermanagement/userlist")
			.then(
				response => {
					return dispatch({
						type: GET_USERS,
						payload: response.data
					})

				}
			);
	}
}
export function addDevice(deviceToken, Token, deviceName) {

	return dispatch => {

		dispatch(startAddDeviceLoading());

		axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/group/member/add?tenant=ESSP", {Token, Devices: [deviceToken]})
			.then(
				response => {
					if(response.data && response.data.Status && response.data.Status.toLowerCase() === "ok"){
						axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/group/member/remove?tenant=ESSP", {Token: 'ESSPERTH', Devices: [deviceToken]})
						.then(
							response => {
								axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/device/update?tenant=ESSP", {Token: deviceToken, Name: deviceName, Info: {group: Token}})
								.then(response => {
									dispatch(stopAddDeviceLoading());
									return dispatch({
										type: ADD_DEVICE,
									})
								}).catch(error => {
									dispatch(stopAddDeviceLoading());
								})
							}
						).catch(error => {
							dispatch(stopAddDeviceLoading());
						});
					}

				}
			).catch(error => {
				dispatch(stopAddDeviceLoading());
			});
	}
}
export function deleteDevices(deviceToken, groupToken, loggedUserRole, loggedUserCompanyId) {

	return dispatch =>{
		dispatch(startLoadDeviceProcessing());
		axios.all([
			axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/group/member/add?tenant=ESSP", {Token: 'ESSPERTH', Devices: [deviceToken]}),
			axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/group/member/remove?tenant=ESSP", {Token: groupToken, Devices: [deviceToken]}),
			axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/put/info/device?tenant=ESSP", {DeviceToken: deviceToken,Time: moment().unix(), Info: {group: 'ESSPERTH'}})
		]).then(axios.spread((...responses) => {
			 dispatch(getAllDevices(loggedUserRole, loggedUserCompanyId));
		})).catch(errors => {
			dispatch(stopLoadDeviceProcessing());
		})
	}
}
export function getAllDevices(loggedUserRole, loggedUserCompanyId) {

	return dispatch => {

		dispatch(startLoadDeviceProcessing());
		if(loggedUserRole.toLowerCase() === 'admin'){
			customAxios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/device/list?tenant=ESSP")
				.then(
					response => {
						customAxios.get(process.env.REACT_APP_API_BASE_URL + "/company/companylist")
						.then(
							secondResponse => {
								response.data.Data.forEach(device => {
									if(device.Info && device.Info.group){
										if(secondResponse.data.find(company => company.companyId == device.Info.group.substring(8)) !== undefined){
											console.log(device.Info.group.substring(8))
											device.Info = {
												...device.Info,
												company: device.Info.company = secondResponse.data.find(company => company.companyId == device.Info.group.substring(8)).companyName
											}
										}
									}
								})
								return dispatch({
									type: GET_ALL_DEVICES,
									payload: (response.data.Data)? response.data.Data: []
								})
			
							}
						);
	
					}
				);
		}else{
			customAxios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/group/detail?tenant=ESSP&groupToken=ESSPERTH" + loggedUserCompanyId)
				.then(
					response => {
						return dispatch({
							type: GET_ALL_DEVICES,
							payload: (response.data.Data)? response.data.Data.Devices: []
						})
	
					}
				);
		}
	}
}

export function startLoadDeviceProcessing() {
	return {
		type: START_LOAD_DEVICE_PROCESSING
	}
}
export function stopLoadDeviceProcessing() {
	return {
		type: STOP_LOAD_DEVICE_PROCESSING
	}
}
export function startAddDeviceLoading() {
	return {
		type: START_ADD_DEVCIE_LOADING
	}
}
export function stopAddDeviceLoading() {
	return {
		type: STOP_ADD_DEVCIE_LOADING
	}
}
export function startAddDeviceComponentLoading() {
	return {
		type: START_ADD_DEVICE_COMPONENT_LOADING
	}
}

export function stopAddDeviceComponentLoading() {
	return {
		type: STOP_ADD_DEVICE_COMPONENT_LOADING
	}
}
export function addDeviceFormResetToFalse() {
	return {
		type: ADD_DEVICE_FORM_RESET_FALSE
	}
}
export function resetDevices() {
	return {
		type: RESET_DEVICES
	}
}