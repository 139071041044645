import React from "react";
import ReactEcharts from "echarts-for-react";

const Gauge = (props) => {
  const {name, value ,paramIcon, decimalCount, max, min ,colorConfig} = props
  const type = 1
  return (
    <div style={{ width: "100%" }}>
      <ReactEcharts
        
        option={{
          series: [
            {
              name: (name || ""),
              type: "gauge",
              min: (min || 0),
              max: (max || 100),
              radius: "100%",
              progress: {
                show: true,
                width: 12,
                itemStyle:{
                  color:"auto"
                 }
              },
              axisLine: {
                show:false,
                lineStyle: {
                  width: 10,
                  color:colorConfig
                  // color: type == 1 ? (
                  //   [
                  //     [0.25, '#4DFF00'],
                  //     [0.75, '#F5772A'],
                  //     [1, '#FF0000']
                  //   ]
                  // ):(
                  //   [
                  //     [0.25, '#FF0000'],
                  //     [0.75, '#F5772A'],
                  //     [1, '#4DFF00']
                  //   ]
                  // )
                  
                },
              },
              itemStyle: {
                color: "#C4162A",
              },
              axisLabel: {
                    show: true,
                    color: '#969696',
                    fontSize: 10
                  },
                  pointer: {
                    itemStyle: {
                      color: 'auto'
                    }
                  },
                  emphasis: {
                    itemStyle: {
                      color: 'auto'
                    },
                  },

              detail: {
                valueAnimation: true,
                color: "#969696",
                offsetCenter: [0, "50%"],
                fontSize: 30,
                fontWeight: "bolder",
                formatter: '{value}' + (paramIcon || ""),
              },
              data: [
                {
                  value: (value?value?.toFixed(2):value == 0 ? 0:"Na"),
                  name: (name || ""),
                  title: {
                    offsetCenter: ["0%", "90%"],
                    fontSize: 22,
                    color: "#838F9C",
                  },
                },
              ],
            },
            // {
            //   name: 'Pressure',
            //   type: 'gauge',
            //   min: 0,
            //   max: 100,
            //   radius: '100%',
            //   progress: {
            //     show: true,
            //     width: 8
            //   },
            //   axisLine: {
            //     lineStyle: {
            //       width: 8
            //     }
            //   },

            //   itemStyle: {
            //     color:  '#C4162A'
            //   },
            //   splitLine: {
            //     show: true,
            //
            //   },
            //   axisLabel: {
            //     show: true,
            //     color: '#969696',
            //     fontSize: 2
            //   },
            //   pointer: {
            //     show: false
            //   },
            //   detail: {
            //     valueAnimation: true,
            //     formatter: '{value}',

            //     color: '#969696',
            //     offsetCenter: [0, '5%'],
            //     fontSize: 30,
            //     fontWeight: 'bolder'
            //   },
            //   data: [
            //     {
            //       value: 50,
            //       name: 'Battery',
            //       title: {
            //         offsetCenter: ['0%', '90%'],
            //         fontSize: 24,
            //         color: '#838F9C'
            //       }
            //     },
            //     {
            //       value: 50,
            //       name: 'V',
            //       title: {
            //           fontSize: 20,
            //           color: '#969696',
            //           fontWeight: 'bolder',
            //           offsetCenter: ['0%', '30%']
            //       }
            //      }
            //   ]
            // }
          ],
        }}
      />
    </div>
  );
};
export default Gauge;
