import customAxios from "../Services/customAxios/CustomAxios";

export const getGroupList = async (params) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/group/list?tenant=ESSP`
  );
};

export const getStatesGroup = async (Token) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/states/group?groupToken=${Token}&tenant=ESSP`
  );
};

export const getGroupDetail = async (Token) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/group/detail?groupToken=${Token}&tenant=ESSP`
  );
};

export const getLatestGroup = async (Token) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/data/latest/group?groupToken=${Token}&tenant=ESSP`
  );
};

export const getRowData = async (Token,startTime,endTime) => {
  // console.log(Token,startTime,endTime)
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/data/raw?deviceToken=${Token}&time=range&startTime=${startTime}&endTime=${endTime}&tenant=ESSP`
  );
};
