
import {
    Typography,
    Button,
    makeStyles,
    Grid,
    TextField,
    Paper,
    InputBase,
    InputLabel,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import UserFormConfirmationComponent from "../UserFormConfirmationComponent/UserFormConfirmationComponent";
import { DeviceTileComponent } from '../../UIComponents/DeviceTileComponent/DeviceTileComponent'
import { useDispatch, useSelector } from "react-redux";
import { getDevices } from "../../DashboardComponent/store/actions";

function MyProfileComponent(props) {
    const useStyles = makeStyles((theme) => ({
        container: {
            width: "100%",
            padding: "20px",
            // backgroundColor: theme.palette.dashboard.main
        },
        paper: {
            // backgroundColor: theme.palette.paper.main
        },
        text: {
            // color: theme.palette.Breadcrumb.text,
            textAlign: 'center',
            marginTop: '20px'
        },
        searchRoot: {
            padding: "4px 4px",
            marginTop: "8px",
            marginBottom: "12px",
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: 400,
            marginLeft: 'auto',
            backgroundColor: '#f2f2f2',
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: "1px",
        },
        formControl: {
            margin: '2em 0',
            padding: '0 1em',
            // // background// color: theme.palette.input.background,

            '& input': {
                // color: theme.palette.input.text,

            },
            '& label': {
                // // color: theme.palette.input.label,

            },
            '& InputLabelProps': {
                // root: theme.palette.input.label,
                // focused: theme.palette.input.label,
            },
            '& InputProps': {
                // root: theme.palette.input.label,
                // focused: theme.palette.input.label,
            }


        },
        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        input: {
            width: "90%",
            padding: 6,
            fontSize: "1em",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            margin: "20px 0"
        },
    }))

    const classes = useStyles()
    const dispatch = useDispatch();

    const defaultFormData = {
        firstName: '',
        lastName: '',
        email: '',
        contactNo: '',
        username: '',
        password: '',
        userGroup: ''
    };

    const [formData, setFormData] = useState(defaultFormData);
    const devices = useSelector(({ dashboard }) => dashboard.devices);
    const errorMessage = useSelector(({ dashboard }) => dashboard.errorMessage);

    useEffect(() => {
        // dispatch(getDevices())
    }, [dispatch])

    const handleForm = (event) => {
        setFormData(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        });
    }

    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

    const handleConfirmModalOpen = () => {
        setConfirmModalOpen(true);
    };

    const handleConfirmModalClose = () => {
        setConfirmModalOpen(false);
    };

    // My profile details
    const userDetails = useSelector(({ main }) => main.userPermissions.User);


    const [myDevices, setMyDevices] = useState([]);
    const [searchDeviceText, setSearchDeviceText] = useState('');

    useEffect(() => {
        if (searchDeviceText === '') {
            setMyDevices(devices)
        } else {
            setMyDevices(devices.filter(device => (device.Token.includes(searchDeviceText))))
        }
    }, [searchDeviceText, devices]);


    return (
        <>
            <Paper className={classes.container}>
                <form>
                    <Grid container md={12}>
                        <Grid md={6} style={{ margin: 'auto' }}>
                            <Paper className={classes.paper} style={{ width: '100%', padding: '5%' }} elevation={13} >
                                <Typography variant="h4" component="h4" gutterBottom align="center" className={classes.text} >
                                    My Account
                                </Typography>
                                <Grid item sm={12} md={12}>
                                    <div className={classes.formControl}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" >
                                            <Grid item md={3}>
                                                <InputLabel>First Name</InputLabel>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TextField
                                                    variant="outlined" size="small" fullWidth name="firstName"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleForm}
                                                    disabled
                                                    value={userDetails.firstName}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.formControl}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" >
                                            <Grid item md={3}>
                                                <InputLabel>Last Name</InputLabel>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TextField
                                                    variant="outlined" size="small" fullWidth name="lastName"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleForm}
                                                    disabled
                                                    value={userDetails.lastName}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.formControl}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" >
                                            <Grid item md={3}>
                                                <InputLabel>Email &nbsp; &nbsp; &nbsp; &nbsp;</InputLabel>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TextField
                                                    variant="outlined" size="small" fullWidth name="email"
                                                    style={{ width: '100%' }}
                                                    type="email"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleForm}
                                                    disabled
                                                    value={userDetails.userEmail}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.formControl}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" >
                                            <Grid item md={3}>
                                                <InputLabel>Contact No</InputLabel>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TextField
                                                    variant="outlined" size="small" fullWidth name="contactNo"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleForm}
                                                    disabled
                                                    value={userDetails.contactNo}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className={classes.formControl}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" >
                                            <Grid item md={3}>
                                                <InputLabel>Username</InputLabel>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TextField
                                                    variant="outlined" size="small" fullWidth name="username"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleForm}
                                                    disabled
                                                    value={userDetails.username}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>

                                    {/* <div className={classes.formControl}>
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center" >
                                            <Grid item md={3}>
                                                <InputLabel>Password</InputLabel>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TextField
                                                    variant="outlined" size="small" fullWidth name="password"
                                                    type="password"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleForm}
                                                    value={formData.password}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div> */}


                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid md={8} style={{ paddingTop: '30px', width: "100%", margin: 'auto' }}  >
                            <Paper elevation={13} className={classes.paper}>
                                <Grid container direction="column"
                                    justify="center"
                                    alignItems="stretch">

                                    <Grid item md={12} xs={12}>
                                        <Typography variant="h5" component="h5" gutterBottom style={{ marginLeft: "10px" }} className={classes.text}>
                                            My Devices
                                        </Typography>

                                    </Grid>
                                    <Grid item md={12} xs={12}>

                                        <InputBase
                                            variant="outlined" style={{ margin: "auto" }}
                                            className={classes.searchRoot}

                                            placeholder="Search"
                                            onChange={event =>
                                                setSearchDeviceText(event.target.value)
                                            }
                                            value={searchDeviceText}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />

                                    </Grid>
                                    <Grid container spacing={4} style={{ width: "100%", paddingTop: '20px', paddingBottom: '20px' }}>

                                        {myDevices.map(device => <Grid item xs={12} sm={6} md={6} justify="left" spacing={"1"} > <DeviceTileComponent device={device.Token} emp={device.Name} style={{ display: 'flex', }} /> </Grid>)}

                                    </Grid>
                                </Grid>
                                {/* <Grid md={12} style={{ marginTop: '20px', paddingBottom: '20px' }}>
                                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                        <Button color="inherit" variant="contained" style={{ marginRight: '10px' }}>Cancel</Button>
                                        <Button className={classes.addButton} variant="contained" onClick={handleConfirmModalOpen}>Edit USER</Button>
                                    </div>
                                </Grid> */}
                            </Paper>


                        </Grid>



                    </Grid>
                </form>


            </Paper>
            <UserFormConfirmationComponent handleClose={handleConfirmModalClose} open={confirmModalOpen} formData={formData} />
        </>
    )
}

export default MyProfileComponent
