import axios from "axios";
import customAxios from "../../../../../Services/customAxios/CustomAxios";

export const GET_REPORT = 'DEVICE_ANALYSIS_REPORT - GET_REPORT';
export const GET_DEVICES = 'DEVICE_ANALYSIS_REPORT - GET_DEVICES';

export const START_REPORT_LOADING = 'DEVICE_ANALYSIS_REPORT - START_REPORT_LOADING';
export const STOP_REPORT_LOADING = 'DEVICE_ANALYSIS_REPORT - STOP_REPORT_LOADING';


export function getReport(device, start, end) {

	return dispatch =>{

        dispatch(startReportLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + `/divicemanagement/getDevicedatarow/${start}/${end}/${device}`)
				.then(
					response => {
						let result = [];
						for(let i = response.data.divice.Data.Data.length - 1; i >=0; i--){
							result.push(response.data.divice.Data.Data[i]);
						}
						dispatch(stopReportLoading());
						return dispatch({
							type: GET_REPORT,
							payload: result
						})
						
					}
				);
	}
}

export function getDevices() {

	return dispatch =>{

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/divicemanagement/groupdetailsLlist/")
				.then(
					response => {
						return dispatch({
							type: GET_DEVICES,
							payload: response.data.divice.Data.Devices
						})
						
					}
				);
	}
}

export function startReportLoading(){
	return {
		type: START_REPORT_LOADING
	}
}

export function stopReportLoading(){
	return {
		type: STOP_REPORT_LOADING
	}
}