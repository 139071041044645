import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeviceFormConfirmationComponent from "../DeviceFormConfirmationComponent/DeviceFormConfirmationComponent";
import AddUserToDevice from "../AddUserToDevice/AddUserToDevice";
import { useDispatch, useSelector } from "react-redux";
import {
  addDeviceFormResetToFalse,
  getCompanies,
  getDevices,
  getUsers,
} from "../store/actions";

function AddDeviceComponent(props) {
  const dispatch = useDispatch();

  const devices = useSelector(({ device }) => device.devices);
//   console.log(devices);
  const companyList = useSelector(({ device }) => device.companyList);
  

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );
//   console.log(loggedUserRole)
//   console.log(companyList.find((d)=>d.companyId == loggedUserCompanyId))

  useEffect(() => {
    dispatch(getDevices());
    // dispatch(getUsers());
    dispatch(getCompanies());
  }, [dispatch]);

  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      padding: "20px",
      // backgroundColor: theme.palette.paper.main
    },
    formControl: {
      margin: "2em 0",
      // // background// color: theme.palette.input.background,
      "& input": {
        // color: theme.palette.input.text,
      },
      "& label": {
        // // color: theme.palette.input.label,
      },
      "& InputLabelProps": {
        // root: theme.palette.input.label,
        // focused: theme.palette.input.label,
      },
      "& InputProps": {
        // root: theme.palette.input.label,
        // focused: theme.palette.input.label,
      },
    },
    cssLabel: {
      // // color: theme.palette.input.label,
      "&.Mui-focused": {
        // // color: theme.palette.input.labelClick,
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    Accordion: {
      backgroundColor: theme.palette.secondary.main,
    },

    input: {
      width: "90%",
      padding: 2,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.primary.main,
      margin: "20px 0",
    },
    forminput: {
      // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
      borderWidth: "1px",
      // borderColor: theme.palette.input.borderColor
    },
    employeeCard: {
      border: "none",
    },
    inputField: {
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.palette.inputBoxSecond.color,
      },
    },
  }));

  const classes = useStyles();

  const defaultFormData = {
    // driverId: '',
    deviceToken: null,
    companyId: null,
    deviceName: "",
  };

  const defaultFormErrors = {
    // driverId: '',
    deviceToken: "",
    companyId: "",
    deviceName: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isLoading, setIsLoading] = useState(false);

  const handleForm = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validateFormData = () => {
    const errors = defaultFormErrors;
    let isHaveErrors = false;

    if (formData.deviceToken === null) {
      errors.deviceToken = "Select a Device Token";
      isHaveErrors = true;
    }
    if (formData.companyId === null) {
      errors.companyId = "Select an organization";
      isHaveErrors = true;
    }
    if (formData.deviceName === "") {
      errors.companyId = "Device Name cannot be empty!";
      isHaveErrors = true;
    }

    setFormErrors(errors);
    return isHaveErrors;
  };
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const handleConfirmModalOpen = () => {
    setFormData((prev) => {
      return {
        ...prev,
      };
    });

    if (!validateFormData()) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  //add Employee

  const [topEmployees, setTopEmployees] = useState([]);

  const employees = useSelector(({ device }) => device.users);

  const [bottomEmployees, setBottomEmployees] = useState([]);

  const [searchEmployeeText, setSearchEmployeeText] = useState("");

  useEffect(() => {
    if (searchEmployeeText === "") {
      setBottomEmployees(
        employees.filter(
          (employee) =>
            topEmployees.find(
              (topEmployees) => topEmployees.userid === employee.userid
            ) === undefined
        )
      );
    } else {
      setBottomEmployees(
        employees.filter(
          (employee) =>
            employee.firstName
              .toLowerCase()
              .includes(searchEmployeeText.toLowerCase()) &&
            topEmployees.find(
              (topEmployees) => topEmployees.userid === employee.userid
            ) === undefined
        )
      );
    }
  }, [searchEmployeeText, topEmployees, employees]);

  const removeEmployee = (employee) => {
    setTopEmployees((prev) =>
      prev.filter((item) => item.userid !== employee.userid)
    );
  };

  const addEmployee = (employee) => {
    setTopEmployees((prev) => [...prev, employee]);
  };
  const addDeviceFormReset = useSelector(
    ({ device }) => device.addDeviceFormReset
  );

  useEffect(() => {
    if (addDeviceFormReset) {
      resetForm();
      dispatch(addDeviceFormResetToFalse());
      handleConfirmModalClose();
    }
  }, [addDeviceFormReset, dispatch]);
  const resetForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultFormErrors);
    setTopEmployees([]);
    dispatch(getDevices());
    dispatch(getUsers());
  };

  console.log("formData", formData)
  return (
    <>
      <Paper className={classes.container}>
        <Typography
          variant="h5"
          style={{ margin: "6px 8px" }}
          className={classes.heading}
        >
          Add Device
        </Typography>
        <form>
          <Grid container>
            <Grid sm={12} md={5}>
              <FormGroup>
                <div className={classes.formControl}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={devices}
                    value={formData.deviceToken}
                    size="small"
                    className={classes.inputField}
                    onChange={(event, newValue) => {
                      setFormData((prev) => {
                        return {
                          ...formData,
                          deviceToken: newValue ? newValue : null,
                          deviceName: newValue
                            ? devices.find(
                                (dev) => dev.Token === newValue.Token
                              ).Name
                            : prev.deviceName,
                        };
                      });
                    }}
                    getOptionLabel={(option) => option.Token}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Device Token"
                        variant="outlined"
                      />
                    )}
                    helperText={formErrors.deviceToken}
                    error={formErrors.deviceToken.length > 0}
                  />
                  {formErrors.deviceToken.length > 0 && (
                    <FormHelperText error>
                      {formErrors.deviceToken}
                    </FormHelperText>
                  )}
                </div>
              </FormGroup>
            </Grid>
            <Grid sm={12} md={1}></Grid>
            <Grid sm={12} md={5}>
              <FormGroup>
                <div className={classes.formControl}>
                    {loggedUserRole.toLowerCase() === "admin"? "":""}
                  <Autocomplete
                    id="combo-box-demo2"
                    options={loggedUserRole.toLowerCase() === "admin" ?companyList:companyList.filter((d)=>d.companyId == loggedUserCompanyId)}
                    value={formData.companyId}
                    size="small"
                    className={classes.inputField}
                    onChange={(event, newValue) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          companyId: newValue ? newValue : null,
                        };
                      });
                    }}
                    getOptionLabel={(option) => option.companyName}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Organization"
                        variant="outlined"
                      />
                    )}
                    helperText={formErrors.companyId}
                    error={formErrors.companyId.length > 0}
                  />
                  {formErrors.companyId.length > 0 && (
                    <FormHelperText error>
                      {formErrors.companyId}
                    </FormHelperText>
                  )}
                </div>
              </FormGroup>
            </Grid>
            <Grid sm={12} md={5}>
              <FormGroup>
                <div className={classes.formControl}>
                  <TextField
                    label="Device Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="deviceName"
                    onChange={handleForm}
                    className={classes.inputField}
                    value={formData.deviceName}
                    helperText={formErrors.deviceName}
                    error={formErrors.deviceName.length > 0}
                  />
                </div>
              </FormGroup>
            </Grid>
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              style={{ margin: "auto" }}
            >
              <Grid md={12}>
                {topEmployees.map((employee) => (
                  <Button
                    onClick={() => removeEmployee(employee)}
                    className={classes.employeeCard}
                  >
                    <AddUserToDevice
                      id={employee.userid}
                      name={employee.firstName + " " + employee.lastName}
                      role={employee.designation}
                    />
                  </Button>
                ))}
              </Grid>
            </Grid>
            <Grid
              xm={12}
              md={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "right" }}>
                <Button
                  className={classes.addButton}
                  variant="contained"
                  onClick={handleConfirmModalOpen}
                  disabled={loggedUserRole.toLowerCase() === "admin" || loggedUserRole.toLowerCase() === "company admin" ? false:true}
                >
                  ADD DEVICE
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <DeviceFormConfirmationComponent
        handleClose={handleConfirmModalClose}
        open={confirmModalOpen}
        devices={devices}
        companyList={companyList}
        formData={formData}
      />
    </>
  );
}

export default AddDeviceComponent;
