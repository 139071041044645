import * as ACTIONS from '../actions';
import moment from 'moment';
const initialState = {
    loading: true,
    devices: [],
    groups: [],
    profileData: null,
    lastUpdatedTime: moment(),
    errorMessage: '',
    farm1: null,
    farm2: null,
    states: [],
    data: [],
    details: [],
    groupDetailsAndData: [],
    groupInfo: null,
    configFlag: false
};

const userReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case ACTIONS.STOP_LOADING: {
            return {
                ...state,
                loading: false
            }
        }
        case ACTIONS.GET_GROUPS: {
            return {
                ...state,
                states: action.payload.states,
                details: action.payload.details,
                data: action.payload.data,
                lastUpdatedTime:moment()
            }
        }
        case ACTIONS.ERROR_MESSAGE: {
            return {
                ...state,
                devices: action.payload,
                errorMessage: 'Network error! Please check your internet connection',
                farm1: null,
                farm2: null
            }
        }
        case ACTIONS.GET_FARM_DETAILS: {
            return {
                ...state,
                errorMessage: '',
                ...action.payload,
                lastUpdatedTime:moment()
            }
        }
        case ACTIONS.GET_GROUP_DETAILS_AND_DATA: {
            return {
                ...state,
                groupDetailsAndData: action.payload.groupDetailsAndData,
                groupInfo: action.payload.groupInfo,
                lastUpdatedTime: moment()
            }
        }
        case ACTIONS.CONFIG_FLAG: {
            return {
                ...state,
                configFlag: !state.configFlag
            }
        }
        case ACTIONS.RESET_DASHBOARD: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default userReducer;