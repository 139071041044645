import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  Paper,
  InputBase,
  Divider,
  IconButton,
  ButtonGroup,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { DeleteForever, Edit } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import EditDeviceModel from "./EditDeviceComponent";
import {
  getDevicesList,
  getCompanyList,
  memberAdd,
  memberRemove,
  infoDevice,
  groupDetail,
} from "../../api/Device";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "20px",
    // backgroundColor: theme.palette.paper.main
  },
  alertBox: {
    // background: theme.palette.paper.main,
    // // color: theme.palette.input.label,
  },
  tableContainer: {
    // padding: theme.spacing(3),
    overflowX: "auto",
  },
  table: {},
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    // color: theme.palette.input.text,
  },
  tableHeadCell: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    fontWeight: "bold",
    // color: theme.palette.input.text,
  },
  tableRow: {
    transitionDuration: 0.2,
    "&:hover": {
      // backgroundColor: theme.palette.table.hover,
      transitionDuration: 0.2,
    },
  },
  tableButton: {
    borderColor: theme.typography.button.borderColor,
    color: theme.typography.button.color,
  },
  buttonSuccess: theme.typography.buttonSuccess,
  buttonWarning: theme.typography.buttonWarning,
  buttonDanger: theme.typography.buttonDanger,
  buttonGray: theme.typography.buttonGray,
  searchRoot: {
    padding: "0px 4px",
    display: "flex",
    justifyContent: "flex-start",
    width: "300x",
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px",
  },
  TablePagination: {
    // color: theme.palette.input.text
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: "100%",
  },
  iconButton: {
    padding: 10,
  },
  iconColor: {
    color: "#363434",
  },
  searchBoxText: {
    color: "#363434",
    marginLeft: 10,
  },
  divider: {
    height: 28,
    marginTop: 10,
    margin: 4,
    backgroundColor: "#5c5c5c",
  },
  heading: {
    // color: theme.palette.input.text,
  },
  formControl: {
    width: "350px",
  },
  forminput: {
    // // background// color: theme.palette.input.background,
  },
  notchedOutline: {
    borderWidth: "1px",
    // borderColor: theme.palette.input.borderColor
  },
  deleteBtn: {
    color: theme.palette.error.dark,
  },
  editBtn: {
    color: theme.palette.warning.dark,
  },
  inputField: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputBoxSecond.color,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputBoxSecond.color,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.inputBoxSecond.color,
    },
  },
}));

function DeviceComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [deviceModelOpen, setDeviceModelOpen] = useState(false);
  const [singleDevice, setSingleDevice] = useState(null);
  const [devicesListData, setDevicesListData] = useState([]);
  const [companyListData, setCompanyListData] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [deviceDeleteState, setDeviceDeleteState] = useState(false);
  const [deviceEditState, setDeviceEditState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const rowsPerPageOptions = [5, 10, 15];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);

  const getPlaginatedData = () => {
    return filteredDevices?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  const deviceDeletetionFlag = useSelector(
    ({ device }) => device.deviceDeletetionFlag
  );
  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loggedUserRole.toLowerCase() === "admin") {
          setLoading(true);
          const {
            data: { Data: deviceList },
          } = await getDevicesList();

          // console.log("deviceList",deviceList)

          const { data: companyList } = await getCompanyList();
          setCompanyListData(companyList);

          // console.log("deviceList", deviceList);
          // console.log("companyList", companyList);

          const tableData = deviceList.map((d) => {
            const company =
              companyList?.find(
                (c) => c?.companyId == d?.Info?.group?.substring(8)
              ) || null;

            return {
              Token: d.Token,
              Name: d.Name,
              Profile: d.Profile,
              Description: d.Description,
              Active: d.Active,
              Enable: d.Enable,
              "Last Seen": d["Last Seen"],
              Info: {
                group: d?.Info?.group || "",
                company: company?.companyName || "",
                Name: d?.Info?.Name || "",
              },
            };
          });
          setDevicesListData(tableData);
          setLoading(false);
          // console.log("temp",tableData)
        } else if (loggedUserRole.toLowerCase() === "company admin") {
          setLoading(true);
          const {
            data: { Data: deviceList },
          } = await groupDetail(loggedUserCompanyId);

          const { data: companyList } = await getCompanyList();
          setCompanyListData(companyList);

          const tableData = deviceList?.Devices?.map((d) => {
            const company =
              companyList?.find(
                (c) => c?.companyId == d?.Info?.group?.substring(8)
              ) || null;

            return {
              Token: d.Token,
              Name: d.Name,
              Profile: d.Profile,
              Description: d.Description,
              Active: d.Active,
              Enable: d.Enable,
              "Last Seen": d["Last Seen"],
              Info: {
                group: d?.Info?.group || "",
                company: company?.companyName || "",
                Name: d?.Info?.Name || "",
              },
            };
          });
          setDevicesListData(tableData);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setErrorMessage("Something went wrong");
        setLoading(true);
      }
    };

    fetchData();
    setDeviceDeleteState(false);
    setDeviceEditState(false);
  }, [deviceDeleteState, deviceEditState]);

  // useEffect(() => {
  //   dispatch(getAllDevices(loggedUserRole, loggedUserCompanyId));
  //   dispatch(getCompanies());
  // }, [deviceDeletetionFlag]);

  const loadDeviceProcessing = useSelector(
    ({ device }) => device.loadDeviceProcessing
  );
  const allDevices = useSelector(({ device }) => device.allDevices);

  const [searchText, setSearchText] = useState("");

  //Delete Device
  const [deviceToDelete, setDeviceToDelete] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);

  const handleGroupEdit = (device) => {
    setSingleDevice(device);
    setDeviceModelOpen(true);
  };

  const deleteDevice = (device) => {
    setDeviceToDelete(device);
    setConfirmAlert(true);
  };

  const cancelDelete = () => {
    setDeviceToDelete("");
    setConfirmAlert(false);
  };

  const handleDeleteDevice = async () => {
    // console.log(deviceToDelete)
    try {
      await memberAdd([deviceToDelete.Token]);
      await memberRemove(deviceToDelete.Info.group, [deviceToDelete.Token]);
      await infoDevice(deviceToDelete.Token);
      // dispatch(deleteDevices(deviceToDelete.Token));
      // dispatch(
      //   deleteDevices(
      //     deviceToDelete.Token,
      //     deviceToDelete.Info.group,
      //     loggedUserRole,
      //     loggedUserCompanyId
      //   )
      // );
      cancelDelete();
      setSuccessMessage("Remove Device Successful");
      setDeviceDeleteState(true);
    } catch (e) {
      console.log("Delete Error - ", e);
      setErrorMessage("Something went wrong");
    }
  };

  const editDevice = (device) => {
    history.push("/device/edit/" + device.Token);
  };

  const [company, setCompany] = useState(0);
  const companyList = useSelector(({ device }) => device.companyList);

  useEffect(() => {
    if (searchText === "") {
      setFilteredDevices(
        devicesListData?.filter(
          (device) =>
            company === 0 ||
            (device.Info &&
              device.Info.group &&
              device.Info.group === "ESSPERTH" + company)
        )
      );
    } else {
      setFilteredDevices(
        devicesListData?.filter(
          (device) =>
            (device.Token.toLowerCase().includes(searchText.toLowerCase()) ||
              device.Name.toLowerCase().includes(searchText.toLowerCase()) ||
              (device.Info &&
                device.Info.Driverid &&
                device.Info.Driverid.toLowerCase().includes(
                  searchText.toLowerCase()
                ))) &&
            (company === 0 ||
              (device.Info &&
                device.Info.group &&
                device.Info.group === "ESSPERTH" + company))
        )
      );
    }
  }, [devicesListData, searchText, company]);
  //  console.log("devicesListData", devicesListData);

  return (
    <Paper className={classes.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={errorMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setErrorMessage("")}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={successMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSuccessMessage("")}
          severity="success"
        >
          {successMessage}
        </MuiAlert>
      </Snackbar>
      <Typography variant="h5" style={{ margin: "6px 8px" }}>
        Device List
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#939799",
          marginBottom: "1rem",
          flexWrap: "wrap",
        }}
      >
        <div
          className={classes.searchRoot}
          style={{
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
          }}
        >
          <InputBase
            className={classes.searchBoxText}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton type="submit" aria-label="search">
            <SearchIcon className={classes.iconColor} />
          </IconButton>
        </div>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
        >
          {/* <InputLabel id="demo-simple-select-outlined-label">Time</InputLabel> */}
          {loggedUserRole.toLowerCase() !== "admin" ? (
            ""
          ) : (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // label="Time"
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              className={classes.forminput}
              InputProps={{
                className: classes.forminput,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            >
              <MenuItem value={0}>Select Organization</MenuItem>
              {companyListData?.map((com) => (
                <MenuItem key={com.companyId} value={com.companyId}>
                  {com.companyName}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Token</TableCell>
              <TableCell className={classes.tableHeadCell}>Name</TableCell>
              <TableCell className={classes.tableHeadCell}>Company</TableCell>
              <TableCell className={classes.tableHeadCell}>
                Description
              </TableCell>
              <TableCell className={classes.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>
          {!loading ? (
            <TableBody>
              {getPlaginatedData()?.map((device) => (
                <TableRow key={device.Token} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {" "}
                    {device.Token}{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {" "}
                    {device.Name}{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {" "}
                    {device.Info && device.Info.company
                      ? device.Info.company
                      : "-"}{" "}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {device.Description ? device.Description : "-"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <ButtonGroup
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      <Button
                        onClick={() => deleteDevice(device)}
                        className={
                          device.Info && device.Info.company
                            ? classes.deleteBtn
                            : classes.buttonGray
                        }
                        disabled={loggedUserRole.toLowerCase() === "admin" || loggedUserRole.toLowerCase() === "company admin" ? !(device.Info && device.Info.company):true}
                        // disabled={!(device.Info && device.Info.company)}
                      >
                        <DeleteForever /> Remove from organization
                      </Button>
                      <Button
                        className={classes.editBtn}
                        onClick={() => handleGroupEdit(device)}
                        disabled={loggedUserRole.toLowerCase() === "admin" || loggedUserRole.toLowerCase() === "company admin" ? false:true}
                      >
                        <Edit /> Edit
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  No Records
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        {loadDeviceProcessing ? <LinearProgress /> : ""}
        <TablePagination
          className={classes.TablePagination}
          component="div"
          count={filteredDevices?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={(event, newPage) => setPage(newPage)}
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
        />
      </TableContainer>
      {deviceModelOpen && (
        <EditDeviceModel
          deviceModelOpen={deviceModelOpen}
          setDeviceModelOpen={setDeviceModelOpen}
          singleDevice={singleDevice}
          setSingleDevice={setSingleDevice}
          setDeviceEditState={setDeviceEditState}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <div>
        <Dialog
          open={confirmAlert}
          onClose={cancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.alertBox}>
            <strong>Are You Sure ? </strong>
          </DialogTitle>
          <DialogContent className={classes.alertBox}>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.alertBox}
            >
              You are trying to Remove Device - {deviceToDelete.Name} from
              organization
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.alertBox}>
            <Button onClick={cancelDelete} className={classes.buttonWarning}>
              Cancel
            </Button>
            <Button
              className={classes.buttonSuccess}
              autoFocus
              onClick={handleDeleteDevice}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Paper>
  );
}

export default DeviceComponent;
