import React, { useEffect, useState } from "react"
import HeaderComponent from "./HeaderComponent/HeaderComponent"
import SideBarComponent from "./SideBarComponent/SideBarComponent"

function LayoutComponent(props) {
    const drawerWidth = props.drawerWidth;

    const [sidebarOpen, setSidebarOpen] = useState(true)

    const handleDrawer = () => {
        setSidebarOpen((prev) => !prev)
    }
    
    const handleWindowResize = () => {
        if(window.innerWidth < 900){
            setSidebarOpen(false);
        }else{
            setSidebarOpen(true);
        }
    }

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
    }, []);

    return (
        <>
            <HeaderComponent
                handleDrawer={handleDrawer}
                sidebarOpen={sidebarOpen}
                drawerWidth={drawerWidth}
            />
            <SideBarComponent
                sidebarOpen={sidebarOpen}
                drawerWidth={drawerWidth}
            />
        
        </>
    )
}

export default LayoutComponent
