export const LOGIN_ROUTE = '/login';
export const DASHBOARD_ROUTE = '/';
export const USER_ROUTE = '/user';
export const ADD_USER_ROUTE = '/user/add';
export const EDIT_USER_ROUTE = '/user/edit';
export const DEVICE_ROUTE = '/device';
export const ADD_DEVICE_ROUTE = '/device/add';
export const MY_ACCOUNT = '/myaccount';
export const COMPANY = '/company';
export const ADD_COMPANY = '/company/add';
export const EDIT_COMPANY = '/company/edit';
export const ALARM_ROUTE = '/alarm';
export const ALARM_VIEW_ROUTE = '/alarm/view';
export const ALARM_ADD_ROUTE = '/alarm/add';
export const COMPANY_ADMIN_UPDATE = '/company/admin/update';
export const MAP_ROUTE = '/map';
export const LORAWAN_ROUTE = '/lorawan';
export const DASHBOARD_VIEW='/dashboards/:token';
export const DEVICE_EDIT='/device/edit/:value';

export const DEVICE_ANALYSIS_REPORT = '/reports/deviceanalysisreport';