import React, { useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { color } from "echarts";
import { useCookies } from "react-cookie";

const LineChart = (props) => {
  const {name, paramIcon, xAxisData, yAxisData} = props

  const [cookies] = useCookies(["theme"]);
  const mode = cookies.theme;


  return (
    <div style={{ width: "100%" }}>
      <ReactEcharts
        option={{
          grid: {
            containLabel: true,
            left: '2%',
            right: '5%',
            bottom: '10%',
            top: '25%'
        },
          xAxis: {
            type: "category",
            data: xAxisData ,
            boundaryGap: false,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            top: 30,
            icon: 'pin',
            data: [(name || "")] ,
            textStyle:{
              color: mode === "Dark"? "#ffffff":"#000000"
           },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}" + (paramIcon || ""),
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#757575",
              },
            },
          },
          series: [
            {
              name: (name || ""),
              data: (yAxisData || []),
              type: "line",
              stack: 'Total',
              itemStyle: {
                color: "#2276FC",
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(34, 118, 252,0.5)",
                  },
                  {
                    offset: 1,
                    color: "rgba(34, 118, 252,0.0)",
                  },
                ]),
              },
            },
          ],
        }}
      />
    </div>
  );
};

export default LineChart;
