import React from 'react'
import { Card, Typography, makeStyles } from '@material-ui/core';

function AddUserToDevice(props) {
    const useStyle = makeStyles((theme) => ({
        cardClass: {
            width: "100%",
            padding: "10px 25px",
            backgroundColor: theme.palette.card.main
        },
    }))

    const classes = useStyle()
    return (


        <Card className={classes.cardClass}>
            <div  >
                <Typography>
                    Name :  {props.name}
                </Typography>
                <Typography>
                    Designation :  {props.role}
                </Typography>

            </div>
        </Card>

    )
}
export default AddUserToDevice