import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card, Grid, Modal,
    Backdrop,
    Fade,
    Paper,

    Table,
    TableRow,
    TableCell,
    TableBody,
    TableContainer
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core'
import logo from "../../../assets/images/logo.jpeg"
import BorderLinearProgress from '../BorderLinearProgress/BorderLinearProgress';
import { Settings } from '@material-ui/icons';



const ProgressCircle = (props: CircularProgressProps & { value: number }) => {


    return (
        <Box position="relative" display="inline-flex" color="secondary" >
            <CircularProgress variant="determinate" {...props} size="5rem" style={{ 'color': props.color }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" style={{ 'color': props.color, fontWeight: 'bold', fontSize: '1.5rem' }}>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export const DeviceTileComponent = (props) => {
    let progressValue = 0;
    if (props.targetValue && props.currentValue) {
        progressValue = (props.currentValue / props.targetValue) * 100;
    }


    let circleColor = 'black';
    // if (progressValue >= 80) {
    //     circleColor = '#009A17';
    // } else if (progressValue >= 60) {
    //     circleColor = '#0057C2';
    // } else if (progressValue >= 20) {
    //     circleColor = '#D41920';
    // } else {
    //     circleColor = '#939799';
    // }
    // if (props.currentValue > 0) {
    //     circleColor = '#31D618';
    // } else if (props.currentValue === 0) {
    //     circleColor = '#ED1D24';
    // } else {
    //     circleColor = '#939799';
    // }

    if(props.errorStates === undefined){
        circleColor = '#939799';
    }else if(props.errorStates > 0){
        circleColor = '#D41920';
    }else{
        circleColor = '#009A17';
    }
    const useStyle = makeStyles((theme) => ({
        cardClass: {
            width: "100%",
            margin: "10px 25px",
            backgroundColor: circleColor,
            color: 'white',
            cursor: 'pointer',
            border: '4px solid #06038D'
        },

        ProgressCircle: {
            marginTop: 40,
            marginBottom: 40
        }
    }))
    const classes = useStyle()


    const valueRangeList = [
        {
            id: 1,
            parameter: 'Water Percentage',
            minValue: 0,
            maxValue: 100,
            unit: '%',
            colors: ['#FA6400', '#FFEE52', '#96D98D', '#8AB8FF']
        },
        {
            id: 2,
            parameter: 'Conductivity',
            minValue: 0,
            maxValue: 600,
            unit: 'mS/m',
            colors: ['#FA6400', '#FFEE52', '#96D98D', '#8AB8FF']
        },
        {
            id: 3,
            parameter: 'Temperature',
            minValue: -10,
            maxValue: 50,
            unit: '°C',
            colors: ['#1F60C4', '#56A64B', '#F2CC0C', '#E02F44']
        },
        {
            id: 4,
            parameter: 'Battery',
            minValue: 0,
            maxValue: 5,
            unit: 'V',
            colors: ['#C4162A', '#FF780A', '#E1D24B', '#56A64B']
        },
        {
            id: 6,
            parameter: 'Humidity',
            minValue: 0,
            maxValue: 100,
            unit: '%',
            colors: ['#FA6400', '#E5D64C', '#96D98D', '#8AB8FF']
        },
        {
            id: 8,
            parameter: 'Leaf Moisture',
            minValue: 0,
            maxValue: 100,
            unit: '%',
            colors: ['#EA5E02', '#FFEE52', '#96D98D', '#8AB8FF']
        },
        {
            id: 10,
            parameter: 'pH',
            minValue: 0,
            maxValue: 14,
            unit: 'pH',
            colors: ['#BE2A3D', '#F2CC0C', '#96D98D', '#37872D']
        },
    ];

    const setBarColor = (value, colors) => {
        if(value > 75){
            return colors[3];
        }else if(value > 50){
            return colors[2];
        }else if(value > 25){
            return colors[1];
        }else{
            return colors[0];
        }
    }

    return (
        <Card className={classes.cardClass}>
            <CardContent style={{minHeight: 340, position: 'relative'}}>
                {/* <Grid container style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} 
                    >
                    <Grid item md={12}>
                        <Typography variant="h5" style={{textAlign: 'center', marginBottom: 30, fontWeight: 'bold', color: 'black'}}>
                            {props.device}
                        </Typography>
                    </Grid>
                    <Grid item md={12}
                        style={{display: 'flex', justifyContent: 'space-between'}} 
                    >
                        <div>
                            <Typography variant="h6" style={{textAlign: 'center', color: '#FFE900', fontWeight: 'bold'}}>
                                Alarm
                            </Typography>
                            <Typography variant="h5" style={{textAlign: 'center', fontWeight: 'bold'}}>
                                {(props.errorStates)? props.errorStates: 0}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h6" style={{textAlign: 'center', color: '#FFE900', fontWeight: 'bold'}}>
                                Battery
                            </Typography>
                            <Typography variant="h5" style={{textAlign: 'center', fontWeight: 'bold'}}>
                                {(!props.batteryLevel || props.batteryLevel === -1)? 'Unknown': (props.batteryLevel && props.batteryLevel > 2.9)? 'Good': 'Bad'}
                            </Typography>
                        </div>
                    </Grid>

                </Grid> */}
                <div>
                    <Typography variant="h6" style={{textAlign: 'center', marginBottom: 30, fontWeight: 'bold', color: 'black'}}>
                        {props.device}
                    </Typography>
                </div>
                {props.groupInfo && Object.keys(props.groupInfo).filter(key => key.startsWith('dashboardConfig_')).map(config => 
                (props.data && props.data.Devices && props.data.Devices.find(d => d.Token === (props.groupInfo[config].split('$'))[0]) && props.data.Devices.find(d => d.Token === (props.groupInfo[config].split('$'))[0]).Data && props.data.Devices.find(d => d.Token === (props.groupInfo[config].split('$'))[0]).Data.find(p => p.Parameter === (props.groupInfo[config].split('$'))[1]) && (valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2])))?(
                    <div style={{marginBottom: '1rem'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', margin: 0, padding: 0}}>
                            <span style={{fontSize: 12}}>{(valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).minValue)}{(valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).unit)}</span>
                            <Typography variant="body1" style={{textAlign: 'center', color: 'black'}}>
                                {(props.groupInfo[config].split('$'))[2]}
                            </Typography>
                            <span style={{fontSize: 12}}>{(valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).maxValue)}{(valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).unit)}</span>
                        </div>
                        <BorderLinearProgress variant="determinate" value={((props.data.Devices.find(d => d.Token === (props.groupInfo[config].split('$'))[0]).Data.find(p => p.Parameter === (props.groupInfo[config].split('$'))[1])).Value / ((valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).maxValue) - (valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).minValue))) * 100} barColor={setBarColor(((props.data.Devices.find(d => d.Token === (props.groupInfo[config].split('$'))[0]).Data.find(p => p.Parameter === (props.groupInfo[config].split('$'))[1])).Value / ((valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).maxValue) - (valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).minValue))) * 100, (valueRangeList.find(vR => vR.parameter === (props.groupInfo[config].split('$'))[2]).colors))} />
                    </div>
                ): '')}
                <div style={{display: 'flex', justifyContent: 'space-between', position: 'absolute', bottom: 0, width: '100%', left: '50%', transform: 'translate(-50%, -50%)', padding: '0 5px'}}>
                    <div>
                        <Typography variant="body1" style={{textAlign: 'center', color: '#FFE900', fontWeight: 'bold'}}>
                            Alarm
                        </Typography>
                        <Typography variant="h6" style={{textAlign: 'center', fontWeight: 'bold'}}>
                            {(props.errorStates)? props.errorStates: 0}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body1" style={{textAlign: 'center', color: '#FFE900', fontWeight: 'bold'}}>
                            Battery
                        </Typography>
                        <Typography variant="h6" style={{textAlign: 'center', fontWeight: 'bold'}}>
                            {(!props.batteryLevel || props.batteryLevel === -1)? 'Unknown': (props.batteryLevel && props.batteryLevel > 2.9)? 'Good': 'Bad'}
                        </Typography>
                    </div>
                </div>
                {((props.targetValue && props.currentValue) || props.currentValue === 0) ? <Box display="flex" className={classes.ProgressCircle}>
                    <Box m="auto">

                        <ProgressCircle value={progressValue} color='white' />

                    </Box>
                </Box> : ''}

                {/* <Grid container>
                    <Grid item xs={12} sm={12} md={6} style={{textAlign: 'center'}}>

                        {props.targetValue || props.targetValue === 0 ? (
                            <>
                                <Typography variant="subtitle2">
                                    Current Value
                                </Typography>
                                <Typography variant="h4">
                                    {(props.currentValue / 1000).toFixed(2)} T
                                </Typography>
                            </>
                        ) : ''
                        }

                    </Grid>

                    <Grid item xs={12} sm={12} md={6} style={{textAlign: 'center'}}>
                        {props.currentValue || props.currentValue === 0 ? (
                            <>
                                <Typography variant="subtitle2">
                                    Target Value
                                </Typography>
                                <Typography variant="h4">
                                    {(props.targetValue / 1000).toFixed(2)} T
                                </Typography>
                            </>
                        ) : ''
                        }
                    </Grid>
                </Grid> */}

            </CardContent>
        </Card>
    )
}







// export default DeviceTileComponent


export const DeviceTileComponentDetails = (props) => {

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            width: "100%",
            [theme.breakpoints.up('sm')]: {
                width: "50%",
            },
            padding: "20px",
            // // background// color: theme.palette.input.background,
        },
        formControl: {
            margin: '2em 0'
        },
        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        deviceMainTile: {
            // // background// color: theme.palette.input.background,
            width: '100%'
        },
        logoClasses: {
            width: "90%"
        },
        sideTiles: {
            textAlign: 'left',
            padding: '10px 20px',
            width: '100%',
            borderWidth: '3px',
            borderColor: theme.typography.button.borderColor,
            // // background// color: theme.palette.input.background,
            borderStyle: 'solid'
        },
        headSideTiles: {
            textAlign: 'center',
            padding: '10px 20px',
            width: '100%',
            borderWidth: '3px',
            borderColor: theme.typography.button.borderColor,
            // // background// color: theme.palette.input.background,
            borderStyle: 'solid'
        },
        mainSideTiles: {
            textAlign: 'center',
            padding: '10px 20px',
            width: '100%',
            borderWidth: '3px',
            borderColor: theme.typography.button.borderColor,
            // // background// color: theme.palette.input.background,
            borderStyle: 'solid'
        },
        leftCell: theme.typography.leftCell,
        topRightCell: theme.typography.topRightCell,
        bottomRightCell: theme.typography.bottomRightCell,


        hrBar: {
            height: '4px',
            margin: "none",
            border: 'none',
            backgroundColor: theme.typography.bottomRightCell.borderColor,
        }

    }))

    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <Paper className={classes.container}>
                    <Grid container style={{ width: '100%' }}>
                        <Grid container md={12} >
                            <Grid item md={8} xs={12}>
                                <img src={logo} className={classes.logoClasses} alt="Mass Industrial Solutions" />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <p style={{ textAlign: 'right' }}>{props.lastUpdatedTime.calendar()}</p>
                            </Grid>
                        </Grid>
                        <Grid md={12} >

                            <TableContainer style={{ width: '100%' }}>
                                <Table>
                                    <TableBody >

                                        <TableRow>
                                            <TableCell align="center" className={classes.leftCell}><strong>Truck:</strong> {props.data.Name}</TableCell>
                                            <TableCell align="center" className={classes.topRightCell}><strong>Bucket Count:</strong> {props.data.Data[5].Value}</TableCell>
                                            <TableCell align="center" className={classes.topRightCell}><strong>Target Weight:</strong> {(props.data.Data[0].Value / 1000).toFixed(2)} T</TableCell>
                                        </TableRow>
                                        {/* </TableBody>
                                </Table>
                                <Table>
                                    <TableBody> */}
                                        <TableRow>
                                            <TableCell align="center" className={classes.leftCell}><strong>Goods :</strong> {props.data.goods}</TableCell>
                                            <TableCell align="center" colSpan={2} rowSpan={3} className={classes.bottomRightCell}> <strong>Total Weight:</strong> <br /> {(props.data.Data[1].Value / 1000).toFixed(2)} T<hr className={classes.hrBar} /><strong>Single Bucket Weight:</strong> <br />{(props.data.Data[3].Value).toFixed(2)} T</TableCell>

                                        </TableRow>
                                        <TableRow >
                                            <TableCell align="center" className={classes.leftCell}><strong>Oper : </strong>{props.data.oper}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" className={classes.leftCell}><strong>Driver:</strong> {props.data.Name}</TableCell>

                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    )
}


