import React, { useEffect, useState } from "react"
import {
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableCell,
    Paper,
    InputBase,
    Divider,
    IconButton,
    ButtonGroup,
    Button,
    LinearProgress,
    TableSortLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core"
import SearchIcon from '@material-ui/icons/Search';
import { Edit, DeleteForever, VpnKey } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { clearGetUserError, deleteUsers, getUsers, setEditingUserId, updateUserPassword } from "./store/actions";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        padding: "20px",
        // backgroundColor: theme.palette.paper.main
    },
    alertBox: {
        // background: theme.palette.paper.main,
        // // color: theme.palette.input.label,
    },

    tableContainer: {
        // padding: theme.spacing(3),
        overflowX: "auto",
    },
    table: {
    },
    tableCell: {
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        // color: theme.palette.input.text,

    },
    tableHeadCell: {
        fontSize: theme.typography.body1.fontSize,
        textAlign: 'center',
        fontWeight: 'bold',
        // color: theme.palette.input.text
    },
    tableRow: {
        transitionDuration: 0.2,
        '&:hover': {
            // backgroundColor: theme.palette.table.hover,
            transitionDuration: 0.2,

        }
    },
    TablePagination: {
        // color: theme.palette.input.text
    },
    tableButton: {
        borderColor: theme.typography.button.borderColor,
        color: theme.typography.button.color,
    },
    buttonSuccess: theme.typography.buttonSuccess,
    buttonWarning: theme.typography.buttonWarning,
    buttonDanger: theme.typography.buttonDanger,
    buttonWarning: theme.typography.buttonWarning,

    searchRoot: {
        padding: "0px 4px",
        marginTop: "8px",
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: 400,
        marginLeft: 'auto',
        backgroundColor: '#f2f2f2',
        borderColor: theme.palette.primary.main,
        borderStyle: "solid",
        borderWidth: "1px",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: "100%",
        color:'#363434',
    },
    iconButton: {
        padding: 10,
    },

    divider: {
        height: 28,
        marginTop:8,
        margin: 4,
        backgroundColor:'#5c5c5c'
    },
    forminput: {
        // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
        borderWidth: "1px",
        // borderColor: theme.palette.input.borderColor
    },
    iconColor:{
        color:'#363434'
    },
    deleteBtn: {
        color: theme.palette.error.dark,
      },
    editBtn: {
        color: theme.palette.warning.dark,
    },
}))

function UserComponent(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    // const users = [{ name: 'admin', userEmail: 'admin@gmail.com', contact: '0713456789', username: 'admin', usergroup: 'Admins' },
    // { name: 'kasun Chamara', userEmail: 'jone@gmail.com', contact: '0713456789', username: 'user1', usergroup: 'Manager' },
    // { name: 'Jhone 1', userEmail: 'jone@gmail.com', contact: '0713456789', username: 'user2', usergroup: 'user' },
    // { name: 'Jhone 2', userEmail: 'jone@gmail.com', contact: '0713456789', username: 'user3', usergroup: 'user' },
    // { name: 'Jhone 3', userEmail: 'jone@gmail.com', contact: '0713456789', username: 'user4', usergroup: 'user' },
    // { name: 'Jhone 4', userEmail: 'jone@gmail.com', contact: '0713456789', username: 'user5', usergroup: 'user' },
    // ]

    const users = useSelector(({ user }) => user.users);
    const userCount = useSelector(({ user }) => user.userCount);
    const loadUserProcessing = useSelector(({ user }) => user.loadUserProcessing);
    const userDeletetionFlag = useSelector(({ user }) => user.userDeletetionFlag);
    const getUserError = useSelector(({ user }) => user.getUserError);

    const rowsPerPageOptions = [5, 10, 30];
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const [searchText, setSearchText] = useState('');

    const [sortParameter, setSortParameter] = useState('name');
    const [sortType, setSortType] = useState('asc');

    const handleSorting = parameter => {
        const isAsc = sortParameter === parameter && sortType === "asc";
        setSortParameter(parameter);
        setSortType(isAsc ? "desc" : "asc");
    }



    useEffect(() => {
        dispatch(getUsers(page, rowsPerPage, searchText, sortParameter, sortType));
    }, [page, rowsPerPage, searchText, sortParameter, sortType, dispatch, userDeletetionFlag]);

    //Delete User
    const [userToDelete, setUserToDelete] = useState('');
    const [confirmAlert, setConfirmAlert] = useState(false);

    const deleteUser = (user) => {
        setUserToDelete(user)
        setConfirmAlert(true)
    }

    const cancelDelete = () => {
        setUserToDelete('')
        setConfirmAlert(false)
    }

    const handleDeleteUser = () => {
        dispatch(deleteUsers(userToDelete.userid));
        cancelDelete();
    }

    const editUserHandler = (userId) => {
        dispatch(setEditingUserId(userId));
        history.push('/user/edit')
    }

    const [passwordChangingUser, setPasswordChangingUser] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const changePasswordDialogBoxOpen = (userId) => {
        setPasswordChangingUser(userId)
    }
    const changePasswordDialogBoxClose = () => {
        setPasswordChangingUser(null);
        setNewPassword('');
        setConfirmPassword('');
        setNewPasswordError('');
        setConfirmPasswordError('');
    }

    const changePassword = () => {
        if(newPassword.length < 8){
            setNewPasswordError('Password length must be greater than 8!');
            return;
        }
        if(newPassword !== confirmPassword){
            setConfirmPasswordError('Confirm Password is not match with new password!');
            return;
        }
        dispatch(updateUserPassword(newPassword, passwordChangingUser));
        changePasswordDialogBoxClose();
    }

    return (
        <Paper className={classes.container}>
            <Typography variant="h5" style={{margin: '6px 8px'}} className={classes.heading}>User List</Typography>
            {(getUserError !== '') && (<Alert severity="error" onClose={() => { dispatch(clearGetUserError()) }}>{getUserError}</Alert>)}
            <div className={classes.searchRoot} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopRightRadius: 20, borderTopLeftRadius: 20,}}>
                <InputBase
                    className={classes.input}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchText}
                    onChange={event => setSearchText(event.target.value)}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon color="inherit" className={classes.iconColor} />
                </IconButton>
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>
                                <TableSortLabel
                                    active={sortParameter === 'name'}
                                    direction={
                                        sortParameter === 'name'
                                            ? sortType
                                            : "asc"
                                    }
                                    onClick={() => {
                                        handleSorting('name')
                                    }}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>
                                <TableSortLabel
                                    active={sortParameter === 'userEmail'}
                                    direction={
                                        sortParameter === 'userEmail'
                                            ? sortType
                                            : "asc"
                                    }
                                    onClick={() => {
                                        handleSorting('userEmail')
                                    }}
                                >
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableHeadCell}>Username</TableCell>
                            <TableCell className={classes.tableHeadCell}>User Group</TableCell>
                            <TableCell className={classes.tableHeadCell}>Company</TableCell>
                            <TableCell className={classes.tableHeadCell}>Contact No</TableCell>
                            <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {(loadUserProcessing) ? ('') : (
                        <TableBody>
                            {
                                users.map(user => (
                                    <TableRow key={user.userid} className={user.tableRow}>
                                        <TableCell className={classes.tableCell}>{user.firstName + ' ' + user.lastName}</TableCell>
                                        <TableCell className={classes.tableCell}>{user.userEmail} </TableCell>
                                        <TableCell className={classes.tableCell}>{user.userEmail}</TableCell>
                                        <TableCell className={classes.tableCell}>{user.usrGroupName}</TableCell>
                                        <TableCell className={classes.tableCell}>{(user.companyName)? user.companyName: '-'}</TableCell>
                                        <TableCell className={classes.tableCell}>{user.contactNo}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <ButtonGroup variant="outlined" size="small" >
                                                <Button className={classes.editBtn} onClick={() => editUserHandler(user.userid)}><Edit /> Edit</Button>
                                                <Button className={classes.buttonWarning} onClick={() => changePasswordDialogBoxOpen(user.userid)}><VpnKey /> Change Password</Button>
                                                <Button className={classes.deleteBtn} onClick={() => deleteUser(user)} ><DeleteForever /> Delete</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>

                                ))
                            }

                        </TableBody>
                    )}

                </Table>
                {(loadUserProcessing) ? (<LinearProgress />) : ''}
                <TablePagination
                    component="div"
                    count={userCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    className={classes.TablePagination}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onChangePage={(event, newPage) => setPage(newPage)}
                    onChangeRowsPerPage={(event) => {
                        setRowsPerPage(parseInt(event.target.value))
                        setPage(0)
                    }}
                />
            </TableContainer>

            <div>

                <Dialog
                    open={confirmAlert}
                    onClose={cancelDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title" className={classes.alertBox}><strong>Are You Sure ? </strong></DialogTitle>
                    <DialogContent className={classes.alertBox}>
                        <DialogContentText id="alert-dialog-description" className={classes.alertBox} style={{ fontSize: '15px' }}>
                            You are trying to Delete a user - {userToDelete.firstName} {userToDelete.lastName}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.alertBox}>
                        <Button onClick={cancelDelete} className={classes.buttonWarning} >
                            Cancel
                        </Button>
                        <Button className={classes.buttonSuccess} autoFocus onClick={handleDeleteUser}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {/* password changing popup code */}
            <Dialog
                open={passwordChangingUser !== null}
                // TransitionComponent={Transition}
                keepMounted
                onClose={changePasswordDialogBoxClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className={`${classes.modalBackground} ${classes.modalTitle}`}>{"Changing Password"}</DialogTitle>
                <DialogContent className={classes.modalBackground}>
                    <DialogContentText id="alert-dialog-slide-description" style={{width: '300px'}}>
                        <TextField
                            label="New Password" variant="outlined" size="small" fullWidth name="newPassword" type="password"
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            onChange={event => setNewPassword(event.target.value)}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            value={newPassword}
                            helperText={newPasswordError}
                            error={(newPasswordError.length > 0)}
                        />
                        <br /><br />
                        <TextField
                            label="Confirm Password" variant="outlined" size="small" fullWidth name="confirmPassword" type="password"
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.cssLabel,

                                }
                            }}
                            onChange={event => setConfirmPassword(event.target.value)}
                            InputProps={{
                                className: classes.forminput,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            value={confirmPassword}
                            helperText={confirmPasswordError}
                            error={(confirmPasswordError.length > 0)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.modalBackground}>
                    <Button onClick={changePasswordDialogBoxClose} className={classes.buttonWarning}>
                        Cancel
                    </Button>
                    <Button onClick={changePassword} className={classes.buttonSuccess}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </Paper >

    )
}

export default UserComponent
