import React, { useState, useEffect } from 'react'
import {
    Button,
    Grid,
    makeStyles,
    Paper,
    TextField,
    FormGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputBase,
    Typography,
    FormHelperText,

} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeviceFormConfirmationComponent from '../DeviceFormConfirmationComponent/DeviceFormConfirmationComponent'
import AddUserToDevice from '../AddUserToDevice/AddUserToDevice'
import { useDispatch, useSelector } from 'react-redux';
import { addDeviceFormResetToFalse, getCompanies, getDeviceByToken, getDevices, getUsers, resetDevices, startLoadDeviceProcessing } from '../store/actions';
import EditDeviceConfirmationComponent from './EditDeviceConfirmationComponent/EditDeviceConfirmationComponent';
import  { NavLink } from 'react-router-dom'
import {  DEVICE_ROUTE } from "../../../RouteComponent/PathConfig";

function EditDeviceComponent(props) {

    const dispatch = useDispatch();
    const [url, setUrl] = useState(window.location.href.split("/"));

    useEffect(() => {
        dispatch(resetDevices());
        dispatch(startLoadDeviceProcessing());
        dispatch(getDeviceByToken(url[url.length - 1]));
    }, [dispatch])


    const device = useSelector(({device}) => device.device);
    const loadDeviceProcessing = useSelector(({device}) => device.loadDeviceProcessing);

    const useStyles = makeStyles((theme) => ({
        container: {
            width: "100%",
            padding: "20px",
        },
        formControl: {
            margin: '2em 0',
        },

        inputField: {
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.inputBoxSecond.color
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.inputBoxSecond.color
                
            },"& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.inputBoxSecond.color
            },
        },

        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        Accordion: {
            backgroundColor: theme.palette.secondary.main
        },

        input: {

            width: "90%",
            padding: 2,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            margin: "20px 0"
        },
        notchedOutline: {
            borderWidth: "1px",
            // borderColor: theme.palette.input.borderColor
        },
        employeeCard: {
            border: 'none'
        },
        heading: {
            // color: theme.palette.input.text,
        },
        cancelButton: {
            backgroundColor: "red",
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        }
    }))

    const classes = useStyles();

    const defaultFormData = {
        deviceToken: null,
        deviceName: '',
        description: ''
    };

    const defaultFormErrors = {
        deviceName: ''
    };

    const [formData, setFormData] = useState(defaultFormData);
    const [formErrors, setFormErrors] = useState(defaultFormErrors);

    useEffect(() => {
        if(device){
            setFormData(prev => {
                return {
                    ...prev,
                    deviceToken: device.Token,
                    deviceName: device.Name,
                    description: device.Description
                }
            });
            handleConfirmModalClose();
        }
    }, [device]);

    const handleForm = (event) => {
        setFormData(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        });
    }

    const validateFormData = () => {
        const errors = defaultFormErrors;
        let isHaveErrors = false;

        // if (formData.driverId === '') {
        //     errors.driverId = 'Device name is  not valid';
        //     isHaveErrors = true;
        // }
        if (formData.deviceName === '') {
            errors.deviceName = 'Device name cannot be empty!';
            isHaveErrors = true;
        }

        setFormErrors(errors);
        return isHaveErrors;
    }
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

    const handleConfirmModalOpen = () => {

        if (!validateFormData()) {
            setConfirmModalOpen(true);
        }
    };

    const handleConfirmModalClose = () => {
        setConfirmModalOpen(false);
    };

    const addDeviceFormReset = useSelector(({ device }) => device.addDeviceFormReset);

    // useEffect(() => {
    //     if (addDeviceFormReset) {
    //         resetForm();
    //         dispatch(addDeviceFormResetToFalse());
    //         handleConfirmModalClose();
    //     }
    // }, [addDeviceFormReset, dispatch])

    const resetForm = () => {
        if(device){
            setFormData(prev => {
                return {
                    ...prev,
                    deviceName: device.Name,
                    description: device.Description
                }
            })
        }else{
            setFormData(defaultFormData);
        }
        setFormErrors(defaultFormErrors);
    }

    const RedirectPage = () => {
        this.props.history.push('/device')
      }
    return (
        <>
            <Paper className={classes.container}>
                <Typography variant="h5" style={{margin: '6px 8px'}} className={classes.heading}>Edit Device - {url[url.length - 1]}</Typography>
                <form>
                    <Grid container  >
                        <Grid sm={12} md={5} >
                            <div className={classes.formControl}>
                                <TextField
                                    label="Device Name" variant="outlined" size="small" fullWidth name="deviceName"
                                    onChange={handleForm}
                                    className={classes.inputField}
                                    value={formData.deviceName}
                                    helperText={formErrors.deviceName}
                                    error={(formErrors.deviceName.length > 0)}
                                    disabled={loadDeviceProcessing}
                                />
                            </div>
                        </Grid>
                        <Grid sm={12} md={1}>

                        </Grid>
                        <Grid sm={12} md={5} >
                            <div className={classes.formControl}>
                                <TextField
                                    label="Description" variant="outlined" size="small" fullWidth name="description"
                                    onChange={handleForm}
                                    className={classes.inputField}
                                    value={formData.description}
                                    disabled={loadDeviceProcessing}
                                />
                            </div>
                        </Grid>
                        <Grid xm={12} md={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <div style={{ textAlign: 'right' }}>
                                <Button className={classes.cancelButton} variant="contained" style={{ marginRight: '10px' }} component={NavLink} to={DEVICE_ROUTE} disabled={loadDeviceProcessing}>Cancel</Button>
                                <Button className={classes.addButton} variant="contained" onClick={handleConfirmModalOpen} disabled={loadDeviceProcessing}>Save</Button>
                            </div>
                        </Grid>

                    </Grid>
                </form>
            </Paper>
            <EditDeviceConfirmationComponent handleClose={handleConfirmModalClose} open={confirmModalOpen} formData={formData} deviceToken={url[url.length - 1]} />
        </>
    )
}

export default EditDeviceComponent
