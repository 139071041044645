import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Chip,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { color } from "echarts";
import { useEffect, useRef, useState } from "react";
import {
  acknowledgeAlarm,
  getGroupDetailsAndData,
  startLoading,
  updateWeatherDevice,
} from "../store/actions";

import "@tomtom-international/web-sdk-maps/dist/maps.css";
import tt from "@tomtom-international/web-sdk-maps";
import moment from "moment";
import { Notifications, Settings } from "@material-ui/icons";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import DashboardConfigComponent from "../DashboardConfigComponent/DashboardConfigComponent";
import Gauge from "../components/Gauge";
import LineChart from "../components/LineChart";
import {
  getStatesGroup,
  getGroupDetail,
  getLatestGroup,
  getRowData,
} from "../../../api/Dashboard";
import {
  getGPSDevicesData,
  getGPSDevicesState,
} from "../../../api/Map";
import { useParams } from "react-router-dom";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "20px",
    minHeight: "80vh",
  },
  boxContainer: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  row: {
    display: "flex",
    justifyContent: "center",
    "&>*": {
      // color: theme.palette.input.text,
      margin: theme.spacing(1),
    },
    // color: theme.palette.input.text,
    flexWrap: "wrap",
  },
  mapContainer: {
    padding: "20px",
    minWidth: "400px",
    // backgroundColor: theme.palette.paper.main,
    textAlign: "center",
    flex: 1,
    minHeight: "500px",
  },
  commonContainer: {
    padding: theme.spacing(4),
    minWidth: "500px",
    // backgroundColor: theme.palette.paper.main,
    textAlign: "center",
    flex: "auto",
  },
  deviceContainer: {
    padding: theme.spacing(4),
    minWidth: "400px",
    // backgroundColor: theme.palette.paper.main,
    textAlign: "center",
  },
  chartContainer: {
    // padding: "5px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  chartItem: {
    margin: "auto",
    // minWidth: '240px',
    [theme.breakpoints.down("md")]: {
      width: "180px",
    },
    [theme.breakpoints.up("md")]: {
      width: "220px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "240px",
    },
  },
  chart: {
    height: "250px",
  },
  errorChip: {
    backgroundColor: theme.palette.error.main,
    maxWidth: "240px",
    color: "white",
    "& > *": {
      fill: "white",
    },
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  warningChip: {
    backgroundColor: theme.palette.warning.main,
    maxWidth: "240px",
    color: "white",
    "& > *": {
      fill: "white",
    },
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  idleChip: {
    maxWidth: "240px",
  },
  alarmContainer: {
    height: "30px",
    marginTop: "15px",
  },
  formControl: {
    width: "400px",
  },
  forminput: {
    // // background// color: theme.palette.input.background,
  },
  notchedOutline: {
    borderWidth: "1px",
    // borderColor: theme.palette.input.borderColor
  },
  weatherUpdate: {
    width: "100%",
    padding: "20px",
    margin: "0px 0px 10px 0px",
    // backgroundColor: theme.palette.paper.main,
  },
  settingButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 1000,
    opacity: 0.6,
    border: "none",
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
      opacity: 1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  weatherDeviceContainer: {
    // backgroundColor: theme.palette.paper.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    "& *": {
      // color: theme.palette.input.text,
    },
    minWidth: 300,
  },
  cssLabel: {
    // // color: theme.palette.input.label,
    "&.Mui-focused": {
      // // color: theme.palette.input.labelClick,
    },
  },
  formContainer: {
    margin: theme.spacing(1, 0),
    borderRadius: "4px",
    padding: theme.spacing(1),
  },
}));

function DashboardViewComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = useParams();

  const timeList = [
    { id: 1, label: "Last 6 hour", seconds: 21600 },
    { id: 2, label: "Last 12 hour", seconds: 43200 },
    { id: 3, label: "Last day", seconds: 86400 },
    { id: 4, label: "Last week", seconds: 604800 },
    { id: 5, label: "Last 30 days", seconds: 2629743 },
  ];

  const [frequency, setFrequency] = useState(timeList[1].seconds);
  const [statesGroupState, setStatesGroupState] = useState([]);
  const [groupDetailState, setGroupDetailState] = useState([]);
  const [latestGroupState, setLatestGroupState] = useState([]);
  const [combineApiDataForWether, setCombineApiDataForWether] = useState([]);
  const [GPSDevicesApiData, setGPSDevicesApiData] = useState([]);
  const [GPSDevicesApiState, setGPSDevicesApiState] = useState([]);
  const [GPSDeviceAvailableState, setGPSDeviceAvailableState] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState([]);

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );

  useEffect(() => {
    const fetchData = async () => {
      const endTime = moment().unix();
      const startTime = endTime - frequency;

      try {

        const {
          data: { Data: statesGroup },
        } = await getStatesGroup(token);
        //console.log("statesGroup",statesGroup);
        setStatesGroupState(statesGroup);
  
        const {
          data: { Data: groupDetail },
        } = await getGroupDetail(token);
        //console.log("groupDetail", groupDetail);
        setGroupDetailState(groupDetail);
  
        const {
          data: { Data: latestGroup },
        } = await getLatestGroup(token);
        //console.log("latestGroup",latestGroup);
        setLatestGroupState(latestGroup);
        
        const getRowDataApi = groupDetail?.Devices?.map((data) =>
        getRowData(data.Token, startTime, endTime)
        );
        const rowDataStructure = await axios.all(getRowDataApi);
        const finalRowData = rowDataStructure.map((t) => t.data.Data);
        //console.log("finalRowData",finalRowData);
        setRowData(finalRowData);
        
        //For Wether Api
        
        if(groupDetail?.Devices?.find((d)=>d?.Profile === "ESGPSPendant")){

          const {
            data: { Data: gpsDeviceData },
          } = await getGPSDevicesData();
  
          setGPSDevicesApiData(gpsDeviceData);
  
          const {
            data: { Data: gpsDeviceState },
          } = await getGPSDevicesState();
  
          setGPSDevicesApiState(gpsDeviceState);

          const combineData = gpsDeviceData?.Devices?.filter((gpsFindData) =>groupDetail?.Devices?.find((d) => d.Token === gpsFindData.Token)).map((gpsData) => {
            const {
              StateMachines,
              LocalCounters,
              DeviceCounters,
              ["Last Seen"]: LastSeen,
            } = gpsDeviceState?.Devices?.find(
              (gpsState) => gpsState.Token === gpsData.Token
            );
  
            return {
              Token: gpsData.Token,
              Name: gpsData.Name,
              "Last Seen": LastSeen,
              Data: gpsData.Data,
              StateMachines: StateMachines,
              LocalCounters: LocalCounters,
              DeviceCounters: DeviceCounters,
            };
          });
  
          setCombineApiDataForWether(combineData)

          setGPSDeviceAvailableState(true)

        }else{
          setGPSDeviceAvailableState(false)
        }
        


      } catch (error) {
        console.log(error)
      }
      


    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, [frequency]);

  useEffect(() => {
    const dataFunction = (data, profile, Token, rowData, stateData) => {
      // console.log("data",data)
      // console.log("profile",profile)
      // console.log("Token",Token)
      // console.log("rowData",rowData)

      if (data) {
        if (profile === "ESSoilMoisture") {
          return data
            .filter(
              (filterData) =>
                filterData.Parameter === "Bat" ||
                filterData.Parameter === "water_SOIL" ||
                filterData.Parameter === "temp_SOIL" ||
                filterData.Parameter === "conduct_SOIL"
            )
            .map((mapData) => {
              return {
                Token: Token,
                Name: mapData.Parameter,
                Value: mapData.Value || null,
                linechartData:
                  rowData?.Data?.map(
                    (mapRowData) => mapRowData[mapData.Parameter]
                  ) || [],
                lineChartTime:
                  rowData?.Data?.map((mapRowData) =>
                    moment
                      .unix(mapRowData.Time)
                      .format(
                        timeList.find((time) => time.seconds === frequency)
                          ?.id === 4 ||
                          timeList.find((time) => time.seconds === frequency)
                            ?.id === 5
                          ? "YYYY/MM/DD"
                          : "h:mm a"
                      )
                  ) || [],
                stateValue:
                  stateData?.find((d) => d?.Name === mapData?.Parameter)
                    ?.State || null,
              };
            });
        } else if (profile === "ESTankLevel") {
          // filterData.Parameter === "Distance" 
          return data
            .filter(
              (filterData) =>
                filterData.Parameter === "Bat"
            )
            .map((mapData) => {
              return {
                Token: Token,
                Name: mapData.Parameter,
                Value: mapData.Value || null,
                linechartData:
                  rowData?.Data?.map(
                    (mapRowData) => mapRowData[mapData.Parameter]
                  ) || [],
                lineChartTime:
                  rowData?.Data?.map((mapRowData) =>
                    moment
                      .unix(mapRowData.Time)
                      .format(
                        timeList.find((time) => time.seconds === frequency)
                          ?.id === 4 ||
                          timeList.find((time) => time.seconds === frequency)
                            ?.id === 5
                          ? "YYYY/MM/DD"
                          : "h:mm a"
                      )
                  ) || [],
                stateValue:
                  stateData?.find((d) => d?.Name === mapData?.Parameter)
                    ?.State || null,
              };
            });
        } else if (profile === "ESTempHumidiy") {
          return data
            .filter(
              (filterData) =>
                filterData.Parameter === "BatV" ||
                filterData.Parameter === "TempC_DS" ||
                filterData.Parameter === "Hum_SHT"
            )
            .map((mapData) => {
              return {
                Token: Token,
                Name: mapData.Parameter,
                Value: mapData.Value || null,
                linechartData:
                  rowData?.Data?.map(
                    (mapRowData) => mapRowData[mapData.Parameter]
                  ) || [],
                lineChartTime:
                  rowData?.Data?.map((mapRowData) =>
                    moment
                      .unix(mapRowData.Time)
                      .format(
                        timeList.find((time) => time.seconds === frequency)
                          ?.id === 4 ||
                          timeList.find((time) => time.seconds === frequency)
                            ?.id === 5
                          ? "YYYY/MM/DD"
                          : "h:mm a"
                      )
                  ) || [],
                stateValue:
                  stateData?.find((d) => d?.Name === mapData?.Parameter)
                    ?.State || null,
              };
            });
        } else if (profile === "ESLEAFSensor") {
          return data
            .filter(
              (filterData) =>
                filterData.Parameter === "Bat" ||
                filterData.Parameter === "Leaf_Moisture" ||
                filterData.Parameter === "Leaf_Temperature"
            )
            .map((mapData) => {
              return {
                Token: Token,
                Name: mapData.Parameter,
                Value: mapData.Value || null,
                linechartData:
                  rowData?.Data?.map(
                    (mapRowData) => mapRowData[mapData.Parameter]
                  ) || [],
                lineChartTime:
                  rowData?.Data?.map((mapRowData) =>
                    moment
                      .unix(mapRowData.Time)
                      .format(
                        timeList.find((time) => time.seconds === frequency)
                          ?.id === 4 ||
                          timeList.find((time) => time.seconds === frequency)
                            ?.id === 5
                          ? "YYYY/MM/DD"
                          : "h:mm a"
                      )
                  ) || [],
                stateValue:
                  stateData?.find((d) => d?.Name === mapData?.Parameter)
                    ?.State || null,
              };
            });
        } else if (profile === "ESPHSensor") {
          return data
            .filter(
              (filterData) =>
                filterData.Parameter === "Bat" ||
                filterData.Parameter === "PH1_SOIL" ||
                filterData.Parameter === "TEMP_SOIL"
            )
            .map((mapData) => {
              return {
                Token: Token,
                Name: mapData.Parameter,
                Value: mapData.Value || null,
                linechartData:
                  rowData?.Data?.map(
                    (mapRowData) => mapRowData[mapData.Parameter]
                  ) || [],
                lineChartTime:
                  rowData?.Data?.map((mapRowData) =>
                    moment
                      .unix(mapRowData.Time)
                      .format(
                        timeList.find((time) => time.seconds === frequency)
                          ?.id === 4 ||
                          timeList.find((time) => time.seconds === frequency)
                            ?.id === 5
                          ? "YYYY/MM/DD"
                          : "h:mm a"
                      )
                  ) || [],
                stateValue:
                  stateData?.find((d) => d?.Name === mapData?.Parameter)
                    ?.State || null,
              };
            });
        } else {
          return [];
        }
      } else {
        return [];
      }
    };

    let profile = groupDetailState?.Devices?.filter(
      (d) =>
        d?.Profile === "ESSoilMoisture" ||
        d?.Profile === "ESTankLevel" ||
        d?.Profile === "ESTempHumidiy" ||
        d?.Profile === "ESLEAFSensor" ||
        d?.Profile === "ESPHSensor"
    )?.map((x) => {
      const { Token, ["Data"]: lData } =
        latestGroupState?.Devices?.find((a) => a?.Token === x?.Token) || {};
      const { StateMachines } =
        statesGroupState?.Devices?.find((a) => a?.Token === x?.Token) || {};
      const rData = rowData?.find((a) => a?.Token === x.Token);
      return {
        name: x.Name,
        Token: Token,
        data: dataFunction(lData, x.Profile, Token, rData, StateMachines),
      };
    });

    setData(profile);

    if (combineApiDataForWether) {
      // console.log("combineApiDataForWether",combineApiDataForWether)
      
      combineApiDataForWether.map(async(device, index) => {
          
          let weatherData = null;
          try {
            const weatherRequest = await axios.get(
              `https://api.openweathermap.org/data/2.5/weather?units=metric&lat=${
                device.Data.find((data) => data.Parameter === "Latitude")
                  .Value !==
                device.Data.find((data) => data.Parameter === "Longitude")
                  .Value
                  ? device.Data.find(
                      (data) => data.Parameter === "Latitude"
                    ).Value
                  : device.Data.find(
                      (data) => data.Parameter === "rxLatitude"
                    ).Value
              }&lon=${
                device.Data.find((data) => data.Parameter === "Latitude")
                  .Value !==
                device.Data.find((data) => data.Parameter === "Longitude")
                  .Value
                  ? device.Data.find(
                      (data) => data.Parameter === "Longitude"
                    ).Value
                  : device.Data.find(
                      (data) => data.Parameter === "rxLongitude"
                    ).Value
              }&appid=${process.env.REACT_APP_API_WEATHER_API_KEY}`
            );
            weatherData = weatherRequest.data;
            setOverallWeather(weatherData)
            console.log("first",weatherData)
          } catch (err) {
            console.log(err);
          }
        });
    }
        

        // console.log("combineData",combineData)

    // console.log("temp",profile)
  }, [groupDetailState, latestGroupState, rowData, frequency, combineApiDataForWether]);

  const loading = useSelector(({ dashboard }) => dashboard.loading);
  const groupDetailsAndData = useSelector(
    ({ dashboard }) => dashboard.groupDetailsAndData
  );

  const lastUpdatedTime = useSelector(
    ({ dashboard }) => dashboard.lastUpdatedTime
  );
  const configFlag = useSelector(({ dashboard }) => dashboard.configFlag);
  const [lastUpdatedTimeRefreshFlag, setLastUpdatedTimeRefreshFlag] =
    useState(false);
  const [getDeviceRefreshFlag, setSetDeviceRefreshFlag] = useState(false);

  // useEffect(() => {
  //   if (props.match.params.value) {
  //     dispatch(getGroupDetailsAndData(props.match.params.value, frequency));
  //   }
  //   const timeOut = setTimeout(() => {
  //     setSetDeviceRefreshFlag((prev) => !prev);
  //   }, 300000);
  //   return () => {
  //     clearTimeout(timeOut);
  //   };
  // }, [getDeviceRefreshFlag, dispatch, frequency, configFlag]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLastUpdatedTimeRefreshFlag((prev) => !prev);
  //   }, 60000);
  // }, [lastUpdatedTimeRefreshFlag]);

  const mapElement = useRef();

  const [mapLongitude, setMapLongitude] = useState(118.42);
  const [mapLatitude, setMapLatitude] = useState(-34.09);
  const [mapZoom, setMapZoom] = useState(4);
  const [map, setMap] = useState({});

  const increaseZoom = () => {
    if (mapZoom < 10) {
      setMapZoom(mapZoom + 1);
    }
  };

  const decreaseZoom = () => {
    if (mapZoom > 1) {
      setMapZoom(mapZoom - 1);
    }
  };

  const updateMap = () => {
    map.setCenter([parseFloat(mapLongitude), parseFloat(mapLatitude)]);
    map.setZoom(mapZoom);
  };

  const [overallWeather, setOverallWeather] = useState();

  // const loadMapWithWeather = () => {
  //   if (!loading && groupDetailsAndData && groupDetailsAndData) {
  //     let map = tt.map({
  //       key: "NdfCp7GrAH1VwqIQ70GBhQWt8kHRR50s",
  //       container: mapElement.current,
  //       center: [mapLongitude, mapLatitude],
  //       zoom: mapZoom,
  //     });
  //     groupDetailsAndData
  //       .filter((device) => device.Profile === "ESGPSPendant")
  //       .forEach(async (device, index) => {
  //         let color = "gray";
  //         if (
  //           device.state &&
  //           device.state.StateMachines &&
  //           device.state.StateMachines.length > 0 &&
  //           device.state.StateMachines[0].Level.toLowerCase() ===
  //             "informational"
  //         ) {
  //           color = "#0F0";
  //         } else if (
  //           device.state &&
  //           device.state.StateMachines &&
  //           device.state.StateMachines.length > 0 &&
  //           device.state.StateMachines[0].Level.toLowerCase() === "error"
  //         ) {
  //           color = "#F00";
  //         }
  //         const popupOffsets = {
  //           top: [0, 0],
  //           bottom: [0, -40],
  //           "bottom-right": [0, -70],
  //           "bottom-left": [0, -70],
  //           left: [25, -35],
  //           right: [-25, -35],
  //         };
  //         let weatherData = null;
  //         try {
  //           const weatherRequest = await axios.get(
  //             `https://api.openweathermap.org/data/2.5/weather?units=metric&lat=${
  //               device.data.Data.find((data) => data.Parameter === "Latitude")
  //                 .Value !==
  //               device.data.Data.find((data) => data.Parameter === "Longitude")
  //                 .Value
  //                 ? device.data.Data.find(
  //                     (data) => data.Parameter === "Latitude"
  //                   ).Value
  //                 : device.data.Data.find(
  //                     (data) => data.Parameter === "rxLatitude"
  //                   ).Value
  //             }&lon=${
  //               device.data.Data.find((data) => data.Parameter === "Latitude")
  //                 .Value !==
  //               device.data.Data.find((data) => data.Parameter === "Longitude")
  //                 .Value
  //                 ? device.data.Data.find(
  //                     (data) => data.Parameter === "Longitude"
  //                   ).Value
  //                 : device.data.Data.find(
  //                     (data) => data.Parameter === "rxLongitude"
  //                   ).Value
  //             }&appid=${process.env.REACT_APP_API_WEATHER_API_KEY}`
  //           );
  //           weatherData = weatherRequest.data;
  //         } catch (err) {
  //           console.log(err);
  //         }
  //         if (device.Token === weatherDeviceToken) {
  //           setOverallWeather(weatherData);
  //         }
  //         if (weatherDeviceToken === 0 && index === 0) {
  //           setOverallWeather(weatherData);
  //         }

  //         let htmlView = `<h3 style="color: black;">${device.Name}</h3>`;

  //         if (weatherData) {
  //           htmlView += `
  //               <div style="border: 1px solid black; border-radius: 3px; padding: 5px;">
  //                 <p style="font-size: 10px; color: black">Weather</p>
  //                 <img src="http://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png" height="50" />
  //                 <p style="color: gray;">${weatherData.weather[0].main}</p>
  //                 <p style="color: black;">Temperature: ${weatherData.main.temp}°C</p>
  //                 <p style="color: black;">Humidity: ${weatherData.main.humidity}%</p>
  //               </div>
  //             `;
  //         }
  //         let popup = new tt.Popup({ offset: popupOffsets }).setHTML(htmlView);
  //         new tt.Marker({ color: color })
  //           .setLngLat([
  //             device.data.Data.find((data) => data.Parameter === "Latitude")
  //               .Value !==
  //             device.data.Data.find((data) => data.Parameter === "Longitude")
  //               .Value
  //               ? device.data.Data.find(
  //                   (data) => data.Parameter === "Longitude"
  //                 ).Value
  //               : device.data.Data.find(
  //                   (data) => data.Parameter === "rxLongitude"
  //                 ).Value,
  //             device.data.Data.find((data) => data.Parameter === "Latitude")
  //               .Value !==
  //             device.data.Data.find((data) => data.Parameter === "Longitude")
  //               .Value
  //               ? device.data.Data.find((data) => data.Parameter === "Latitude")
  //                   .Value
  //               : device.data.Data.find(
  //                   (data) => data.Parameter === "rxLatitude"
  //                 ).Value,
  //           ])
  //           .setPopup(popup)
  //           .addTo(map);
  //       });
  //     setMap(map);
  //     return () => map.remove();
  //   }
  // };

  // const loadWeather =  () => {
  //   if (combineApiDataForWether) {
  //     console.log("combineApiDataForWether",combineApiDataForWether)
      
  //     combineApiDataForWether.map(async(device, index) => {
          
  //         let weatherData = null;
  //         try {
  //           const weatherRequest = await axios.get(
  //             `https://api.openweathermap.org/data/2.5/weather?units=metric&lat=${
  //               device.Data.find((data) => data.Parameter === "Latitude")
  //                 .Value !==
  //               device.Data.find((data) => data.Parameter === "Longitude")
  //                 .Value
  //                 ? device.Data.find(
  //                     (data) => data.Parameter === "Latitude"
  //                   ).Value
  //                 : device.Data.find(
  //                     (data) => data.Parameter === "rxLatitude"
  //                   ).Value
  //             }&lon=${
  //               device.Data.find((data) => data.Parameter === "Latitude")
  //                 .Value !==
  //               device.Data.find((data) => data.Parameter === "Longitude")
  //                 .Value
  //                 ? device.Data.find(
  //                     (data) => data.Parameter === "Longitude"
  //                   ).Value
  //                 : device.Data.find(
  //                     (data) => data.Parameter === "rxLongitude"
  //                   ).Value
  //             }&appid=${process.env.REACT_APP_API_WEATHER_API_KEY}`
  //           );
  //           weatherData = weatherRequest.data;
  //           setOverallWeather(weatherData)
  //           console.log("first",weatherData)
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       });
  //   }
  // };

  // useEffect(() => {
  //   // loadWeather()
  //   // setWeatherDevice();
  //   // loadMapWithWeather();
  // }, [combineApiDataForWether]);

  const setValue = (value) => {
    return value;
  };

  // const getRowData = (rawData, parameter) => {
  //   let outputData = [];
  //   for (let i = 0; i < rawData.length; i++) {
  //     outputData = [...outputData, rawData[i][parameter]];
  //   }
  //   return outputData;
  // };

  const getRowDataTime = (rawData) => {
    let outputData = [];
    for (let i = 0; i < rawData.length; i++) {
      outputData = [
        ...outputData,
        frequency > "86400"
          ? moment.unix(rawData[i].Time).format("DD MMM")
          : moment.unix(rawData[i].Time).format("HH:mm"),
      ];
    }
    return outputData;
  };

  const getColorsByValue = (value, target, colors, time) => {
    if (time < moment().unix() - 3600) {
      return "gray";
    }
    const result = (value / target).toFixed(2);
    console.log(result);
    if (result >= 0.75) {
      return colors[3];
    } else if (result >= 0.5) {
      return colors[2];
    } else if (result >= 0.25) {
      return colors[1];
    } else {
      return colors[0];
    }
  };

  const handleAlarmAck = (ack, deviceToken, parameter) => {
    if (ack) {
      let requestObject = {
        DeviceToken: deviceToken,
        Time: moment().unix(),
        Info: {
          [parameter]: "true",
        },
      };
      dispatch(acknowledgeAlarm(requestObject, props.match.params.value));
    }
  };

  // dashboard config codes
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);

  const handleSettingsDrawerOpen = () => {
    setSettingsDrawerOpen(true);
  };
  const handleSettingsDrawerClose = () => {
    setSettingsDrawerOpen(false);
  };

  const groupInfo = useSelector(({ dashboard }) => dashboard.groupInfo);

  // weather device change
  const [weatherDeviceToken, setWeatherDeviceToken] = useState(0);
  const [weatherDeviceError, setWeatherDeviceTokenError] = useState("");
  const [weatherDeviceDrawerOpen, setWeatherDeviceDrawerOpen] = useState(false);

  const handleWeatherDeviceDrawerOpen = () => {
    if (!loading) setWeatherDeviceDrawerOpen(true);
  };
  const handleWeatherDeviceDrawerClose = () => {
    setWeatherDeviceDrawerOpen(false);
  };

  const handleWeatherDeviceSave = () => {
    if (weatherDeviceToken === 0) {
      setWeatherDeviceTokenError("Select a device");
      return;
    }
    dispatch(updateWeatherDevice(weatherDeviceToken, props.match.params.value));
  };

  const setWeatherDevice = () => {
    if (groupDetailsAndData && groupInfo) {
      if (groupInfo.weatherDeviceToken) {
        setWeatherDeviceToken(groupInfo.weatherDeviceToken);
      } else {
      }
    }
  };

  const setBoxProfileName = (name) => {};

  const setParameterName = (name) => {
    if (name !== null) {
      if (name === "Bat" || name === "BatV") {
        return "Battery";
      } else if (name === "water_SOIL") {
        return "Soil Moisture";
      } else if (name === "temp_SOIL" || name === "TEMP_SOIL") {
        return "Soil Temperature";
      } else if (name === "conduct_SOIL") {
        return "Soil Conductivity";
      } else if (name === "Distance") {
        return "Depth";
      } else if (name === "TempC_DS") {
        return "Temperature";
      } else if (name === "Hum_SHT") {
        return "Humidity";
      } else if (name === "Leaf_Moisture") {
        return "Lead Moisture";
      } else if (name === "Leaf_Temperature") {
        return "Leaf Temperature";
      } else if (name === "PH1_SOIL") {
        return "Soil PH Value";
      } else {
        return name;
      }
    } else {
      return null;
    }
  };

  const setGurgeColor = (name) => {
    if (name !== null) {
      if (name === "Bat" || name === "BatV") {
        return [
          [0.25, "#FF0000"],
          [0.75, "#F5772A"],
          [1, "#4DFF00"],
        ];
      } else {
        return [
          [0.25, "#4DFF00"],
          [0.75, "#F5772A"],
          [1, "#FF0000"],
        ];
      }
    } else {
      return [
        [0.25, "#4DFF00"],
        [0.75, "#F5772A"],
        [1, "#FF0000"],
      ];
    }
  };

  // console.log("data", data);
  // console.log("groupDetailState",groupDetailState)
  // console.log("latestGroupState",latestGroupState)
  // console.log("rowData",rowData)

  return (
    <>
      {overallWeather && (
        <Paper className={classes.weatherUpdate}>
          <Typography variant="body1">Weather Update</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <img src={`http://openweathermap.org/img/wn/${overallWeather?.weather[0]?.icon}@2x.png`} height="50" />
              <p style={{ color: "gray" }}>{overallWeather.weather[0].main}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ margin: 0, padding: 0 }}>Temperature: {overallWeather.main.temp} °C</p>
              <p style={{ margin: 0, padding: 0 }}>Humidity: {overallWeather.main.humidity} %</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ margin: 0, padding: 0 }}>Wind Speed: {overallWeather.wind.speed} M/s</p>
              <p style={{ margin: 0, padding: 0 }}>Wind Direction: {overallWeather.wind.deg}°</p>
            </div>
          </div>
        </Paper>
      )}
      <Paper className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "#939799",
          }}
        >
          {/* <Typography variant="subtitle2">
            Last Update: {moment().to(lastUpdatedTime)}
          </Typography> */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // label="Time"
              value={frequency}
              onChange={(event) => setFrequency(event.target.value)}
              className={classes.forminput}
              InputProps={{
                className: classes.forminput,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            >
              {timeList.map((item) => (
                <MenuItem key={item.id} value={item.seconds}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {data?.map((d, i) => (
          <Box
            style={{ border: "1px solid grey", margin: "10px 0px 10px 0px" }}
            key={i}
          >
            <Grid
              xs={12}
              container
              direction="column"
              alignItems="center"
              style={{ margin: "10px 0px 40px 0px" }}
            >
              <Typography style={{ fontSize: "25px" }}>{d.name}</Typography>
            </Grid>
            <Grid container style={{ justifyContent: "space-around" }}>
              {d?.data?.map((d, i) => (
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={3}
                  container
                  direction="column"
                  alignItems="center"
                  style={{ padding: 5 }}
                  key={i}
                >
                  {d.stateValue ? (
                    d.stateValue == "Alarmed" ? (
                      <Chip
                        variant="default"
                        size="medium"
                        label="Alarm"
                        icon={
                          <NotificationImportantIcon
                            style={{ color: "#fff" }}
                          />
                        }
                        style={{
                          backgroundColor: "red",
                          color: "#fff",
                          margin: "10px 0px 30px 0px",
                        }}
                      />
                    ) : (
                      <Typography
                        style={{ margin: "10px 0px 62px 0px" }}
                      ></Typography>
                    )
                  ) : (
                    <Typography
                      style={{ margin: "10px 0px 62px 0px" }}
                    ></Typography>
                  )}

                  <Gauge
                    name={setParameterName(d.Name)}
                    value={d.Value}
                    colorConfig={setGurgeColor(d.Name)}
                    paramIcon={""}
                  />
                  <LineChart
                    name={setParameterName(d.Name)}
                    paramIcon={""}
                    yAxisData={d.linechartData}
                    xAxisData={d.lineChartTime}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Paper>
    </>
  );

  // return (
  // <div>
  //     <Fab size="medium" aria-label="add" className={classes.settingButton} onClick={handleSettingsDrawerOpen} disabled={loading}>
  //       <Settings />
  //     </Fab>
  //     <Modal
  //       aria-labelledby="transition-modal-title"
  //       aria-describedby="transition-modal-description"
  //       className={classes.modal}
  //       open={settingsDrawerOpen}
  //       onClose={handleSettingsDrawerClose}
  //       closeAfterTransition
  //       BackdropComponent={Backdrop}
  //       BackdropProps={{
  //         timeout: 500,
  //       }}
  //     >
  //       <Fade in={settingsDrawerOpen}>
  //         <DashboardConfigComponent info={groupInfo} groupToken={props.match.params.value} deviceList={(groupDetailsAndData)? groupDetailsAndData: []} />
  //       </Fade>
  //     </Modal>
  //     <div style={{ display: 'flex', justifyContent: 'space-between', color: '#939799' }}>
  //         <Typography variant="subtitle2">Last Update: {moment().to(lastUpdatedTime)}</Typography>
  //         <FormControl variant="outlined" className={classes.formControl} size="small">
  //           {/* <InputLabel id="demo-simple-select-outlined-label">Time</InputLabel> */}
  //           <Select
  //             labelId="demo-simple-select-outlined-label"
  //             id="demo-simple-select-outlined"
  //             // label="Time"
  //             value={frequency}
  //             onChange={event => setFrequency(event.target.value)}
  //             className={classes.forminput}
  //             InputProps={{
  //               className: classes.forminput,
  //               classes: {
  //                   notchedOutline: classes.notchedOutline,
  //               },
  //           }}
  //           >
  //             {timeList.map(item => (
  //               <MenuItem key={item.id} value={item.seconds}>{item.label}</MenuItem>
  //             ))}
  //           </Select>
  //         </FormControl>
  //     </div>
  {
    /* {overallWeather && (
              <Paper className={classes.weatherUpdate}>
                <Typography variant="body1">Weather Update</Typography>
                <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src={`http://openweathermap.org/img/wn/${overallWeather.weather[0].icon}@2x.png`} height="50" />
                    <p style={{color: 'gray'}}>{overallWeather.weather[0].main}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p style={{margin: 0, padding: 0}}>Temperature: {overallWeather.main.temp}°C</p>
                    <p style={{margin: 0, padding: 0}}>Humidity: {overallWeather.main.humidity}%</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p style={{margin: 0, padding: 0}}>Wind Speed: {overallWeather.wind.speed}M/s</p>
                    <p style={{margin: 0, padding: 0}}>Wind Direction: {overallWeather.wind.deg}°</p>
                  </div>
                </div>
                <div style={{position: 'absolute', top: 5, right: 5}}>
                  <Settings style={{cursor: 'pointer'}} onClick={handleWeatherDeviceDrawerOpen} />
                </div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={weatherDeviceDrawerOpen}
                  onClose={handleWeatherDeviceDrawerClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={weatherDeviceDrawerOpen}>
                    <Paper className={classes.weatherDeviceContainer}>
                      <Typography>Weather Device</Typography>
                      <div className={classes.formContainer}>
                        <div className={classes.formControl}>
                            <TextField
                                label="Device" variant="outlined" size="small" fullWidth
                                name="weatherDeviceToken"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        root: classes.cssLabel,

                                    }
                                }}
                                InputProps={{
                                    className: classes.forminput,
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                value={weatherDeviceToken}
                                onChange={event => {setWeatherDeviceToken(event.target.value); setWeatherDeviceTokenError('')}}
                                helperText={weatherDeviceError}
                                error={(weatherDeviceError.length > 0)}
                            >
                                <MenuItem value={0}>Select device</MenuItem>
                                {groupDetailsAndData.filter(device => device.Profile === 'ESGPSPendant').map(device => (
                                    <MenuItem key={device.Token} value={device.Token}>{device.Token}</MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Button onClick={handleWeatherDeviceSave} fullWidth style={{marginTop: '1rem'}}>Save</Button>
                      </div>
                    </Paper>
                  </Fade>
                </Modal>
              </Paper>
            )} */
  }
  {
    /* {(loading) ? (<div style={{width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></div>) : ''} */
  }
  {
    /* {(!loading && groupDetailsAndData) ? (
                <>
                    <div className={classes.row}>
                        <Paper className={classes.mapContainer}>
                            <Typography variant="h6">Map</Typography>
                            <div ref={mapElement} className="mapDiv" style={{width: '100%', height: '95%', margin: 'auto'}}></div>
                        </Paper>
                        {groupDetailsAndData.filter(item => item.Token === 'GPS-Pendant-05').map(item => (
                          <Paper className={classes.commonContainer}>
                            <Typography variant="h6">{item.Name}</Typography>
                            <div className={classes.chartContainer} style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                              <FormControlLabel
                                control={<IOSSwitch checked={item.data.Data.find(d => d.Parameter === 'LON').Value === 'ON'} onChange={() => {}} name={item.Token} />}
                              />
                            </div>
                          </Paper>
                        ))}
                        {groupDetailsAndData.filter(item => item.Profile === 'ESSoilMoisture').map(item => (
                            <Paper className={classes.commonContainer}>
                                <Typography variant="h6">{item.Name}</Typography>
                                <div className={classes.chartContainer}>
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'water_SOIL')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'water_SOIL') && (item.state.StateMachines.find(s => s.Name === 'water_SOIL').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'water_SOIL').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'water_SOIL').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'water_SOIL').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'water_SOIL').State.toLowerCase() === 'alarmed'), item.Token, 'water_SOIL_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                    {
                                                        series: [
                                                          {
                                                            name: 'Water Percentage',
                                                            type: 'gauge',
                                                            radius: '100%',
                                                            progress: {
                                                              show: true,
                                                              width: 18
                                                            },
                                                            axisLine: {
                                                              lineStyle: {
                                                                width: 18
                                                              }
                                                            },
                                                      
                                                            itemStyle: {
                                                              color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'water_SOIL').Value, 100, ['#FA6400', '#FFEE52', '#96D98D', '#8AB8FF'], item.data.Data.find(data => data.Parameter === 'water_SOIL').Time)
                                                            },
                                                            splitLine: {
                                                              show: false
                                                            },
                                                            axisLabel: {
                                                              show: true,
                                                              color: '#969696',
                                                              fontSize: 8
                                                            },
                                                            pointer: {
                                                              show: false
                                                            },
                                                            detail: {
                                                              valueAnimation: true,
                                                              formatter: '{value}',
                                                      
                                                              color: '#969696',
                                                              offsetCenter: [0, '5%'],
                                                              fontSize: 30,
                                                              fontWeight: 'bolder'
                                                            },
                                                            data: [
                                                              {
                                                                value: setValue(item.data.Data.find(data => data.Parameter === 'water_SOIL').Value),
                                                                name: 'Water Percentage',
                                                                title: {
                                                                  offsetCenter: ['0%', '90%'],
                                                                  fontSize: 24,
                                                                  color: '#838F9C'
                                                                }
                                                              },
                                                              {
                                                                value: setValue(item.data.Data.find(data => data.Parameter === 'water_SOIL').Value),
                                                                name: '%',
                                                                title: {
                                                                  fontSize: 20,
                                                                   color: '#969696',
                                                                   fontWeight: 'bolder',
                                                                    offsetCenter: ['0%', '30%']
                                                                }
                                                               
                                                                }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                            }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value}%'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                            data: getRowData(item.rawData, 'water_SOIL'),
                                                            type: 'line',
                                                            itemStyle: {
                                                                color: '#2276FC'
                                                            },
                                                            areaStyle: {
                                                              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                                {
                                                                  offset: 0,
                                                                  color: 'rgba(34, 118, 252,0.5)'
                                                                },
                                                                {
                                                                  offset: 1,
                                                                  color: 'rgba(34, 118, 252,0.0)'
                                                                }
                                                              ])
                                                            },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'conduct_SOIL')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'conduct_SOIL') && (item.state.StateMachines.find(s => s.Name === 'conduct_SOIL').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'conduct_SOIL').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'conduct_SOIL').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'conduct_SOIL').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'conduct_SOIL').State.toLowerCase() === 'alarmed'), item.Token, 'conduct_SOIL_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Conductivity',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 600,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'conduct_SOIL').Value, 600, ['#FA6400', '#FFEE52', '#96D98D', '#8AB8FF'], item.data.Data.find(data => data.Parameter === 'conduct_SOIL').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'conduct_SOIL').Value),
                                                            name: 'Conductivity',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'conduct_SOIL').Value),
                                                            name: 'mS/m',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} mS/m'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'conduct_SOIL'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'temp_SOIL')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'temp_SOIL') && (item.state.StateMachines.find(s => s.Name === 'temp_SOIL').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'temp_SOIL').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'temp_SOIL').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'temp_SOIL').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'temp_SOIL').State.toLowerCase() === 'alarmed'), item.Token, 'temp_SOIL_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Temperature',
                                                        type: 'gauge',
                                                        min: -10,
                                                        max: 50,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'temp_SOIL').Value, 50, ['#1F60C4', '#56A64B', '#F2CC0C', '#E02F44'], item.data.Data.find(data => data.Parameter === 'temp_SOIL').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}°',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'temp_SOIL').Value),
                                                            name: 'Temperature',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'temp_SOIL').Value),
                                                            name: 'C',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} °C'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'temp_SOIL'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'Bat')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'Bat') && (item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'Bat').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'alarmed'), item.Token, 'Bat_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                    {
                                                        series: [
                                                          {
                                                            name: 'Battery',
                                                            type: 'gauge',
                                                            min: 0,
                                                            max: 5,
                                                            radius: '100%',
                                                            progress: {
                                                              show: true,
                                                              width: 18
                                                            },
                                                            axisLine: {
                                                              lineStyle: {
                                                                width: 18
                                                              }
                                                            },
                                                      
                                                            itemStyle: {
                                                              color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'Bat').Value, 5, ['#C4162A', '#FF780A', '#E1D24B', '#56A64B'], item.data.Data.find(data => data.Parameter === 'Bat').Time)
                                                            },
                                                            splitLine: {
                                                              show: false
                                                            },
                                                            axisLabel: {
                                                              show: true,
                                                              color: '#969696',
                                                              fontSize: 8
                                                            },
                                                            pointer: {
                                                              show: false
                                                            },
                                                            detail: {
                                                              valueAnimation: true,
                                                              formatter: '{value}',
                                                      
                                                              color: '#969696',
                                                              offsetCenter: [0, '5%'],
                                                              fontSize: 30,
                                                              fontWeight: 'bolder'
                                                            },
                                                            data: [
                                                              {
                                                                value: setValue(item.data.Data.find(data => data.Parameter === 'Bat').Value),
                                                                name: 'Battery',
                                                                title: {
                                                                  offsetCenter: ['0%', '90%'],
                                                                  fontSize: 24,
                                                                  color: '#838F9C'
                                                                }
                                                              },
                                                              {
                                                                value: setValue(item.data.Data.find(data => data.Parameter === 'Bat').Value),
                                                                name: 'V',
                                                                title: {
                                                                    fontSize: 20,
                                                                    color: '#969696',
                                                                    fontWeight: 'bolder',
                                                                    offsetCenter: ['0%', '30%']
                                                                }
                                                               }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} V'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'Bat'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        ))}                        
                        {groupDetailsAndData.filter(item => item.Profile === 'ESTempHumidiy').map(item => (
                            <Paper className={classes.commonContainer} key={item.Token}>
                                <Typography variant="h6">{item.Name}</Typography>
                                <div className={classes.chartContainer}>
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'Hum_SHT')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'Hum_SHT') && (item.state.StateMachines.find(s => s.Name === 'Hum_SHT').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'Hum_SHT').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'Hum_SHT').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'Hum_SHT').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'Hum_SHT').State.toLowerCase() === 'alarmed'), item.Token, 'Hum_SHT_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Humidity',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 100,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'Hum_SHT').Value, 100, ['#FA6400', '#E5D64C', '#96D98D', '#8AB8FF'], item.data.Data.find(data => data.Parameter === 'Hum_SHT').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Hum_SHT').Value),
                                                            name: 'Humidity',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Hum_SHT').Value),
                                                            name: '%',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value}%'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'Hum_SHT'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'TempC_DS')) && ( 
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'TempC_DS') && (item.state.StateMachines.find(s => s.Name === 'TempC_DS').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'TempC_DS').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'TempC_DS').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'TempC_DS').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'TempC_DS').State.toLowerCase() === 'alarmed'), item.Token, 'TempC_DS_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Temperature',
                                                        type: 'gauge',
                                                        min: -10,
                                                        max: 50,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'TempC_DS').Value, 50, ['#1F60C4', '#56A64B', '#F2CC0C', '#E02F44'], item.data.Data.find(data => data.Parameter === 'TempC_DS').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}°',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'TempC_DS').Value),
                                                            name: 'Temperature',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'TempC_DS').Value),
                                                            name: 'C',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} °C'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'TempC_DS'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'BatV')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'BatV') && (item.state.StateMachines.find(s => s.Name === 'BatV').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'BatV').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'BatV').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'BatV').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'BatV').State.toLowerCase() === 'alarmed'), item.Token, 'BatV_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Battery',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 5,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'BatV').Value, 5, ['#C4162A', '#FF780A', '#FFEE52', '#56A64B'], item.data.Data.find(data => data.Parameter === 'BatV').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'BatV').Value),
                                                            name: 'Battery',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'BatV').Value),
                                                            name: 'V',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} V'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'BatV'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        ))}  

                        {groupDetailsAndData.filter(item => item.Profile === 'ESLEAFSensor').map(item => (
                            <Paper className={classes.commonContainer}>
                                <Typography variant="h6">{item.Name}</Typography>
                                <div className={classes.chartContainer}>
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'Leaf_Moisture')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'Leaf_Moisture') && (item.state.StateMachines.find(s => s.Name === 'Leaf_Moisture').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'Leaf_Moisture').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'Leaf_Moisture').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'Leaf_Moisture').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'Leaf_Moisture').State.toLowerCase() === 'alarmed'), item.Token, 'Leaf_Moisture_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Leaf Moisture',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 100,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'Leaf_Moisture').Value, 100, ['#EA5E02', '#FFEE52', '#96D98D', '#8AB8FF'], item.data.Data.find(data => data.Parameter === 'Leaf_Moisture').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Leaf_Moisture').Value),
                                                            name: 'Leaf Moisture',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Leaf_Moisture').Value),
                                                            name: '%',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value}%'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'Leaf_Moisture'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'Leaf_Temperature')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'Leaf_Temperature') && (item.state.StateMachines.find(s => s.Name === 'Leaf_Temperature').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'Leaf_Temperature').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'Leaf_Temperature').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'Leaf_Temperature').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'Leaf_Temperature').State.toLowerCase() === 'alarmed'), item.Token, 'Leaf_Temperature_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Temperature',
                                                        type: 'gauge',
                                                        min: -10,
                                                        max: 50,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'Leaf_Temperature').Value, 50, ['#1F60C4', '#56A64B', '#F2CC0C', '#E02F44'], item.data.Data.find(data => data.Parameter === 'Leaf_Temperature').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}°',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Leaf_Temperature').Value),
                                                            name: 'Temperature',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Leaf_Temperature').Value),
                                                            name: 'C',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} °C'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'Leaf_Temperature'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'Bat')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'Bat') && (item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'Bat').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'alarmed'), item.Token, 'Bat_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Battery',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 5,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'Bat').Value, 5, ['#BC1629', '#FF780A', '#FFEE52', '#56A64B'], item.data.Data.find(data => data.Parameter === 'Bat').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Bat').Value),
                                                            name: 'Battery',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Bat').Value),
                                                            name: 'V',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} V'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'Bat'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        ))}   

                        {groupDetailsAndData.filter(item => item.Profile === 'ESPHSensor').map(item => (
                            <Paper className={classes.commonContainer}>
                                <Typography variant="h6">{item.Name}</Typography>
                                <div className={classes.chartContainer}>
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'PH1_SOIL')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'PH1_SOIL') && (item.state.StateMachines.find(s => s.Name === 'PH1_SOIL').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'PH1_SOIL').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'PH1_SOIL').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'PH1_SOIL').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'PH1_SOIL').State.toLowerCase() === 'alarmed'), item.Token, 'PH1_SOIL_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'pH Value',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 14,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'PH1_SOIL').Value, 14, ['#BE2A3D', '#F2CC0C', '#96D98D', '#37872D'], item.data.Data.find(data => data.Parameter === 'PH1_SOIL').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'PH1_SOIL').Value),
                                                            name: 'pH Value',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'PH1_SOIL').Value),
                                                            name: 'pH',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} pH'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'PH1_SOIL'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'TEMP_SOIL')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'TEMP_SOIL') && (item.state.StateMachines.find(s => s.Name === 'TEMP_SOIL').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'TEMP_SOIL').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'TEMP_SOIL').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'TEMP_SOIL').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'TEMP_SOIL').State.toLowerCase() === 'alarmed'), item.Token, 'TEMP_SOIL_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Temperature',
                                                        type: 'gauge',
                                                        min: -10,
                                                        max: 50,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'TEMP_SOIL').Value, 50, ['#1F60C4', '#56A64B', '#F2CC0C', '#CE2C40'], item.data.Data.find(data => data.Parameter === 'TEMP_SOIL').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}°',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'TEMP_SOIL').Value),
                                                            name: 'Temperature',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'TEMP_SOIL').Value),
                                                            name: 'C',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                            }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} °C'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'TEMP_SOIL'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                    {(item.data && item.data.Data && item.data.Data.find(data => data.Parameter === 'Bat')) && (
                                        <div className={classes.chartItem}>
                                            <div className={classes.alarmContainer}>
                                                {item.state && item.state.StateMachines && item.state.StateMachines.find(s => s.Name === 'Bat') && (item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'alarmed' || item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'acknowledged') && (    
                                                    <Chip icon={<Notifications />} 
                                                    className={(item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'acknowledged')? classes.warningChip: classes.errorChip} 
                                                    label={item.state.StateMachines.find(s => s.Name === 'Bat').Description} 
                                                    onClick={() => {
                                                      handleAlarmAck((item.state.StateMachines.find(s => s.Name === 'Bat').State.toLowerCase() === 'alarmed'), item.Token, 'Bat_ack');
                                                    }} />
                                                )}
                                            </div>
                                            <ReactEcharts
                                                style={{height: '250px'}}
                                                option={
                                                {
                                                    series: [
                                                      {
                                                        name: 'Battery',
                                                        type: 'gauge',
                                                        min: 0,
                                                        max: 5,
                                                        radius: '100%',
                                                        progress: {
                                                          show: true,
                                                          width: 18
                                                        },
                                                        axisLine: {
                                                          lineStyle: {
                                                            width: 18
                                                          }
                                                        },
                                                  
                                                        itemStyle: {
                                                          color: getColorsByValue(item.data.Data.find(data => data.Parameter === 'Bat').Value, 5, ['#C4162A', '#FF780A', '#F4E34F', '#56A64B'], item.data.Data.find(data => data.Parameter === 'Bat').Time)
                                                        },
                                                        splitLine: {
                                                          show: false
                                                        },
                                                        axisLabel: {
                                                          show: true,
                                                          color: '#969696',
                                                          fontSize: 8
                                                        },
                                                        pointer: {
                                                          show: false
                                                        },
                                                        detail: {
                                                          valueAnimation: true,
                                                          formatter: '{value}',
                                                  
                                                          color: '#969696',
                                                          offsetCenter: [0, '5%'],
                                                          fontSize: 30,
                                                          fontWeight: 'bolder'
                                                        },
                                                        data: [
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Bat').Value),
                                                            name: 'Battery',
                                                            title: {
                                                              offsetCenter: ['0%', '90%'],
                                                              fontSize: 24,
                                                              color: '#838F9C'
                                                            }
                                                          },
                                                          {
                                                            value: setValue(item.data.Data.find(data => data.Parameter === 'Bat').Value),
                                                            name: 'V',
                                                            title: {
                                                                fontSize: 20,
                                                                color: '#969696',
                                                                fontWeight: 'bolder',
                                                                offsetCenter: ['0%', '30%']
                                                            }
                                                           }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                }
                                            />
                                            <ReactEcharts
                                                style={{height: '160px'}}
                                                option={{
                                                    grid: {
                                                        containLabel: true,
                                                        left: '4%',
                                                        right: '4%',
                                                        bottom: '0%',
                                                        top: '5%'
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        data: getRowDataTime(item.rawData)
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        axisLabel: {
                                                            formatter: '{value} V'
                                                        },
                                                        splitLine: {
                                                          lineStyle: {
                                                            type: 'dashed',
                                                            color: '#757575'
                                                          }
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                        data: getRowData(item.rawData, 'Bat'),
                                                        type: 'line',
                                                        itemStyle: {
                                                            color: '#2276FC'
                                                        },
                                                        areaStyle: {
                                                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            {
                                                              offset: 0,
                                                              color: 'rgba(34, 118, 252,0.5)'
                                                            },
                                                            {
                                                              offset: 1,
                                                              color: 'rgba(34, 118, 252,0.0)'
                                                            }
                                                          ])
                                                        },
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        ))}                        
                    </div>
                </>
            ) : ''} */
  }
  // <Gauge></Gauge>
  // </div>
  // )
}

export default DashboardViewComponent;
