import * as ACTIONS from '../actions';
const initialState = {
    loading: true,
    devices: [],
    states: [],
    errorMessage: ''
};

const mapReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case ACTIONS.STOP_LOADING: {
            return {
                ...state,
                loading: false
            }
        }
        case ACTIONS.GET_DEVICES: {
            return {
                ...state,
                devices: action.payload,
            }
        }
        case ACTIONS.GET_STATES: {
            return {
                ...state,
                states: action.payload,
            }
        }
        case ACTIONS.ERROR_MESSAGE: {
            return {
                ...state,
                errorMessage: 'Network error! Please check your internet connection',
            }
        }
        case ACTIONS.RESET_MAPS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default mapReducer;