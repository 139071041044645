import axios from "axios";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_DEVICES = 'MAP_COMPONENT - GET_DEVICES';
export const GET_STATES = 'MAP_COMPONENT - GET_STATES';
export const GET_DETAILS = 'MAP_COMPONENT - GET_DETAILS';

export const START_LOADING = 'MAP_COMPONENT - START_LOADING';
export const STOP_LOADING = 'MAP_COMPONENT - STOP_LOADING';
export const ERROR_MESSAGE = 'MAP_COMPONENT - ERROR_MESSAGE';
export const RESET_MAPS = 'MAP_COMPONENT - RESET_MAPS';


export function getDevices() {

	return dispatch =>{

		axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/data/latest/group?tenant=ESSP&groupToken=ESSPLOCATION")
		.then(response => {
			dispatch(getStates());
			return dispatch({
				type: GET_DEVICES,
				payload: response.data.Data.Devices
			})
		}).catch(error => {
			console.log(error)
			dispatch(stopLoading());
			return dispatch({
				type: ERROR_MESSAGE,
			})
		});
	}
}

export function getStates() {

	return dispatch =>{

		axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/states/group?tenant=ESSP&groupToken=ESSPLOCATION")
		.then(response => {
			dispatch(stopLoading());
			return dispatch({
				type: GET_STATES,
				payload: response.data.Data.Devices
			})
		}).catch(error => {
			console.log(error)
			dispatch(stopLoading());
			return dispatch({
				type: ERROR_MESSAGE,
			})
		});
	}
}


export function startLoading(){
	return {
		type: START_LOADING
	}
}

export function stopLoading(){
	return {
		type: STOP_LOADING
	}
}
export function resetMaps(){
	return {
		type: RESET_MAPS
	}
}