import React from 'react';
import {
    makeStyles,
} from "@material-ui/core"
import logo from '../../assets/images/logo.png'
const LogoComponent = (props) => {
    const useStyles = makeStyles((theme) => ({
        logBackground: {
            width: props.drawerWidth,
            alignItems: "center"
        },
        logoClasses: {
            // marginLeft: 10,
            [theme.breakpoints.down("xs")]: {
                width: '220px'

            },
            [theme.breakpoints.between("sm", "md", '')]: {
                width: '250px'
            },
            "@media (min-width: 1280px)": {
                width: '300px'
            }
        },


    }))

    const classes = useStyles()
    return (
        <div className={classes.logBackground} >
            <img src={logo} className={classes.logoClasses} alt="Mass Industrial Solutions" />
        </div>
    )
}

export default LogoComponent