import React from 'react'
import UserComponent from '../Components/UserComponent/UserComponent'
import DashboardComponent from '../Components/DashboardComponent/DashboardComponent'
import {
    ADD_USER_ROUTE, EDIT_USER_ROUTE,
    DASHBOARD_ROUTE, DEVICE_ROUTE, ADD_DEVICE_ROUTE,
    USER_ROUTE, MY_ACCOUNT,
    DEVICE_ANALYSIS_REPORT,
    COMPANY, ADD_COMPANY, EDIT_COMPANY, ALARM_ROUTE, COMPANY_ADMIN_UPDATE, MAP_ROUTE, ALARM_VIEW_ROUTE, ALARM_ADD_ROUTE, LORAWAN_ROUTE, DASHBOARD_VIEW, DEVICE_EDIT
} from './PathConfig'
import AddUserComponent from '../Components/UserComponent/AddUserComponent/AddUserComponent'
import EditUserComponent from '../Components/UserComponent/EditUserComponent/EditUserComponent'
import DeviceComponent from '../Components/DeviceComponent/DeviceComponent'
import AddDeviceComponent from '../Components/DeviceComponent/AddDeviceComponent/AddDeviceComponent'
import ProtectedRoute from './ProtectedRoute'
import MyProfileComponent from '../Components/UserComponent/MyProfileComponent/MyProfileComponent'
import DeviceAnalysisComponent from '../Components/ReportsComponents/DeviceAnalysisComponent/DeviceAnalysisComponent'
import CompanyComponent from '../Components/CompanyComponent/CompanyComponent'
import AddCompanyComponent from '../Components/CompanyComponent/AddCompanyComponent/AddCompanyComponent'
import EditCompanyComponent from '../Components/CompanyComponent/EditCompanyComponent/EditCompanyComponent'
import { useSelector } from 'react-redux'
import AlarmComponent from '../Components/AlarmComponent/AlarmComponent'
import AdminProfileUpdateComponent from '../Components/LoginComponent/AdminProfileUpdateComponent/AdminProfileUpdateComponent'
import MapComponent from '../Components/MapComponent/MapComponent'
import AlarmViewComponent from '../Components/AlarmComponent/AlarmViewComponent'
import AddAlarmComponent from '../Components/AlarmComponent/AddAlarmComponent'
import LorawanComponent from '../Components/LorawanComponent/LorawanComponent'
import DashboardViewComponent from '../Components/DashboardComponent/DashboardViewComponent/DashboardViewComponent'
import EditDeviceComponent from '../Components/DeviceComponent/EditDeviceComponent/EditDeviceComponent'
function RouteComponent() {

    const userPermissions = useSelector(({ main }) => main.userPermissions);

    return (
        <>
            {/* <Route exact path={DASHBOARD_ROUTE} component={DashboardComponent} /> */}
            <ProtectedRoute path={DASHBOARD_ROUTE} component={DashboardComponent} isAuthorized={true} />
            <ProtectedRoute exact path={USER_ROUTE} component={UserComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1'))} />
            <ProtectedRoute exact path={ADD_USER_ROUTE} component={AddUserComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1#1'))} />
            <ProtectedRoute exact path={EDIT_USER_ROUTE} component={EditUserComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1#1'))} />
            <ProtectedRoute exact path={DEVICE_ROUTE} component={DeviceComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '2'))} />
            <ProtectedRoute exact path={ADD_DEVICE_ROUTE} component={AddDeviceComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '2#1'))} />
            <ProtectedRoute exact path={MY_ACCOUNT} component={MyProfileComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '2#2'))} />
            {/* <ProtectedRoute exact path={DEVICE_ANALYSIS_REPORT} component={DeviceAnalysisComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '3'))} /> */}
            <ProtectedRoute exact path={COMPANY} component={CompanyComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '3'))} />
            <ProtectedRoute exact path={ADD_COMPANY} component={AddCompanyComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '3'))} />
            <ProtectedRoute exact path={EDIT_COMPANY} component={EditCompanyComponent} isAuthorized={(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '3'))} />
            <ProtectedRoute exact path={ALARM_ROUTE} component={AlarmComponent} isAuthorized={true} />
            <ProtectedRoute exact path={COMPANY_ADMIN_UPDATE} component={AdminProfileUpdateComponent} isAuthorized={true} />
            <ProtectedRoute exact path={MAP_ROUTE} component={MapComponent} isAuthorized={true} />
            <ProtectedRoute exact path={ALARM_VIEW_ROUTE} component={AlarmViewComponent} isAuthorized={true} />
            <ProtectedRoute exact path={ALARM_ADD_ROUTE} component={AddAlarmComponent} isAuthorized={true} />
            <ProtectedRoute exact path={LORAWAN_ROUTE} component={LorawanComponent} isAuthorized={true} />
            <ProtectedRoute exact path={DASHBOARD_VIEW} component={DashboardViewComponent} isAuthorized={true} />
            <ProtectedRoute exact path={DEVICE_EDIT} component={EditDeviceComponent} isAuthorized={true} />
        </>
    )
}

export default RouteComponent
