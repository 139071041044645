import axios from 'axios';
import React, { useEffect } from 'react';

function LorawanComponent(props) {

    const redirect = () => {
        window.location = 'http://110.142.251.42:8000/#/';
    }

    const loginHandle = () => {
        // axios.post('http://110.142.251.42/api/internal/login', {
        //     email: 'admin',
        //     password: '1qaz2wsx@'
        // }).then(response => {
        //     console.log(response)
        // })
        redirect();
    }

    useEffect(() => {
        loginHandle();
    }, [])

    return (
        <div>
            
        </div>
    );
}

export default LorawanComponent;