import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";

import AddCompanyConfirmation from "../AddCompanyConfirmation/AddCompanyConfirmation";
import { useDispatch, useSelector } from "react-redux";
import ReactPhoneInput from "react-phone-input-material-ui";
// import { addDeviceFormResetToFalse, getDevices, getUsers } from '../store/actions';
import {
  firstNamePattern,
  emailPattern,
  contactPattern,
} from "../../../Config/RegExp";
import { addCompanyFormResetToFalse } from "../store/actions";
import { useCookies } from "react-cookie";

function AddCompanyComponent(props) {
  const dispatch = useDispatch();

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );
  
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      padding: "20px",
      // backgroundColor: theme.palette.paper.main
    },
    text: {
      // color: theme.palette.Breadcrumb.text,
      marginTop: "20px",
    },
    formControl: {
      margin: "1em 0",

      "& .special-label": {
        background: "#ffffff",
        color:'#868686'
      },
    },
    formControlDark: {
      margin: "1em 0",
      
      "& .special-label": {
        background: "#424242",
      },
    },
    cssLabel: {
      // // color: theme.palette.input.label,
      "&.Mui-focused": {
        // // color: theme.palette.input.labelClick,
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    Accordion: {
      backgroundColor: theme.palette.secondary.main,
    },

    input: {
      width: "90%",
      padding: 2,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.primary.main,
      margin: "20px 0",
    },
    forminput: {
      // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
      borderWidth: "1px",
      // borderColor: theme.palette.input.borderColor
    },
    employeeCard: {
      border: "none",
    },
    inputField: {
      margin: "1em 0",
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.inputBoxSecond.color
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.inputBoxSecond.color
          
      },"& .MuiInputLabel-root.Mui-focused": {
          color: theme.palette.inputBoxSecond.color
      },
    },
    backcolor: "red",
    heading: {
      "& .special-label": {
        background: "red",
      },
    },
    cancelButton: {
      backgroundColor: "red",
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();

  const [cookies] = useCookies(["theme"]);
  const mode = cookies.theme;

  let phoneNoColor = "#ffffff";
  let phoneNoLabelColor = "#737373";

  if (mode === "Dark") {
    phoneNoColor = "#424242";
    phoneNoLabelColor = "#fafafa";
  }

  const defaultFormData = {
    companyID: "",
    companyName: "",
    // companyCode: '',
    companyEmail: "",
    contactNo1: "",
    contactNo2: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    countryId: 13,
    companyAdminName: "",
    companyAdminEmail: "",
  };

  const defaultFormErrors = {
    companyID: "",
    companyName: "",
    companyCode: "",
    companyEmail: "",
    contactNo1: "",
    contactNo2: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    countryId: "",
    companyAdminName: "",
    companyAdminEmail: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const handleForm = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const validateFormData = () => {
    const errors = defaultFormErrors;
    let isHaveErrors = false;
    
    if (formData.companyName === "") {
      errors.companyName = "Company Name is not valid";
      isHaveErrors = true;
    }

    if (
      formData.companyEmail === "" ||
      !emailPattern.test(formData.companyEmail)
    ) {
      errors.companyEmail = "Company Email is not valid";
      isHaveErrors = true;
    }
    if (formData.contactNo1 === "" || formData.contactNo1.length < 5) {
      errors.contactNo1 = "Company Contact is not valid";
      isHaveErrors = true;
    }
    if (
      formData.companyAdminName === "" ||
      !firstNamePattern.test(formData.companyAdminName)
    ) {
      errors.companyAdminName = "Company Admin Name is not valid";
      isHaveErrors = true;
    }
    if (
      formData.companyAdminEmail === "" ||
      !emailPattern.test(formData.companyAdminEmail)
    ) {
      errors.companyAdminEmail = "Company Admin Email is not valid";
      isHaveErrors = true;
    }
    setFormErrors(errors);
    return isHaveErrors;
  };
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const handleConfirmModalOpen = () => {
    if (!validateFormData()) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const resetForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultFormErrors);
  };

  const addCompanyFormReset = useSelector(
    ({ company }) => company.addCompanyFormReset
  );

  useEffect(() => {
    if (addCompanyFormReset) {
      resetForm();
      dispatch(addCompanyFormResetToFalse());
      handleConfirmModalClose();
    }
  }, [addCompanyFormReset, dispatch]);

  return (
    <>
      <Paper className={classes.container}>
        <Typography
          variant="h5"
          style={{ margin: "6px 8px" }}
          className={classes.heading}
        >
          Add Organization
        </Typography>
        <form>
          <Grid container>
            <Grid sm={12} md={5} style={{ margin: "0 auto" }}>
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                align="left"
                className={classes.text}
              >
                Organization Details
              </Typography>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyName"
                    onChange={handleForm}
                    helperText={formErrors.companyName}
                    value={formData.companyName}
                    error={formErrors.companyName.length > 0}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyEmail"
                    onChange={handleForm}
                    helperText={formErrors.companyEmail}
                    value={formData.companyEmail}
                    error={formErrors.companyEmail.length > 0}
                  />
                </div>
              </FormGroup>

              <Grid container justify="space-between">
                <Grid item md={5} sm={11}>
                  <div
                    className={
                      mode === "Dark"
                        ? classes.formControlDark
                        : classes.formControl
                    }
                  >
                    <ReactPhoneInput
                      name="contactNo1"
                      label="Contact No 1"
                      variant="outlined"
                      size="small"
                      inputStyle={{
                        width: "100%",
                        padding: "12px 25px 0px 58px",
                        backgroundColor: mode === "Dark"? "#424242":"#ffffff",
                        borderColor: mode === "Dark"? "#fafafa":"#737373",
                      }}
                      specialLabel="Contact No 1"
                      value={formData.contactNo1}
                      country="au"
                      onChange={(e) =>
                        setFormData((prev) => {
                          return {
                            ...prev,
                            contactNo1: e,
                          };
                        })
                      } // passed function receives the phone value
                      component={TextField}
                      helperText={formErrors.contactNo1}
                      error={formErrors.contactNo1.length > 0}
                    />
                    {formErrors.contactNo1.length > 0 && (
                      <FormHelperText error={true}>
                        {formErrors.contactNo1}
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
                <Grid item md={6} sm={11}>
                  <div
                    className={
                      mode === "Dark"
                        ? classes.formControlDark
                        : classes.formControl
                    }
                  >
                    <ReactPhoneInput
                      name="contactNo2"
                      label="Contact No 2"
                      variant="outlined"
                      size="small"
                      inputStyle={{
                        width: "100%",
                        padding: "12px 25px 0px 58px",
                        backgroundColor: mode === "Dark"? "#424242":"#ffffff",
                        borderColor: mode === "Dark"? "#fafafa":"#737373",
                      }}
                      value={formData.contactNo2}
                      specialLabel="Contact No 2"
                      country="au"
                      onChange={(e) =>
                        setFormData((prev) => {
                          return {
                            ...prev,
                            contactNo2: e,
                          };
                        })
                      } // passed function receives the phone value
                      component={TextField}
                    />
                  </div>
                </Grid>
              </Grid>

              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Add Line 1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="addressLine1"
                    onChange={handleForm}
                    helperText={formErrors.addressLine1}
                    value={formData.addressLine1}
                    error={formErrors.addressLine1.length > 0}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Add Line 2"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="addressLine2"
                    onChange={handleForm}
                    helperText={formErrors.addressLine2}
                    value={formData.addressLine2}
                    error={formErrors.addressLine2.length > 0}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Add Line 3"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="addressLine3"
                    onChange={handleForm}
                    helperText={formErrors.addressLine3}
                    value={formData.addressLine3}
                    error={formErrors.addressLine3.length > 0}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="City"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="city"
                    onChange={handleForm}
                    helperText={formErrors.city}
                    value={formData.city}
                    error={formErrors.city.length > 0}
                  />
                </div>
              </FormGroup>
            </Grid>
            <Grid sm={12} md={5} style={{ margin: "0 auto" }}>
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                align="left"
                className={classes.text}
              >
                Admin Details
              </Typography>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Admin User Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyAdminName"
                    onChange={handleForm}
                    helperText={formErrors.companyAdminName}
                    value={formData.companyAdminName}
                    error={formErrors.companyAdminName.length > 0}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className={classes.inputField}>
                  <TextField
                    label="Admin Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="companyAdminEmail"
                    onChange={handleForm}
                    helperText={formErrors.companyAdminEmail}
                    value={formData.companyAdminEmail}
                    error={formErrors.companyAdminEmail.length > 0}
                  />
                </div>
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={classes.cancelButton}
                  style={{ marginRight: "10px" }}
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  className={classes.addButton}
                  variant="contained"
                  onClick={handleConfirmModalOpen}
                  disabled={loggedUserRole.toLowerCase() === "admin" || loggedUserRole.toLowerCase() === "company admin" ? false:true}
                >
                  ADD Organization
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <AddCompanyConfirmation
        handleClose={handleConfirmModalClose}
        open={confirmModalOpen}
        formData={formData}
      />
    </>
  );
}

export default AddCompanyComponent;
