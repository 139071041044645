import customAxios from "../Services/customAxios/CustomAxios";
import moment from "moment";


export const getDevicesList = async (params) => {
    return customAxios.get(
      `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/device/list?tenant=ESSP`
    );
};

export const getDevicesListByOrganization = async (params) => {
  return customAxios.get(
    `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/device/list?tenant=ESSP`
  );
};

export const getGPSDevicesData = async (params) => {
    return customAxios.get(
      `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/data/latest/group?tenant=ESSP&groupToken=ESSPLOCATION`
    );
};  


export const getGPSDevicesState = async (params) => {
    return customAxios.get(
      `${process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL}/get/states/group?tenant=ESSP&groupToken=ESSPLOCATION`
    );
};  
