import * as ACTIONS from '../actions';
const initialState = {
    loading: true,
    devices: [],
    userList: [],
    loadUserProcessing: false,
    errorMessage: ''
};

const alarmReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case ACTIONS.STOP_LOADING: {
            return {
                ...state,
                loading: false
            }
        }
        case ACTIONS.GET_DEVICES: {
            return {
                ...state,
                devices: action.payload,
            }
        }
        case ACTIONS.GET_USERS_BY_COMPANY: {
            return {
                ...state,
                userList: action.payload,
            }
        }
        case ACTIONS.RESET_USER_LIST: {
            return {
                ...state,
                userList: [],
            }
        }
        case ACTIONS.LOAD_USER_PROCESSING: {
            return {
                ...state,
                loadUserProcessing: action.payload,
            }
        }
        case ACTIONS.ERROR_MESSAGE: {
            return {
                ...state,
                errorMessage: 'Network error! Please check your internet connection',
            }
        }
        case ACTIONS.RESET_ALARMS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default alarmReducer;