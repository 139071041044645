import axios from "axios";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_COMPANIES = 'COMPANY_COMPONENT - GET_COMPANIES';
export const GET_COMPANIES_ERROR = 'COMPANY_COMPONENT - GET_COMPANIES_ERROR';
// export const CLEAR_GET_USER_ERROR = 'USER_COMPONENT - CLEAR_GET_USER_ERROR';
// export const GET_DEVICES = 'USER_COMPONENT - GET_DEVICES';
// export const GET_USER_GROUPS = 'USER_COMPONENT - GET_USER_GROUPS';
export const ADD_COMPANY = 'COMPANY_COMPONENT - ADD_COMPANY';

export const START_LOADING = 'COMPANY_COMPONENT - START_LOADING';
export const STOP_LOADING = 'COMPANY_COMPONENT - STOP_LOADING';

export const ADD_COMPANY_PROCESSING = 'COMPANY_COMPONENT - ADD_COMPANY_PROCESSING';
export const ADD_COMPANY_FORM_RESET_FALSE = 'COMPANY_COMPONENT - ADD_COMPANY_FORM_RESET_FALSE';
export const ADD_COMPANY_ERROR_CLEAR = 'COMPANY_COMPONENT - ADD_COMPANY_ERROR_CLEAR';

export const SET_EDITING_COMPANY_ID = 'COMPANY_COMPONENT - SET_EDITING_COMPANY_ID';
export const GET_EDITING_COMPANY = 'COMPANY_COMPONENT - GET_EDITING_COMPANY';
export const REMOVE_EDITING_COMPANY = 'COMPANY_COMPONENT - REMOVE_EDITING_COMPANY';

// export const EDIT_USER = 'USER_COMPONENT - EDIT_USER';
// export const EDIT_USER_PROCESSING = 'USER_COMPONENT - EDIT_USER_PROCESSING';
// export const EDIT_USER_FORM_RESET_FALSE = 'USER_COMPONENT - EDIT_USER_FORM_RESET_FALSE';
// export const EDIT_USER_ERROR_CLEAR = 'USER_COMPONENT - EDIT_USER_ERROR_CLEAR';

// export const DELETE_USER = 'USER_COMPONENT - DELETE_USER';


export function getCompanies(page, rowsPerPage, searchText, sortParameter, sortType) {

	return dispatch =>{

        dispatch(startLoading());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + 
			`/company/getCompanyList?page=${page}&rowsPerPage=${rowsPerPage}&searchText=${searchText}&sortParameter=${sortParameter}&sortType=${sortType}`)
				.then(
					response => {
						dispatch(stopLoading());
						return dispatch({
							type: GET_COMPANIES,
							payload: response.data
						})
						
					}
				).catch(error => {
					dispatch(stopLoading());
					return dispatch({
						type: GET_COMPANIES_ERROR,
					})
				});
	}
}
export function startLoading(){
	return {
		type: START_LOADING
	}
}

export function stopLoading(){
	return {
		type: STOP_LOADING
	}
}

export function addCompany(formData) {

	return dispatch =>{

        dispatch(addCompanyProcessing());

		customAxios.post(process.env.REACT_APP_API_BASE_URL + "/company/addcompany", formData)
				.then(
					response => {
						dispatch(createCompanyGroup('ESSPERTH' + response.data.newuser.companyID, response.data.newuser.companyName))
					}
				).catch(error => {
					return dispatch({
						type: GET_COMPANIES_ERROR,
					})
				});
	}
}

export function editCompany(formData) {

	return dispatch =>{

        dispatch(addCompanyProcessing());

		customAxios.post(process.env.REACT_APP_API_BASE_URL + "/company/updatecompanydetails", formData)
				.then(
					response => {
						dispatch(removeEditingCompany());
					}
				).catch(error => {
					return dispatch({
						type: GET_COMPANIES_ERROR,
					})
				});
	}
}

export function createCompanyGroup(Token, Name) {

	return dispatch =>{

		axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/group/create?tenant=ESSP", {Token, Name})
				.then(
					response => {
						return dispatch({
							type: ADD_COMPANY,
							payload: response.data.Status
						})
						
					}
				).catch(error => {
					return dispatch({
						type: GET_COMPANIES_ERROR,
					})
				});
	}
}

export function addCompanyProcessing(){
	return {
		type: ADD_COMPANY_PROCESSING
	}
}
export function addCompanyFormResetToFalse(){
	return {
		type: ADD_COMPANY_FORM_RESET_FALSE
	}
}
export function addCompanyErrorClear(){
	return {
		type: ADD_COMPANY_ERROR_CLEAR
	}
}
// export function clearGetUserError(){
// 	return {
// 		type: CLEAR_GET_USER_ERROR
// 	}
// }
export function setEditingCompanyId(id){
	return {
		type: SET_EDITING_COMPANY_ID,
		payload: id
	}
}
export function getEditingCompany(companyId) {

	return dispatch =>{

        dispatch(addCompanyProcessing());

		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/company/getcompanydetails/" + companyId)
				.then(
					response => {
						return dispatch({
							type: GET_EDITING_COMPANY,
							payload: {...response.data, contactNo1: (response.data.contactNo1)? response.data.contactNo1: '', contactNo2: (response.data.contactNo2)? response.data.contactNo2: ''}
						})
						
					}
				).catch(error => {
					return dispatch({
						type: GET_COMPANIES_ERROR,
					})
				});
	}
}

export function deleteCompanyById(companyId, page, rowsPerPage, searchText, sortParameter, sortType) {

	return dispatch =>{

        dispatch(startLoading());

		customAxios.put(process.env.REACT_APP_API_BASE_URL + "/company/deletecompany/" + companyId)
				.then(
					response => {
						dispatch(getCompanies(page, rowsPerPage, searchText, sortParameter, sortType));
						axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/reg/group/delete?tenant=ESSP", {Token: 'ESSPERTH' + companyId})
						.then(
							response => {
							}
						);
					}
				).catch(error => {
					return dispatch({
						type: GET_COMPANIES_ERROR,
					})
				});
	}
}

export function removeEditingCompany(){
	return {
		type: REMOVE_EDITING_COMPANY
	}
}