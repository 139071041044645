import {
  Typography,
  Button,
  makeStyles,
  Grid,
  TextField,
  Paper,
  MenuItem,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import UserFormConfirmationComponent from "../UserFormConfirmationComponent/UserFormConfirmationComponent";
import { DeviceTileComponent } from "../../UIComponents/DeviceTileComponent/DeviceTileComponent";
import { useDispatch, useSelector } from "react-redux";

import {
  addUserFormResetToFalse,
  getCompanies,
  getDevices,
  getUserGroups,
} from "../store/actions";
import {
  firstNamePattern,
  lastNamePattern,
  emailPattern,
  contactPattern,
  designationPattern,
} from "../../../Config/RegExp";

// import 'react-phone-input-2/lib/style.css'

import "react-phone-input-2/lib/material.css";
import ReactPhoneInput from "react-phone-input-material-ui";
import { useCookies } from "react-cookie";
import Autocomplete from "@material-ui/lab/Autocomplete";
function AddUserComponent(props) {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",

      padding: "20px",
      // backgroundColor: theme.palette.paper.main
    },
    formControl: {
      margin: "2em 0",

      "& .special-label": {
        background: "#ffffff",
        color: "#868686",
      },
    },
    formControlDark: {
      margin: "1em 0",

      "& .special-label": {
        background: "#424242",
      },
    },

    cssLabel: {
      // // color: theme.palette.input.label,
      "&.Mui-focused": {
        // // color: theme.palette.input.labelClick,
      },
    },
    addButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    Accordion: {
      backgroundColor: theme.palette.secondary.main,
    },
    input: {
      width: "90%",
      padding: 6,
      fontSize: "1em",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.primary.main,
      margin: "20px 0",
    },
    forminput: {
      // // background// color: theme.palette.input.background,
    },
    notchedOutline: {
      borderWidth: "1px",
      // borderColor: theme.palette.input.borderColor
    },
    countryList: {
      ...theme.typography.body1,
    },
    phoneInput: {
      width: "100%",
      outline: "none",
      padding: "8.5px 14px 8.5px 58px",
    },
    // back// color: theme.palette.input.background,

    inputField: {
      margin: "2em 0",
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBoxSecond.color,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.palette.inputBoxSecond.color,
      },
    },
    cancelButton: {
      backgroundColor: "red",
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const loggedUserRole = useSelector(
    ({ main }) => main.userPermissions.UserRoleName
  );
  const loggedUserCompanyId = useSelector(
    ({ main }) => main.userPermissions.User.companyID
  );

  const defaultFormData = {
    firstName: "",
    lastName: "",
    userEmail: "",
    contactNo: "",
    designation: "",
    usrGroupId: 0,
    // devices: [],
    companyId:
      loggedUserRole && loggedUserRole.toLowerCase() === "admin"
        ? 0
        : loggedUserCompanyId,
    alertEnable: false,
  };

  const defaultFormErrors = {
    firstName: "",
    lastName: "",
    userEmail: "",
    contactNo: "",
    designation: "",
    usrGroupId: "",
    // devices: ''
    companyId: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const userGroups = useSelector(({ user }) => user.userGroups);
  console.log(userGroups);
  const addUserFormReset = useSelector(({ user }) => user.addUserFormReset);

  useEffect(() => {
    if (addUserFormReset) {
      resetForm();
      dispatch(addUserFormResetToFalse());
      handleConfirmModalClose();
    }
  }, [addUserFormReset, dispatch]);

  const resetForm = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultFormErrors);
  };

  useEffect(() => {
    dispatch(getUserGroups());
    // dispatch(getDevices())
    dispatch(getCompanies());
  }, [dispatch]);

  const handleForm = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
    console.log(formData);
  };

  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const validateFormData = () => {
    const errors = defaultFormErrors;
    let isHaveErrors = false;

    if (
      formData.firstName === "" ||
      !firstNamePattern.test(formData.firstName)
    ) {
      errors.firstName = "Firs Name is not valid! Characters Only";
      isHaveErrors = true;
    }
    if (formData.lastName === "" || !lastNamePattern.test(formData.lastName)) {
      errors.lastName = "Last Name is not valid! Characters Only";
      isHaveErrors = true;
    }
    if (formData.userEmail === "" || !emailPattern.test(formData.userEmail)) {
      errors.userEmail = "Email is not valid!";
      isHaveErrors = true;
    }
    if (formData.contactNo === "" || !contactPattern.test(formData.contactNo)) {
      errors.contactNo = "Contact Number is not valid! Numbers only";
      isHaveErrors = true;
    }
    if (
      formData.designation === "" ||
      !designationPattern.test(formData.designation)
    ) {
      errors.designation = "designation is not valid!";
      isHaveErrors = true;
    }
    if (formData.usrGroupId === 0) {
      errors.usrGroupId = "Select a User Group";
      isHaveErrors = true;
    }
    if (
      formData.usrGroupId !== 1 &&
      (formData.companyId === 0 || formData.companyId === null)
    ) {
      errors.companyId = "Select a Organization";
      isHaveErrors = true;
    }

    setFormErrors(errors);
    return isHaveErrors;
  };

  const handleConfirmModalOpen = () => {
    setFormData((prev) => {
      return {
        ...prev,
        devices: topDevices,
        companyId: prev.usrGroupId === 1 ? null : prev.companyId,
      };
    });
    if (!validateFormData()) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };
  //add devices

  const [topDevices, setTopDevices] = useState([]);
  // const devices = [{ id: 1, name: 'TEST1' }, { id: 2, name: 'TEST2' }, { id: 3, name: 'TEST3' }];
  const devices = useSelector(({ user }) => user.devices);
  const companyList = useSelector(({ user }) => user.companyList);

  const [bottomDevices, setBottomDevices] = useState([]);

  const [searchDeviceText, setSearchDeviceText] = useState("");

  useEffect(() => {
    if (searchDeviceText === "") {
      if (topDevices.length > 0) {
        setBottomDevices(
          devices.filter(
            (device) =>
              topDevices.find(
                (topDevice) => topDevice.Token === device.Token
              ) === undefined
          )
        );
      } else {
        setBottomDevices(devices);
      }
    } else {
      if (topDevices.length > 0) {
        setBottomDevices(
          devices.filter(
            (device) =>
              device.Name.includes(searchDeviceText) &&
              topDevices.find(
                (topDevice) => topDevice.Token === device.Token
              ) === undefined
          )
        );
      } else {
        setBottomDevices(
          devices.filter((device) => device.Name.includes(searchDeviceText))
        );
      }
    }
  }, [searchDeviceText, topDevices, devices]);

  const removeDevice = (device) => {
    setTopDevices((prev) => prev.filter((item) => item.Token !== device.Token));
    setFormData((prev) => {
      return {
        ...prev,
        devices: prev.devices.filter(
          (deviceToken) => deviceToken !== device.Token
        ),
      };
    });
  };

  const addDevice = (device) => {
    setTopDevices((prev) => [...prev, device]);
    setFormData((prev) => {
      return {
        ...prev,
        devices: [...prev.devices, device.Token],
      };
    });
  };

  const [cookies] = useCookies(["theme"]);
  const mode = cookies.theme;

  let phoneNoColor = "#ffffff";
  let phoneNoLabelColor = "#737373";

  if (mode === "Dark") {
    phoneNoColor = "#424242";
    phoneNoLabelColor = "#fafafa";
  }

  console.log("loggedUserCompanyId" ,loggedUserCompanyId)
  console.log("companyList", companyList)
  console.log("formData",formData)

  return (
    <>
      <Paper className={classes.container}>
        <Typography
          variant="h5"
          style={{ margin: "6px 8px" }}
          className={classes.heading}
        >
          Add User
        </Typography>
        <form>
          <Grid container>
            <Grid item sm={12} md={5}>
              <div className={classes.inputField}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="firstName"
                  onChange={handleForm}
                  value={formData.firstName}
                  helperText={formErrors.firstName}
                  error={formErrors.firstName.length > 0}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="lastName"
                  onChange={handleForm}
                  value={formData.lastName}
                  helperText={formErrors.lastName}
                  error={formErrors.lastName.length > 0}
                />
              </div>
              <div
                className={
                  mode === "Dark"
                    ? classes.formControlDark
                    : classes.formControl
                }
              >
                <ReactPhoneInput
                  name="contactNo"
                  label="Contact No"
                  variant="outlined"
                  size="small"
                  inputStyle={{
                    width: "100%",
                    padding: "8px 25px 0px 58px",
                    backgroundColor: phoneNoColor,
                    borderColor: phoneNoLabelColor,
                  }}
                  value={formData.contactNo}
                  country="au"
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        contactNo: e,
                      };
                    })
                  } // passed function receives the phone value
                  component={TextField}
                />
              </div>
              <div className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="alertEnable"
                      checked={formData.alertEnable}
                      onChange={(event) =>
                        setFormData((prev) => {
                          return { ...prev, alertEnable: event.target.checked };
                        })
                      }
                    />
                  }
                  label="Alert Enable"
                  labelPlacement="start"
                />
              </div>
            </Grid>
            <Grid item sm={12} md={2}></Grid>
            <Grid item sm={12} md={5}>
              <div className={classes.inputField}>
                <TextField
                  label="Designation"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="designation"
                  onChange={handleForm}
                  value={formData.designation}
                  helperText={formErrors.designation}
                  error={formErrors.designation.length > 0}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="userEmail"
                  type="email"
                  onChange={handleForm}
                  value={formData.userEmail}
                  helperText={formErrors.userEmail}
                  error={formErrors.userEmail.length > 0}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  label="User Group"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="usrGroupId"
                  select
                  value={formData.usrGroupId}
                  onChange={handleForm}
                  helperText={formErrors.usrGroupId}
                  error={formErrors.usrGroupId.length > 0}
                >
                  <MenuItem value={0}>Select group</MenuItem>
                  {(loggedUserRole.toLowerCase() === "company admin"
                    ? userGroups.filter((d) => d.usrGroupId == 2)
                    : userGroups
                  ).map((group) => (
                    <MenuItem key={group.usrGroupId} value={group.usrGroupId}>
                      {group.usrGroupDescription}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className={classes.inputField}>
                      <TextField
                  label="Organization"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="companyId"
                  select
                  value={formData.companyId}
                  onChange={handleForm}
                  helperText={formErrors.companyId}
                  error={formErrors.companyId.length > 0}
                  disabled={loggedUserRole.toLowerCase() === "admin"? formData.usrGroupId != "" ? false : true:true}
                >
                  <MenuItem value={0}>Select Organization</MenuItem>
                      {companyList.map((company) => (
                        <MenuItem
                          key={company.companyId}
                          value={company.companyId}
                        >
                          {company.companyName}
                        </MenuItem>
                      ))}
                  </TextField>
              </div>
            </Grid>
            <Grid item md={12}>
              <div style={{ marginTop: "50px", textAlign: "right" }}>
                <Button
                  className={classes.cancelButton}
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  className={classes.addButton}
                  variant="contained"
                  onClick={handleConfirmModalOpen}
                  disabled={
                    loggedUserRole.toLowerCase() === "admin" ||
                    loggedUserRole.toLowerCase() === "company admin"
                      ? false
                      : true
                  }
                >
                  ADD USER
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <UserFormConfirmationComponent
        handleClose={handleConfirmModalClose}
        open={confirmModalOpen}
        formData={formData}
        userGroups={userGroups}
        companyList={companyList}
      />
    </>
  );
}

export default AddUserComponent;
