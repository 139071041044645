import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import userReducer from "../Components/UserComponent/store/reducers";
import deviceReducer from "../Components/DeviceComponent/store/reducers";
import mainReducer from "../Components/MainComponent/store/reducers";
import dashboardReducer from "../Components/DashboardComponent/store/reducers";
import deviceAnalysisReportReducer from "../Components/ReportsComponents/DeviceAnalysisComponent/store/reducers";
import companyReducer from "../Components/CompanyComponent/store/reducers";
import alarmReducer from "../Components/AlarmComponent/store/reducers";
import mapReducer from "../Components/MapComponent/store/reducers";

const rootReducer = combineReducers({
    user: userReducer,
    device: deviceReducer,
    main: mainReducer,
    dashboard: dashboardReducer,
    company: companyReducer,
    deviceAnalysisReport: deviceAnalysisReportReducer,
    alarm: alarmReducer,
    map: mapReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;