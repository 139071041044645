import axios from "axios";
import customAxios from "../../../../Services/customAxios/CustomAxios";

export const GET_DEVICES = 'ALARM_COMPONENT - GET_DEVICES';

export const START_LOADING = 'ALARM_COMPONENT - START_LOADING';
export const STOP_LOADING = 'ALARM_COMPONENT - STOP_LOADING';
export const ERROR_MESSAGE = 'ALARM_COMPONENT - ERROR_MESSAGE';
export const RESET_ALARMS = 'ALARM_COMPONENT - RESET_ALARMS';


export const GET_USERS_BY_COMPANY = 'ALARM_COMPONENT - GET_USERS_BY_COMPANY';
export const RESET_USER_LIST = 'ALARM_COMPONENT - RESET_USER_LIST';
export const LOAD_USER_PROCESSING = 'ALARM_COMPONENT - LOAD_USER_PROCESSING';


export function getDevices() {

	return dispatch =>{
		axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/get/group/list?tenant=ESSP")
		.then(response => {

			let requestList = response.data.Data.filter(d => d.Token !== 'ESSPERTH').map(row => (
				axios.get(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + `/get/group/detail?groupToken=${row.Token}&tenant=ESSP`)
			))

				axios.all(requestList).then(
					axios.spread((...responses) => {
	
						// let responseOne = responses[0].data.Data.Devices.filter(device => (
						// 	device.Info && (Object.keys(device.Info).find(key => key.startsWith('AL_')) !== undefined)
						// ));
						// let responseTwo = responses[1].data.Data.Devices.filter(device => (
						// 	device.Info && (Object.keys(device.Info).find(key => key.startsWith('AL_')) !== undefined)
						// ));

						let responseData = [];
						responses.forEach(singleResponse => {
							responseData = [
								...responseData, 
								...(singleResponse.data.Data.Devices.filter(device => (
									device.Info && (Object.keys(device.Info).find(key => key.startsWith('AL_')) !== undefined))))
							]
						});
	
						dispatch(stopLoading());
						return dispatch({
							type: GET_DEVICES,
							payload: responseData
						})
						
					}
				)).catch(error => {
					console.log(error)
					dispatch(stopLoading());
					return dispatch({
						type: ERROR_MESSAGE,
						payload: []
					})
				});

		}).catch(error => {
			dispatch(stopLoading());
			return dispatch({
				type: ERROR_MESSAGE,
				payload: []
			})
		});
		
	}
}

export function updateAlarm(requestObject) {

	return dispatch =>{

		axios.post(process.env.REACT_APP_API_AURIGAA_PLATFORM_BASE_URL + "/put/info/device?tenant=ESSP", requestObject)
		.then(response => {
			dispatch(getDevices());
		}).catch(error => {
			console.log(error)
			dispatch(stopLoading());
			return dispatch({
				type: ERROR_MESSAGE,
			})
		});
	}
}

export function getUsersByCompanyId(companyId) {

	return dispatch =>{
		dispatch(loadUserProcessing(true));
		customAxios.get(process.env.REACT_APP_API_BASE_URL + "/usermanagement/findUserByCompanyId/" + companyId)
		.then(response => {
			dispatch(loadUserProcessing(false));
			return dispatch({
				type: GET_USERS_BY_COMPANY,
				payload: response.data
			})
		}).catch(error => {
			dispatch(loadUserProcessing(false));
		})
	}
}


export function resetUserList(){
	return {
		type: RESET_USER_LIST
	}
}
export function startLoading(){
	return {
		type: START_LOADING
	}
}

export function stopLoading(){
	return {
		type: STOP_LOADING
	}
}
export function resetAlarms(){
	return {
		type: RESET_ALARMS
	}
}
export function loadUserProcessing(value){
	return {
		type: LOAD_USER_PROCESSING,
		payload: value
	}
}