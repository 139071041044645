import React, { useEffect, useState } from 'react'
import { makeStyles, Grid, Paper, Typography, Chip, CircularProgress, TextField, MenuItem, Button, LinearProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { getDevices, getUsersByCompanyId, resetAlarms, resetUserList, startLoading, updateAlarm } from './store/actions';
import moment from 'moment';
import { getCompanies } from '../DeviceComponent/store/actions';

function AddAlarmComponent() {
    const useStyles = makeStyles((theme) => ({
        container: {
            width: "100%",
            padding: theme.spacing(5),
            // minHeight: '80vh',
            // backgroundColor: theme.palette.paper.main
        },
        input: {
            width: "90%",
            padding: 6,
            fontSize: "1em",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            margin: "20px 0"
        },
        forminput: {
            // // background// color: theme.palette.input.background,
        },
        notchedOutline: {
            borderWidth: "1px",
            // borderColor: theme.palette.input.borderColor
        },
        formControl: {
            margin: '2em 1em 0em 1em',
            // // background// color: theme.palette.input.background,
            border: 'red',
            '& input': {
                // color: theme.palette.input.text,

            },
            '& InputLabelProps': {
                // root: theme.palette.input.label,
                // focused: theme.palette.input.label,
            },
            '& InputProps': {
                // root: theme.palette.input.label,
                // focused: theme.palette.input.label,
            },
        },

        cssLabel: {
            // // color: theme.palette.input.label,
            "&.Mui-focused": {
                // // color: theme.palette.input.labelClick,

            }
        },
        TablePagination: {
            // color: theme.palette.input.text
        },
        tableContainer: {
            // padding: theme.spacing(3),
            overflowX: "auto",
        },
        table: {
        },
        tableCell: {
            fontSize: theme.typography.body1.fontSize,
            textAlign: 'center',
            // color: theme.palette.input.text,
        },
        tableHeadCell: {
            fontSize: theme.typography.body1.fontSize,
            textAlign: 'center',
            fontWeight: 'bold',
            // color: theme.palette.input.text,
        },
        tableRow: {
            transitionDuration: 0.2,
            '&:hover': {
                // backgroundColor: theme.palette.table.hover,
                transitionDuration: 0.2,

            }
        },
        tableButton: {
            borderColor: theme.typography.button.borderColor,
            color: theme.typography.button.color,
        },
        enabledChip: {
            backgroundColor: theme.palette.success.dark,
            color: 'white'
        },
        disabledChip: {
            backgroundColor: theme.palette.error.dark,
            color: 'white'
        },
        heading: {
            // color: theme.palette.input.text,
        }
    }),
    );
    const classes = useStyles();
    const dispatch = useDispatch();

    const [formErrors, setFormErrors] = useState({device: '', parameter: '', paramValue: '', condition: ''});

    const [companyInput, setCompanyInput] = useState(0);
    const [deviceInput, setDeviceInput] = useState(0);
    const [parameterInput, setParameterInput] = useState(0);
    const [conditionInput, setConditionInput] = useState(0);
    const [paramValueInput, setParamValueInput] = useState(0);
    const [nameInput, setNameInput] = useState('');

    const errorMessage = useSelector(({alarm}) => alarm.errorMessage);
    const companyList = useSelector(({device}) => device.companyList);

    const loggedUserRole = useSelector(
        ({ main }) => main.userPermissions.UserRoleName
      );
      const loggedUserCompanyId = useSelector(
        ({ main }) => main.userPermissions.User.companyID
      );

    const devices = useSelector(({alarm}) => alarm.devices);
    const loading = useSelector(({alarm}) => alarm.loading);

    const conditionList = [
        {token: '>>', label: 'Greater Than'},
        {token: '<<', label: 'Less Than'},
        {token: '==', label: 'Equal'},
    ];

    useEffect(() => {
        dispatch(resetAlarms())
        dispatch(startLoading());
        dispatch(getDevices());
        dispatch(getCompanies());
    }, [dispatch]);

    useEffect(() => {
        if(!devices){
            setDeviceInput(0);
            setParameterInput(0);
            setParamValueInput(0);
            setConditionInput(0)
            setNameInput('')
        }else if(deviceInput === 0){
            setParameterInput(0);
            setParamValueInput(0);
            setConditionInput(0)
            setNameInput('')
        }else if(parameterInput === 0){
            setParamValueInput(0);
            setNameInput('')
        }else{
            let value = parameterInput.substring(0, parameterInput.length - 5) + 'value';
            setParamValueInput(devices.find(device => device.Token === deviceInput).Info[value]);
            let conditionIcon = parameterInput.substring(0, parameterInput.length - 5) + 'condition';
            setConditionInput(devices.find(device => device.Token === deviceInput).Info[conditionIcon])
            let name = parameterInput.substring(0, parameterInput.length - 5) + 'name';
            setNameInput((devices.find(device => device.Token === deviceInput).Info[name])? devices.find(device => device.Token === deviceInput).Info[name]: '');
        }
    }, [devices, deviceInput, parameterInput, dispatch]);

    const handleAdd = () => {
        // if(companyInput === 0){
        //     setFormErrors(prev => {
        //         return {
        //             ...prev,
        //             device: 'Select an organization'
        //         }
        //     })
        //     return;
        // }
        if(deviceInput === ''){
            setFormErrors(prev => {
                return {
                    ...prev,
                    device: 'Select a device'
                }
            })
            return;
        }
        if(parameterInput === 0){
            setFormErrors(prev => {
                return {
                    ...prev,
                    parameter: 'Select a parameter'
                }
            })
            return;
        }
        if(conditionInput === 0){
            setFormErrors(prev => {
                return {
                    ...prev,
                    condition: 'Set a value'
                }
            })
            return;
        }
        if(parameterInput === ''){
            setFormErrors(prev => {
                return {
                    ...prev,
                    paramValue: 'Set a value'
                }
            })
            return;
        }
        let requestObject = {
            DeviceToken: deviceInput,
            Time: moment().unix(),
            Info: {
                [parameterInput]: parseInt(paramValueInput),
                [parameterInput.substring(0, parameterInput.length - 5) + 'condition']: conditionInput,
                [parameterInput.substring(0, parameterInput.length - 5) + 'enable']: 'true'
            }
        }
        if(nameInput !== ''){
            requestObject = {
                ...requestObject,
                Info: {
                    ...requestObject.Info,
                    [parameterInput.substring(0, parameterInput.length - 5) + 'name']: nameInput
                }
            }
        }
        setDeviceInput(0);
        setParameterInput(0);
        setParamValueInput(0);
        setConditionInput(0);
        setNameInput('');
        dispatch(startLoading());
        dispatch(updateAlarm(requestObject));
    }

    return (
        <>
            <Paper className={classes.container}>
                <Typography variant="h5" style={{margin: '6px 8px'}}  className={classes.heading}>Add Alarm</Typography>
                {loading && (<LinearProgress />)}
                {(errorMessage !== '') ? (<Alert severity="error">{errorMessage}</Alert>) : ''}
                <form>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.formControl}>
                                <TextField
                                    label="Organization" variant="outlined" size="small" fullWidth
                                    name="organization"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.cssLabel,

                                        }
                                    }}
                                    InputProps={{
                                        className: classes.forminput,
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={companyInput}
                                    onChange={event => setCompanyInput(event.target.value)}
                                    // helperText={formErrors.device}
                                    // error={(formErrors.device.length > 0)}
                                    disabled={loading}
                                >
                                    <MenuItem value={0}>Select Organization</MenuItem>
                                    {(loggedUserRole.toLowerCase() === "admin" ?companyList:companyList.filter((d)=>d.companyId == loggedUserCompanyId)).map(company => (
                                        <MenuItem key={company.companyId} value={company.companyId}>{company.companyName}</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.formControl}>
                                <TextField
                                    label="Device" variant="outlined" size="small" fullWidth
                                    name="device"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.cssLabel,

                                        }
                                    }}
                                    InputProps={{
                                        className: classes.forminput,
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={deviceInput}
                                    onChange={event => setDeviceInput(event.target.value)}
                                    helperText={formErrors.device}
                                    error={(formErrors.device.length > 0)}
                                    disabled={companyInput === 0 || loading}
                                >
                                    <MenuItem value={0}>Select Device</MenuItem>
                                    {devices.filter(device => 
                                        ( device.Info && Object.keys(device.Info).filter(key => key.endsWith('enable')).find(k => device.Info[k] === 'false') !== undefined && (companyInput === 0 || device.Info.group === `ESSPERTH${companyInput}`) ) 
                                    ).map(device => (
                                        <MenuItem key={device.Token} value={device.Token}>{device.Name}</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.formControl}>
                                <TextField
                                    label="Parameter" variant="outlined" size="small" fullWidth
                                    name="parameter"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.cssLabel,

                                        }
                                    }}
                                    InputProps={{
                                        className: classes.forminput,
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={parameterInput}
                                    onChange={event => setParameterInput(event.target.value)}
                                    helperText={formErrors.parameter}
                                    error={(formErrors.parameter.length > 0)}
                                    disabled={deviceInput === 0 || loading}
                                >
                                    <MenuItem value={0}>Select Parameter</MenuItem>
                                    {(deviceInput && deviceInput !== '' && devices) && Object.keys(devices.find(device => device.Token === deviceInput).Info).filter(k => (k.startsWith('AL_') && k.endsWith('_value'))).map(key => {
                                    if(devices.find(device => device.Token === deviceInput).Info[key.substring(0, key.length - 5) + 'enable'] !== undefined && devices.find(device => device.Token === deviceInput).Info[key.substring(0, key.length - 5) + 'enable'] === 'true'){
                                        return '';
                                    }
                                    return (
                                        <MenuItem key={key} value={key}>{key.substring(3, key.length - 6).toLocaleUpperCase()}</MenuItem>
                                    )})}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.formControl}>
                                <TextField
                                    label="Condition" variant="outlined" size="small" fullWidth
                                    name="condition"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.cssLabel,

                                        }
                                    }}
                                    InputProps={{
                                        className: classes.forminput,
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={conditionInput}
                                    onChange={event => setConditionInput(event.target.value)}
                                    helperText={formErrors.condition}
                                    error={(formErrors.condition.length > 0)}
                                    disabled={parameterInput === 0 || loading}
                                >
                                    <MenuItem value={0}>Select Condition</MenuItem>
                                    {conditionList.map(condition => (
                                        <MenuItem key={condition.token} value={condition.token}>{condition.label}</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.formControl}>
                                <TextField
                                    label={`Value`} variant="outlined" size="small" fullWidth
                                    name="paramValue"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.cssLabel,

                                        }
                                    }}
                                    InputProps={{
                                        className: classes.forminput,
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={paramValueInput}
                                    onChange={event => setParamValueInput(event.target.value)}
                                    helperText={formErrors.paramValue}
                                    error={(formErrors.paramValue.length > 0)}
                                    disabled={parameterInput === 0 || loading}
                                >
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.formControl}>
                                <TextField
                                    label={`Name`} variant="outlined" size="small" fullWidth
                                    name="paramValue"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                            root: classes.cssLabel,

                                        }
                                    }}
                                    InputProps={{
                                        className: classes.forminput,
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={nameInput}
                                    onChange={event => setNameInput(event.target.value)}
                                    disabled={parameterInput === 0 || loading}
                                >
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'right', margin: '1rem 1rem 0 0'}}>
                            <div>
                                <Button variant="contained" color="primary" onClick={handleAdd} disabled={loggedUserRole.toLowerCase() === "admin" || loggedUserRole.toLowerCase() === "company admin" ? false:true} style={{width: '140px'}}>Add</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            
        </>
    )
}

export default AddAlarmComponent;
