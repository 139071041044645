import {
    Button,
    Grid,
    makeStyles,
    Modal,
    Backdrop,
    Fade,
    Paper,
    TextField,
    FormGroup,
    LinearProgress,
    Typography
} from '@material-ui/core'
import React from "react"
import { useDispatch, useSelector } from 'react-redux';
import { addDevice } from '../store/actions';

function DeviceFormConfirmationComponent(props) {

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            width: "70%",
            [theme.breakpoints.up('sm')]: {
                width: "60%",
            },
            padding: "20px",
        },
        formControl: {
            margin: '2em 0'
        },
        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        cancelButton: {
            backgroundColor: "red",
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
        }

    }))

    const classes = useStyles();
    const dispatch = useDispatch();

    const addDeviceHanlder = () => {
        dispatch(addDevice(props.formData.deviceToken.Token, 'ESSPERTH' + props.formData.companyId.companyId, props.formData.deviceName))
    }

    const addDeviceLoading = useSelector(({ device }) => device.addDeviceLoading);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableBackdropClick={addDeviceLoading}
        >
            <Fade in={props.open}>
                <Paper className={classes.container}>
                    <Typography variant='h5'>Confirm Add device details</Typography>
                    <form>
                        <Grid container  >
                            <Grid sm={12} md={5}>

                                <FormGroup>
                                    <div className={classes.formControl}>
                                        <TextField label="Device Token" variant="outlined" size="small" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                            value={(props.formData.deviceToken && props.devices.find(device => device.Token === props.formData.deviceToken.Token))? props.devices.find(device => device.Token === props.formData.deviceToken.Token).Name: ''} />

                                    </div>
                                </FormGroup>
                                
                            </Grid>
                            <Grid sm={12} md={2}>

                            </Grid>
                            <Grid sm={12} md={4} >
                                <FormGroup>
                                    <div className={classes.formControl}>
                                        <TextField label="Organization" variant="outlined" size="small" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                            value={(props.formData.companyId && props.companyList.find(company => company.companyId === props.formData.companyId.companyId))? props.companyList.find(company => company.companyId === props.formData.companyId.companyId).companyName: ''} />

                                    </div>
                                </FormGroup>
                            </Grid>
                            <Grid sm={12} md={4} >
                                <FormGroup>
                                    <div className={classes.formControl}>
                                        <TextField label="Device Name" variant="outlined" size="small" fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                            value={props.formData.deviceName} />

                                    </div>
                                </FormGroup>
                            </Grid>
                            <Grid xm={12} md={12}>
                                <div className={classes.formControl} style={{ textAlign: 'right' }}>
                                    <Button className={classes.cancelButton} variant="contained" style={{ marginRight: '10px' }} disabled={addDeviceLoading} onClick={props.handleClose}>Cancel</Button>
                                    <Button className={classes.addButton} variant="contained" onClick={addDeviceHanlder} disabled={addDeviceLoading}>CONFIRM</Button>
                                </div>
                            </Grid>

                        </Grid>
                    </form>
                    {(addDeviceLoading) ? (<LinearProgress />) : ''}
                </Paper>
            </Fade>
        </Modal>
    )
}

export default DeviceFormConfirmationComponent
