import React, { useState } from 'react'
import { Grid, makeStyles, Paper, Button, Typography, TextField, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import mainImage from '../../assets/images/login_image.png'
import logo from '../../assets/images/logo.jpeg'
import Alert from '@material-ui/lab/Alert';
import AuthService from '../../Services/AuthService/AuthService'
import { useHistory } from 'react-router-dom'
import axios from 'axios';


const LoginComponents = (props) => {
    const UseStyles = makeStyles((theme) => ({
        root: {

            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "100vh",
            
        },
        title: {
            color: theme.palette.primary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(10),


        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            backgroundColor: 'rgba(32,70,88,1)',
            '&:hover': {
                backgroundColor: 'rgba(11,32,45,1)',
            }
        },
        loginCard: {
            
            height: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: 'center',
            marginLeft:'10px',
        },
        paper: {
            marginTop: theme.spacing(10),
            
        },
        logo: {
            width: '100%',
        },
        mainImage: {
            width: '100%',
            borderRadius: '5%',
            backgroundSize: 'cover',
            height: '450px'
        },
        cssOutlinedInput: {
            '&$notchedOutline': {
                borderColor: `${theme.palette.secondary.main} !important`,
            }
        },
        cssLabel: {
            color: theme.palette.secondary.main,
        },
        notchedOutline: {
            borderWidth: '2px',
            borderColor: `${theme.palette.secondary.main} !important`
        },
        input: {
            "& .MuiInputBase-root": {
                color: theme.palette.secondary.main,

            }
        },
        buttonSuccess: theme.typography.buttonSuccess,
        buttonWarning: theme.typography.buttonWarning,
        textField: {
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(32,70,88,1)'
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(11,32,45,1)'
                
            },"& .MuiInputLabel-root.Mui-focused": {
                color: 'rgba(11,32,45,1)'
            },
        },
    }))
    const classes = UseStyles();

    const history = useHistory();

    const [username, setUsername] = useState(''); // store username
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');


    const handleLogin = event => {
        event.preventDefault(); // block default form submit
        AuthService.login(username, password).then(response => {
            AuthService.storeSession({ data: response.data })
            if(response.data.loginstatus === 3){
                setIsLoading(true);
                history.push("/");
            }else{
                setIsLoading(true);
                history.push("/company/admin/update");
            }
        }).catch(error => {
            setErrorMessage((error.response && error.response.data) ? error.response.data.status : 'Network error! Please check your internet connection')
        });
    }

    // password reset code
    const [resettingUsername, setResettingUsername] = useState('');
    const [resettingUsernameError, setResettingUsernameError] = useState('');
    const [resetPasswordDialogBoxOpen, setResetPasswordDialogBoxOpen] = useState(false);
    const [confirmResetPasswordDialogBoxOpen, setConfirmResetPasswordDialogBoxOpen] = useState(false);

    const sendResetUsername = () => {
        if(resettingUsername.trim() === ''){
            setResettingUsernameError('Username cannot be empty!');
            return;
        }
        axios.get(process.env.REACT_APP_API_BASE_URL + `/usermanagement/forgotpassword/email/${resettingUsername}/requestpasswordreset`)
        .then(response => {
            if(response.data){
                resetPasswordDialogBoxClose();
                setConfirmResetPasswordDialogBoxOpen(true);
            }else{
                setResettingUsernameError('Username is not found');
            }
        }).catch(error => {
            setResettingUsernameError((error.response && error.response.data) ? error.response.data.status : 'Network error! Please check your internet connection')
        });
    }

    const resetPasswordDialogBoxClose = () => {
        setResettingUsername('');
        setResettingUsernameError('');
        setResetPasswordDialogBoxOpen(false);
    }

    const openResettingPassword = () => {
        setResettingUsername('');
        setResettingUsernameError('');
        setResetPasswordDialogBoxOpen(true);
    }


    return (
        <div container className={classes.root} spacing={2} >
            <Grid container md={8} >
                <Paper elevation={8} className={classes.paper} >
                    <Grid container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center"

                    >

                        <Grid container md={7} direction="row"
                            alignItems="center"
                            justify="center" >
                            <Grid item={true} md={10} style={{textAlign: 'center'}} >
                                {/* <img src={logo} alt="Green IT" className={classes.logo} /> */}
                                {/* <Typography variant="h5"
                                    color="inherit">
                                    <span className={classes.title}>Device Monitoring and </span> Alerting System </Typography> */}
                                <img src={mainImage} alt="Green IT" className={classes.mainImage} />
                            </Grid>
                        </Grid>


                        <Grid container md={5} direction="row"
                            alignItems="center"
                            justify="center">
                            <Paper className={classes.loginCard}>
                                <Grid item={true} xs={10} spacing={5}>
                                    <form className={classes.form} onSubmit={handleLogin} noValidate >
                                        {(errorMessage !== '') && (
                                            <Alert variant="filled" severity="warning">
                                                {errorMessage}
                                            </Alert>
                                        )}
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            color="secondary"
                                            className={classes.textField}
                                            autoFocus
                                            value={username}
                                            onChange={(event) => { setUsername(event.target.value) }}
                                            disabled={isLoading}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            color="secondary"
                                            className={classes.textField}
                                            autoComplete="current-password"
                                            value={password}
                                            onChange={(event) => { setPassword(event.target.value) }}
                                            disabled={isLoading}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            className={classes.submit}
                                        >
                                            {(isLoading)? 'Processing': 'Login'}
                                            
                                        </Button>
                                        <Grid container>
                                            <Grid item={true} xs>

                                            </Grid>
                                            <Grid item={true} >
                                                <Button  onClick={openResettingPassword}>
                                                    Forgot password?
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {/* password reset popup code */}
            <Dialog
                open={resetPasswordDialogBoxOpen}
                // TransitionComponent={Transition}
                keepMounted
                onClose={resetPasswordDialogBoxClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" className={`${classes.modalBackground} ${classes.modalTitle}`}>{"Reset Password"}</DialogTitle>
                <DialogContent className={classes.modalBackground}>
                    <DialogContentText id="alert-dialog-slide-description" style={{width: '300px'}}>
                        <TextField
                            label="Username" variant="outlined" size="small" fullWidth name="resettingUsername" type="text"
                            onChange={event => setResettingUsername(event.target.value)}
                            className={classes.textField}
                            value={resettingUsername}
                            helperText={resettingUsernameError}
                            error={(resettingUsernameError.length > 0)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.modalBackground}>
                    <Button onClick={resetPasswordDialogBoxClose} >
                        Cancel
                    </Button>
                    <Button onClick={sendResetUsername} >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={confirmResetPasswordDialogBoxOpen}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setConfirmResetPasswordDialogBoxOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"New password is send to your email"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setConfirmResetPasswordDialogBoxOpen(false)} className={classes.buttonSuccess}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



export default LoginComponents
