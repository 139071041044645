import * as ACTIONS from '../actions';
const initialState = {
    devices: [],
    allDevices: [],
    loadDeviceProcessing: false,
    addDeviceComponentLoading: false,
    addDeviceLoading: false,
    addDeviceFormReset: false,
    users: [],
    deviceDeletetionFlag: false,
    companyList: [],
    device: null
};

const userReducer = (state = initialState, action) => {
    
    switch(action.type){
        case ACTIONS.START_ADD_DEVICE_COMPONENT_LOADING: {
            return {
                ...state,
                addDeviceComponentLoading: true
            }
        }
        case ACTIONS.STOP_ADD_DEVICE_COMPONENT_LOADING: {
            return {
                ...state,
                addDeviceComponentLoading: false
            }
        }
        case ACTIONS.START_ADD_DEVCIE_LOADING: {
            return {
                ...state,
                addDeviceLoading: true
            }
        }
        case ACTIONS.STOP_ADD_DEVCIE_LOADING: {
            return {
                ...state,
                addDeviceLoading: false
            }
        }
        case ACTIONS.GET_DEVICES: {
            return {
                ...state,
                devices: action.payload
            }
        }
        case ACTIONS.ADD_DEVICE: {
            return {
                ...state,
                addDeviceFormReset: true
            }
        }
        case ACTIONS.ADD_DEVICE_ERROR: {
            return {
                ...state,
                addDeviceComponentLoading: false
            }
        }
        case ACTIONS.ADD_DEVICE_FORM_RESET_FALSE: {
            return {
                ...state,
                addDeviceFormReset: false
            }
        }
        case ACTIONS.GET_ALL_DEVICES: {
            return {
                ...state,
                allDevices: action.payload,
                loadDeviceProcessing: false
            }
        }
        case ACTIONS.START_LOAD_DEVICE_PROCESSING: {
            return {
                ...state,
                loadDeviceProcessing: true
            }
        }
        case ACTIONS.STOP_LOAD_DEVICE_PROCESSING: {
            return {
                ...state,
                loadDeviceProcessing: false
            }
        }
        case ACTIONS.GET_USERS: {
            return {
                ...state,
                users: action.payload
            }
        }
        case ACTIONS.DELETE_DEVICE: {
            return {
                ...state,
                deviceDeletetionFlag: !state.deviceDeletetionFlag
            }
        }
        case ACTIONS.GET_COMPANIES: {
            return {
                ...state,
                companyList: action.payload
            }
        }
        case ACTIONS.GET_DEVICE_BY_TOKEN: {
            return {
                ...state,
                device: action.payload
            }
        }
        case ACTIONS.RESET_DEVICES: {
            return initialState
        }
        default: {
            return state;
        }
    }
};

export default userReducer;