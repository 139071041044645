import { createMuiTheme } from '@material-ui/core/styles';

const PRIMARY_COLOR = '#0071bd';
const SECONDARY_COLOR = '#e6e6e6';

export function lightTheme(mode) {
  return createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: PRIMARY_COLOR,
        heder: "#ffffff" //primary color
      },
      secondary: {
        main: SECONDARY_COLOR
      },
      paper:{
        color:"#FFFFFF"
      },
      icons: {
        color: '#090088',
      },
      inputBoxFirst:{
        color:'#d4d4d4'
      },
      inputBoxSecond:{
        color:'#737373'
      },
      cardColor:{
        color:'#ffffff',
        bottomBoxColor:"rgba(79, 29, 222, 0.3)"
      },
      phoneInput:{
        backgroundColor:"#ffffff",
        borderColor:"#737373"
      }
    },
  });
}


export function darkTheme(mode) {
  return createMuiTheme({
    palette: {
      type:"dark",
      primary: {
        main: '#212121',
        heder: "#10151D"
      },
      secondary: {
        main: '#e6e6e6'
      },
      paper:{
        color:"#090909"
      },
      icons: {
        color: '#ffffff',
      },
      inputBoxFirst:{
        color:'#d1d1d1'
      },
      inputBoxSecond:{
        color:'#fafafa'
      },
      cardColor:{
        color:'#10151D',
        bottomBoxColor:"rgba(104, 57, 237, 0.9)"
      },
      phoneInput:{
        backgroundColor:"#424242",
        borderColor:"#fafafa"
      }
    },
  });
}