import { makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import { NavLink } from "react-router-dom";
import { ADD_DEVICE_ROUTE, ADD_USER_ROUTE, DASHBOARD_ROUTE, DEVICE_ANALYSIS_REPORT, DEVICE_ROUTE, USER_ROUTE, COMPANY, ADD_COMPANY, ALARM_ROUTE, MAP_ROUTE, ALARM_VIEW_ROUTE, ALARM_ADD_ROUTE, LORAWAN_ROUTE } from "../../RouteComponent/PathConfig";
import TocIcon from '@material-ui/icons/Toc';
import AddIcon from '@material-ui/icons/Add';
import { Alarm, Equalizer, Map, People, PersonAdd, ShowChart, Tune } from "@material-ui/icons";
import { useSelector } from "react-redux";

function SideBarComponent(props) {

    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: props.drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
        },
        drawerOpen: {
            width: props.drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),

            backgroundColor: "#10151D",
            color: theme.palette.secondary.main,
        },
        drawerClose: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
                backgroundColor: "#10151D",
                color: theme.palette.secondary.main,
            }),
            backgroundColor: "#10151D",
            color: theme.palette.secondary.main,
            overflowX: "hidden",
            width: theme.spacing.unit * 7 + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing.unit * 9 + 1,
            },

            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.colors.white,
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            marginTop: theme.spacing.unit,
            justifyContent: "flex-end",
            padding: "0 8px",
            ...theme.mixins.toolbar
        },
        listClass: {
            backgroundColor: "#10151D",
            // color: theme.palette.colors.white,
        },
        ItemIconClass: {
            color: "inherit",

        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        active: {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "#10B981",
            fill: theme.palette.primary.main,
            "&:hover": {
                background: "rgba(255, 255, 255, 0.08)"
            },
        }
    }))

    const classes = useStyles()
    const [userManage, setUserManage] = useState(false);
    const viewUserDropDown = () => {
        setUserManage(!userManage);
    };
    const [deviceManage, setDeviceManage] = useState(false);
    const viewDeviceDropDown = () => {
        setDeviceManage(!deviceManage);
    };
    const [reportManage, setReportManage] = useState(false);
    const viewReportDropDown = () => {
        setReportManage(!reportManage);
    };
    const [alarmManage, setAlarmManage] = useState(false);
    const viewAlarmDropDown = () => {
        setAlarmManage(prev => !prev);
    };

    const [companyManage, setCompanyManage] = useState(false);
    const viewCompanyManage = () => {
        setCompanyManage(!companyManage);
    };

    const userPermissions = useSelector(({ main }) => main.userPermissions);

    return (
        <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: props.sidebarOpen,
                [classes.drawerClose]: !props.sidebarOpen,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: props.sidebarOpen,
                    [classes.drawerClose]: !props.sidebarOpen,
                }),
            }}
            open={props.sidebarOpen}
        >
            <div className={classes.toolbar} />
            <List className={classes.listClass} >
                <ListItem button key={"Dashboard"} component={NavLink} to={DASHBOARD_ROUTE} activeClassName={classes.active} exact>
                    <ListItemIcon className={classes.ItemIconClass} >
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                </ListItem>

                <Divider />

                <ListItem button key={"Map"} component={NavLink} to={MAP_ROUTE} activeClassName={classes.active} exact>
                    <ListItemIcon className={classes.ItemIconClass} >
                        <Map />
                    </ListItemIcon>
                    <ListItemText primary={"Map"} />
                </ListItem>
                <Divider />

                {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '2')) && (

                    <>
                        <ListItem button key={"Device Management"} onClick={viewDeviceDropDown}>
                            <ListItemIcon className={classes.ItemIconClass}>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Devices"} />
                            {deviceManage ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Divider />
                        <Collapse in={deviceManage} timeout="auto" unmountOnExit>
                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '2#1')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={ADD_DEVICE_ROUTE} activeClassName={classes.active} exact >
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <AddIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Device" />
                                    </ListItem>
                                </List>
                            )}

                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '2#2')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={DEVICE_ROUTE} activeClassName={classes.active} exact>
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <TocIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="View Device" />
                                    </ListItem>
                                </List>
                            )}
                        </Collapse>
                    </>

                )}

                {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1')) && (
                    <>
                        <ListItem button key={"Company Management"} onClick={viewCompanyManage}>
                            <ListItemIcon className={classes.ItemIconClass}>
                                <HomeWorkIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Organization"} />
                            {companyManage ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Divider />
                        <Collapse in={companyManage} timeout="auto" unmountOnExit>
                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1#1')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={ADD_COMPANY} activeClassName={classes.active} exact>
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <AddIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Organization " />
                                    </ListItem>
                                </List>
                            )}
                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1#2')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={COMPANY} activeClassName={classes.active} exact>
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <TocIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="View Organization " />
                                    </ListItem>
                                </List>
                            )}

                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1#1')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={ADD_USER_ROUTE} activeClassName={classes.active} exact>
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <PersonAdd />
                                        </ListItemIcon>
                                        <ListItemText primary="Add User " />
                                    </ListItem>
                                </List>
                            )}
                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '1#2')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={USER_ROUTE} activeClassName={classes.active} exact>
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <People />
                                        </ListItemIcon>
                                        <ListItemText primary="View User " />
                                    </ListItem>
                                </List>
                            )}


                        </Collapse>
                        
                    </>
                )}

                <>
                    <ListItem button key={"Alarms"} onClick={viewAlarmDropDown}>
                        <ListItemIcon className={classes.ItemIconClass}>
                            <Alarm />
                        </ListItemIcon>
                        <ListItemText primary={"Alarms"} />
                        {alarmManage ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Divider />
                    <Collapse in={alarmManage} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={NavLink} to={ALARM_ROUTE} activeClassName={classes.active} exact >
                                <ListItemIcon className={classes.ItemIconClass}>
                                    <Tune />
                                </ListItemIcon>
                                <ListItemText primary="Configure Alarm" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={NavLink} to={ALARM_ADD_ROUTE} activeClassName={classes.active} exact >
                                <ListItemIcon className={classes.ItemIconClass}>
                                    <AddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Add Alarm" />
                            </ListItem>
                        </List>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} component={NavLink} to={ALARM_VIEW_ROUTE} activeClassName={classes.active} exact >
                                <ListItemIcon className={classes.ItemIconClass}>
                                    <TocIcon />
                                </ListItemIcon>
                                <ListItemText primary="View Alarm" />
                            </ListItem>
                        </List>
                    </Collapse>
                </>

                {/* {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '3')) && (
                    <>
                        <ListItem button key={"Reports"} onClick={viewReportDropDown}>
                            <ListItemIcon className={classes.ItemIconClass}>
                                <Equalizer />
                            </ListItemIcon>
                            <ListItemText primary={"Reports"} />
                            {reportManage ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Divider />
                        <Collapse in={reportManage} timeout="auto" unmountOnExit>
                            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '3#1')) && (
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to={DEVICE_ANALYSIS_REPORT} activeClassName={classes.active} exact >
                                        <ListItemIcon className={classes.ItemIconClass}>
                                            <ShowChart />
                                        </ListItemIcon>
                                        <ListItemText primary="Device Analysis" />
                                    </ListItem>
                                </List>
                            )}
                        </Collapse>
                    </>
                )} */}

            </List>

            {(userPermissions !== null && userPermissions.options.find(option => option.museroptionshashcode === '5')) && (
                <>
                    <ListItem button key={"Lorawan"} onClick={() => {window.open('http://110.142.251.42:8000/#/login', "_blank")}}>
                        <ListItemIcon className={classes.ItemIconClass} >
                            <Map />
                        </ListItemIcon>
                        <ListItemText primary={"Lorawan"} />
                    </ListItem>
                    <Divider />
                </>
            )}
        </Drawer>
    )
}

export default SideBarComponent
